import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, onValue, update } from 'firebase/database';  // Firebase veritabanı işlemleri için
import { BrowserRouter as Router, Route } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';


const TeskilatDegisiklikBildirScreen = ({ navigation }) => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    useEffect(() => {
      const checkUserId = async () => {
        const userId = await localStorage.getItem('userId');
        if (!userId) {
          navigate('/login', { replace: true });
        }
      };
    
      checkUserId();
    }, [navigate]);
    
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = isMobile ? mobileStyles : desktopStyles;
  
    // ... (Diğer useEffect ve fonksiyonlar)
  
  
  
  // Anahtar kelimeyi Türkçe'ye çevirme fonksiyonu
  const translateKey = (key) => {
    const translations = {
      ageDate: "Doğum Tarihi",
      selectedCityName: "Şehir",
      department: "Bölüm",
      additionalInfo: "Ek Bilgiler",
      selectedDistrictName: "İlçe",
      email: "E-posta",
      employmentStatus: "Çalışma Durumu",
      extraGorev: "Ek Görev",
      faculty: "Fakülte",
      fullName: "Ad Soyad",
      graduationYear: "Mezuniyet Yılı",
      highSchool: "Lise",
      langueDetails: "Yabancı Dil Bilgisi",
      maritalStatus: "Medeni Durum",
      merkezTasra: "Merkez/Taşra",
      onay: "Onay Durumu",
      status: "durum",
      otherDescription: "Diğer Açıklama",
      otherJob: "Diğer İş",
      phoneNumber: "Telefon Numarası",
      selectedGorev: "Seçilen Görev",
      selectedKademe: "Seçilen Kademe",
      selectedUniversity: "Üniversite",
      yukseklisans: "Yüksek Lisans/Doktora"
      // Diğer anahtar kelimeler eklenebilir
    };
    return translations[key] || key;
  };
  
  
  
    useEffect(() => {
      const db = getDatabase();
      const usersRef = ref(db, 'kullanicilar');
  
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
        const unapprovedUsers = Object.keys(data)
          .map(key => {
            return {
              id: key,
              ...data[key]
            };
          });
  
        setUsers(unapprovedUsers);
      });
    }, []);
  
    
  
    const showUserDetails = (user) => {
      setSelectedUser(user);
      setModalVisible(true);
    };
  
    const renderItem = ({ item }) => (
      <div style={styles.confirmationListItem}>
        <p style={styles.confirmationListItemText}>{item.fullName}</p>
        <button style={styles.confirmationButton} onClick={() => showUserDetails(item)}>
          <p style={styles.confirmationButtonText}>İncele</p>
        </button>
      </div>
    );
  
  
    
  
    // Kullanıcı bilgilerini gösteren Modal pop-up'ı
  const UserDetailsModal = ({ visible, user, onClose }) => {
    return (
        <Modal
  isOpen={modalVisible}
  onRequestClose={() => setModalVisible(false)}
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)', // Arka plan rengini ayarla
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '400px', // Modal genişliğini burada ayarlayın
      maxHeight: '80%', // Modalın maksimum yüksekliğini sınırla
      overflow: 'auto', // İçeriği taşarsa kaydırma çubuğu ekle
      padding: '20px', // İç padding
      border: '2px solid black', // İnce çerçeve ekle
      borderRadius: '50px', // Köşelere yuvarlaklık ekle
    }
  }}
>
        <div style={styles.centeredView}>
          <div style={styles.modalView}>
            <div>
              {Object.entries(user).map(([key, value]) => {
                if (key !== 'status' && key !== 'id' && key !== 'onay' && key !== 'IKonay' && key !== 'IKyonetim' && key !== 'ik' && key !== 'IKstatus' && key !== 'push_token') {
                  return (
                    <div key={key} style={styles.modalItem}>
                      <p style={styles.modalItemTitle}>{translateKey(key)}:</p>
                      <p style={styles.modalItemText}>{value.toString()}</p>
                    </div>
                  );
                }
              })}
            </div>
            <div style={styles.modalButtonsContainer}>
             
  
                <button
                  style={styles.modalButton2}
                  onClick={() => setModalVisible(!modalVisible)}
                >
                  <p style={styles.modalButtonText}>Kapat</p>
                </button>
  
                <button style={styles.teskilatdegisiklikReportButton}  onClick={() => {
              setModalVisible(!modalVisible)
                navigate('/teskilat',{ state: { userId2: user.id }}); // Navigasyon
              }}
            >
              <p style={styles.modalButtonText}>Değişiklik Bildir</p>
            </button>
               
              </div>
          </div>
        </div>
      </Modal>
    );
  };
  
  
    return (
      <div style={{ flex: 1 }}>
      <div style={styles.confirmationHeaderContainer}>
       <p style={styles.taskManagementTitle}>Kullanıcılar</p>
        <div style={styles.divider}/>
  </div>
  
  {users.map((user, index) => (
  <div key={user.id}>
    <div style={styles.listcontainer}>
      <p style={styles.confirmationListItemText}>{user.fullName.toUpperCase()}</p>
      <button style={styles.confirmationButton} onClick={() => showUserDetails(user)}>
        <p style={styles.confirmationButtonText}>İncele</p>
      </button>
    </div>
    {/* İlk ve son öğe arasında çizgi ekleme */}
    {index !== users.length - 1 && <hr style={{width: '100%', border: '1px solid lightgray'}} />}
  </div>
))}

       <UserDetailsModal
          visible={modalVisible}
          user={selectedUser}
          onClose={() => setModalVisible(false)}
        />
    </div>
    );
  };

  const desktopStyles = {
    confirmationHeaderContainer: {

      alignItems: 'center',
        justifyContent: 'center',
      paddingHorizontal: 20,
      paddingVertical: 10,
    },
    taskManagementTitle: {
      fontSize: 30,
      fontWeight: 'bold',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex'
    },
    divider: {
      height: 1,
      backgroundColor: 'black',
      marginTop: 5,
    },
    confirmationListItem: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 20,
      paddingVertical: 10,
      borderBottomWidth: 1,
      borderBottomColor: 'lightgrey',
    },
    confirmationListItemText: {
      fontSize: 16,
      fontWeight: 'bold'
    },
    confirmationButton: {
      backgroundColor: 'blue',
      padding: 10,
      borderRadius: 5,
      width: '10%',
      height: '30px',
      display:'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0 2px 5px rgba(0,0,0,0.9)',
      border: 'none', // Çizgiyi kaldırmak için eklenen özellik
      outline: 'none', // Çizgiyi kaldırmak için eklenen özellik
    },
    confirmationButtonText: {
      color: 'white',
      fontWeight: 'bold',
      fontSize: 15
    },
    centeredView: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 22,
    },
    modalView: {
      margin: 20,
      backgroundColor: 'white',
      borderRadius: 20,
      padding: 35,
      alignItems: 'center',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
    },
    modalItem: {
      flexDirection: 'row',
      marginBottom: 10,
    },
    modalItemTitle: {
      fontWeight: 'bold',
      marginRight: 5,
    },
    modalItemText: {
      flex: 1,
    },
    
    modalButtonsContainer: {
      flexDirection: 'row',
      marginTop: 10,
    },
    modalButton2: {
      borderRadius: 5,
      padding: 10,
      marginHorizontal: 5,
      flex: 1,
      alignItems: 'center',
      backgroundColor: 'darkgray',
      width: '50%'
    },
    modalButtonClose: {
      backgroundColor: 'red',
    },
    teskilatdegisiklikReportButton: {
      backgroundColor: 'blue',
      width: '50%',
      borderRadius: 5,
      padding: 10,
      marginHorizontal: 5,
      flex: 1,
      alignItems: 'center',

    },
    modalButtonText: {
      color: 'white',
      fontWeight: 'bold',
    },
    listcontainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10,
        display: 'flex',
        borderBottomWidth: 1,
        borderBottomColor: 'lightgray',
    }
  };
  const mobileStyles = {
    confirmationHeaderContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: 10,
      paddingVertical: 5,
    },
    taskManagementTitle: {
      fontSize: 24, // Font boyutu küçültüldü
      fontWeight: 'bold',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex'
    },
    divider: {
      height: 1,
      backgroundColor: 'black',
      marginTop: 5,
    },
    confirmationListItem: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 10,
      paddingVertical: 5,
      borderBottomWidth: 1,
      borderBottomColor: 'lightgrey',
    },
    confirmationListItemText: {
      fontSize: 14, // Font boyutu küçültüldü
      fontWeight: 'bold'
    },
    confirmationButton: {
      backgroundColor: 'blue',
      padding: 8, // Padding küçültüldü
      borderRadius: 5,
      width: '20%', // Buton genişliği artırıldı
      height: '30px',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      boxShadow: '0 2px 5px rgba(0,0,0,0.9)',
      border: 'none', // Çizgiyi kaldırmak için eklenen özellik
      outline: 'none', // Çizgiyi kaldırmak için eklenen özellik

    },
    confirmationButtonText: {
      color: 'white',
      fontWeight: 'bold',
      fontSize: 14 // Font boyutu küçültüldü
    },
    centeredView: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 22,
    },
    modalView: {
      margin: 10, // Margin küçültüldü
      backgroundColor: 'white',
      borderRadius: 20,
      padding: 20, // Padding küçültüldü
      alignItems: 'center',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
    },
    modalItem: {
      flexDirection: 'row',
      marginBottom: 5, // Margin küçültüldü
    },
    modalItemTitle: {
      fontWeight: 'bold',
      marginRight: 5,
    },
    modalItemText: {
      flex: 1,
    },
    modalButtonsContainer: {
      flexDirection: 'row',
      marginTop: 10,
    },
    modalButton2: {
      borderRadius: 5,
      padding: 8, // Padding küçültüldü
      marginHorizontal: 5,
      flex: 1,
      alignItems: 'center',
      backgroundColor: 'darkgray',
      width: '45%' // Buton genişliği artırıldı
    },
    modalButtonClose: {
      backgroundColor: 'red',
    },
    teskilatdegisiklikReportButton: {
      backgroundColor: 'blue',
      width: '45%', // Buton genişliği artırıldı
      borderRadius: 5,
      padding: 8, // Padding küçültüldü
      marginHorizontal: 5,
      flex: 1,
      alignItems: 'center',
    },
    modalButtonText: {
      color: 'white',
      fontWeight: 'bold',
    },
    listcontainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 10,
      display: 'flex',
      borderBottomWidth: 1,
      borderBottomColor: 'lightgray',
    }
  };
  
export default TeskilatDegisiklikBildirScreen;