import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';
import { initializeApp } from 'firebase/app';
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';

const firebaseConfig = {
  apiKey: "AIzaSyDdugV4yuPfYBfVdTs6xEEFhrB6H9XgvRA",
  authDomain: "dernek-9b26c.firebaseapp.com",
  databaseURL: "https://dernek-9b26c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "dernek-9b26c",
  storageBucket: "dernek-9b26c.appspot.com",
  messagingSenderId: "58054737120",
  appId: "1:58054737120:web:c4535a79d85d6a63102485",
  measurementId: "G-N3BL5L2NZN"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

function LoginScreen() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      navigate('/home');
    }
  }, [navigate]);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = isMobile ? mobileStyles : desktopStyles;

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      navigate('/home');
    }
  }, [navigate]);

  const login = () => {
    if (!email || !password) {
      window.alert("Uyarı: E-mail ve şifre alanları boş geçilemez!");
      return;
    }

    if (isRememberMe) {
      localStorage.setItem('userEmail', email);
      localStorage.setItem('userPassword', password);
      localStorage.setItem('isRememberMe', 'true');
    } else {
      localStorage.removeItem('userEmail');
      localStorage.removeItem('userPassword');
      localStorage.setItem('isRememberMe', 'false');
    }

    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        const db = getDatabase(app);
        const userRef = ref(db, 'kullanicilar/' + user.uid);

        await localStorage.setItem('userId', user.uid);

        onValue(userRef, (snapshot) => {
          const userData = snapshot.val();

          if (userData.IKonay === false || userData.IKonay === undefined) {
            window.alert("Giriş Engellendi: Giriş yapabilmeniz için komisyon onayı almanız gerekmektedir.");
            localStorage.removeItem('userId');
            return;
          } else {
            navigate('/home');
          }
        }, (error) => {
          window.alert("Hata: Veritabanı hatası: " + error.message);
        });
      })
      .catch((error) => {
        window.alert("Giriş Hatası: Mail adresiniz veya şifreniz yanlış!");
      });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const toggleRememberMe = () => {
    setIsRememberMe(!isRememberMe);
  };

  useEffect(() => {
    const loadRememberedLoginData = () => {
      const savedEmail = localStorage.getItem('userEmail');
      const savedPassword = localStorage.getItem('userPassword');
      const savedRememberMe = localStorage.getItem('isRememberMe');

      if (savedEmail && savedPassword && savedRememberMe === 'true') {
        setEmail(savedEmail);
        setPassword(savedPassword);
        setIsRememberMe(true);
      }
    };

    loadRememberedLoginData();
  }, []);

  useEffect(() => {
    if (isRememberMe) {
      localStorage.setItem('userEmail', email);
      localStorage.setItem('userPassword', password);
      localStorage.setItem('isRememberMe', 'true');
    } else {
      localStorage.removeItem('userEmail');
      localStorage.removeItem('userPassword');
      localStorage.setItem('isRememberMe', 'false');
    }
  }, [email, password, isRememberMe]);

  return (
    <div style={styles.container}>
      <img src={require('../assets/imar.png')} style={styles.logo} alt="Logo" />
      <div style={styles.formContainer}>
        <h1 style={styles.title}>Giriş Yap</h1>
        <div style={styles.inputContainer}>
          <FaEnvelope style={styles.icon} />
          <input type="email" placeholder="E-mail" value={email} onChange={e => setEmail(e.target.value)} style={styles.input} />
        </div>
        <div style={styles.inputContainer}>
          <FaLock style={styles.icon} />
          <input type={passwordVisibility ? "text" : "password"} placeholder="Şifre" value={password} onChange={e => setPassword(e.target.value)} style={styles.input} />
          <button onClick={togglePasswordVisibility} style={styles.passwordToggle}>
            {passwordVisibility ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
          </button>
        </div>
        <div style={styles.checkboxContainer}>
        <p  style={styles.checkboxLabel}>Beni Hatırla</p>
          <input
            type="checkbox"
            checked={isRememberMe}
            onChange={toggleRememberMe}
            style={styles.checkbox}
            id="rememberMe"
            
          />
          
        </div>

        <button onClick={login} style={styles.loginButton}>Giriş</button>
        <p style={styles.registerText}>
          Hesabınız yok mu? <span onClick={() => navigate('/register')} style={styles.registerLink}>Kayıt Ol</span>
        </p>
        <p style={styles.registerText}>
          <span onClick={() => navigate('/forgot-password')} style={styles.registerLink}>Şifremi Unuttum</span>
        </p>
      </div>
    </div>
  );
}

const desktopStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%', 
    backgroundColor: '#f0f0f0',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  logo: {
    marginBottom: '20px',
    width: '300px',
    height: '300px',
  },
  formContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '40px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '400px',
  },
  title: {
    fontSize: '24px',
    color: '#000000',
    marginBottom: '20px',
    textAlign: 'center',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    position: 'relative',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    width: '100%',
    paddingLeft: '40px', // İkon için boşluk
  },
  icon: {
    position: 'absolute',
    left: '10px',
    color: '#aaa',
  },
  passwordToggle: {
    position: 'absolute',
    right: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#aaa',
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    marginTop: '-10px',
  },
 checkbox: {
   
    cursor: 'pointer',
  },
  checkboxLabel: {
    fontSize: '16px',
    marginLeft: '5px',
    color: '#000',  // Yazıyı siyah yapar
  },
  loginButton: {
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '25px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
  },
  registerText: {
    fontSize: '16px',
    color: '#333',
    textAlign: 'center',
  },
  registerLink: {
    color: '#007bff',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};

const mobileStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0',
    
     paddingLeft: '20px',
    paddingRight: '20px',
  },
  logo: {
    marginBottom: '20px',
    width: '150px',
    height: '150px',
  },
  formContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '40px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
  },
  title: {
    fontSize: '20px',
    color: '#000000',
    marginBottom: '20px',
    textAlign: 'center',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    width: '100%',
    position: 'relative',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    width: '100%',
    paddingLeft: '40px', // İkon için boşluk
  },
  icon: {
    position: 'absolute',
    left: '10px',
    color: '#aaa',
  },
  passwordToggle: {
    position: 'absolute',
    right: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#aaa',
  },
    checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    marginTop: '-10px',
  },
 checkbox: {
    marginLeft: '10px',
    cursor: 'pointer',
  },
  checkboxLabel: {
    fontSize: '13px',
    marginLeft: '5px',
    color: '#000',  // Yazıyı siyah yapar
  },
  loginButton: {
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '25px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
  },
  registerText: {
    fontSize: '16px',
    color: '#333',
    textAlign: 'center',
  },
  registerLink: {
    color: '#007bff',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};

export default LoginScreen;
