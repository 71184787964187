import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import cityData from '../il_ilceler.json';
import { MdLocationOn, MdLocationCity, MdEvent, MdWork } from 'react-icons/md';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const InsanKaynaklariScreen = () => {
    const navigate = useNavigate();
    const [komisyonSayilari, setKomisyonSayilari] = useState([]);
    const [genelToplam, setGenelToplam] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [cityItems, setCityItems] = useState([]);
    const [selectedCommission, setSelectedCommission] = useState('');
    const [districtItems, setDistrictItems] = useState([]);
    const [selectedCityName, setSelectedCityName] = useState('');
    const [selectedDistrictName, setSelectedDistrictName] = useState('');
    useEffect(() => {
        const checkUserId = async () => {
            const userId = await localStorage.getItem('userId');
            if (!userId) {
                navigate('/login', { replace: true });
            }
        };

        checkUserId();
    }, [navigate]);

    useEffect(() => {
        fetchData();
    }, [selectedDate, selectedCommission, selectedCityName, selectedDistrictName]);

    const fetchData = () => {
        const db = getDatabase();
        const counts = {};
        let toplam = 0;
    
        // Sabit listemiz
        const komisyonlar = [
            'Adilane', 'Dış İlişkiler', 'Genel Sekreterlik', 'Gönül Dostları', 'Hukuk Ve Muhasebat',
            'Alınteri ve Emek', 'İdari Ve Mali İşler', 'İmar Akademi', 'İnsan Kaynakları', 'Kardeşlik Ve Birlik',
            'Kültür Ve Sanat', 'Maarif ve Medeniyet', 'Mekteb-i Meva', 'Mekteb-i Şifa', 'Tarım, Orman Ve Doğa Bilimleri',
            'Teknikhane', 'Yayınlar', 'Tanıtım ve Medya', 'Spor Komisyonu', 'Kişisel Gelişim', 'Konya İMAR'
        ];
    
        // İlk olarak tüm komisyonları sayımızı 0 olarak ayarlayalım
        komisyonlar.forEach((komisyon) => {
            counts[komisyon] = 0;
        });
    
        const globalUserRef = ref(db, `/kullanicilar/${localStorage.getItem('userId')}`);
        onValue(globalUserRef, (globalSnapshot) => {
            const globalUserData = globalSnapshot.val();
            const kullaniciRef = ref(db, '/kullanicilar');
            const gorevRef = ref(db, 'GorevBilgiler');
    
            onValue(gorevRef, (gorevSnapshot) => {
                const gorevData = gorevSnapshot.val();
                const filteredUserIds = new Set();
    
                if (gorevData) {
                    Object.entries(gorevData).forEach(([userId, gorevler]) => {
                        Object.values(gorevler).forEach((gorev) => {
                            const komisyon = gorev.Komisyon;
                            const gorevBaslangicTarihi = new Date(gorev.GorevbaslangicTarihi);
                            const gorevBitisTarihi = new Date(gorev.GorevBitisTarihi);
                            const currentDate = new Date(selectedDate);
    
                            if (
                                currentDate >= gorevBaslangicTarihi &&
                                currentDate <= gorevBitisTarihi &&
                                komisyon !== 'İmar Topluluğu Üyesi Değilim'
                            ) {
                                filteredUserIds.add(userId);
                            }
                        });
                    });
                }
    
                onValue(kullaniciRef, (snapshot) => {
                    snapshot.forEach((kullaniciSnapshot) => {
                        const data = kullaniciSnapshot.val();
                        if (filteredUserIds.has(kullaniciSnapshot.key)) {
                            if (
                                (selectedCommission === '' || data.otherJob === selectedCommission) &&
                                (selectedCityName === '' || data.selectedCityName === selectedCityName) &&
                                (selectedDistrictName === '' || data.selectedDistrictName === selectedDistrictName)
                            ) {
                                const komisyon = data.otherJob;
                                if (counts.hasOwnProperty(komisyon)) {
                                    counts[komisyon] += 1;
                                }
                            }
                        }
                    });
    
                    toplam = Object.values(counts).reduce((a, b) => a + b, 0);
    
                    const countsArray = komisyonlar.map((komisyon) => ({
                        komisyon: komisyon,
                        kisiSayisi: counts[komisyon],
                    }));
    
                    setKomisyonSayilari(countsArray);
                    setGenelToplam(toplam);
                });
            });
        });
    };
    
      
    
    

      const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    
    const handleCityChange = (e) => {
        const value = e.target.value;
        setSelectedCity(value);
        loadDistricts(value);
        const selectedCityItem = cityItems.find(item => item.value === value);
        setSelectedCityName(selectedCityItem ? selectedCityItem.label : '');
        setSelectedDistrict('');
        setSelectedDistrictName('');
    };
    
    const handleDistrictChange = (e) => {
        const value = e.target.value;
        setSelectedDistrict(value);
        const selectedDistrictItem = districtItems.find(item => item.value === value);
        setSelectedDistrictName(selectedDistrictItem ? selectedDistrictItem.label : '');
    };
    
    const handleCommissionChange = (e) => {
        setSelectedCommission(e.target.value);
    };
    
      const loadCities = () => {
        const cities = cityData.cityset.CITY.map(city => ({
            label: city['_cityname'],
            value: city['_cityid']
        }));
        setCityItems(cities);
    };
    
    const loadDistricts = (cityId) => {
        const city = cityData.cityset.CITY.find(city => city['_cityid'] === cityId);
        if (city && city.DISTRICT) {
            const districts = city.DISTRICT.map(district => ({
                label: district['DISTNAME'],
                value: district['DISTID']
            }));
            setDistrictItems(districts);
        } else {
            setDistrictItems([]);
        }
    };
    
    useEffect(() => {
        loadCities();
    }, []);
    const createPDF = async () => {
        const docDefinition = {
            content: [
                { text: 'İNSAN KAYNAKLARI RAPORU', style: 'header' },
                {
                    table: {
                        headerRows: 1,
                        widths: ['*', '*'],
                        body: [
                            [{ text: 'Komisyon', style: 'tableHeader' }, { text: 'Kişi', style: 'tableHeader' }],
                            ...komisyonSayilari.map(item => [item.komisyon, item.kisiSayisi]),
                            [{ text: 'Genel Toplam', colSpan: 2, alignment: 'left' }, genelToplam]
                        ]
                    }
                }
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                }
            },
            defaultStyle: {
                font: 'Roboto'
            }
        };

        pdfMake.createPdf(docDefinition).download('insan_kaynaklari_raporu.pdf');
    };
    const commissionOptions = [
        { label: 'Adilane', value: 'Adilane' },
        { label: 'Dış İlişkiler', value: 'Dış İlişkiler' },
        { label: 'Genel Sekreterlik', value: 'Genel Sekreterlik' },
        { label: 'Gönül Dostları', value: 'Gönül Dostları' },
        { label: 'Hukuk Ve Muhasebat', value: 'Hukuk Ve Muhasebat' },
        { label: 'Alınteri ve Emek', value: 'Alınteri ve Emek' },
        { label: 'İdari Ve Mali İşler', value: 'İdari Ve Mali İşler' },
        { label: 'İmar Akademi', value: 'İmar Akademi' },
        { label: 'İnsan Kaynakları', value: 'İnsan Kaynakları' },
        { label: 'Kardeşlik Ve Birlik', value: 'Kardeşlik Ve Birlik' },
        { label: 'Kültür Ve Sanat', value: 'Kültür Ve Sanat' },
        { label: 'Maarif ve Medeniyet', value: 'Maarif ve Medeniyet' },
        { label: 'Mekteb-i Meva', value: 'Mekteb-i Meva' },
        { label: 'Mekteb-i Şifa', value: 'Mekteb-i Şifa' },
        { label: 'Tarım, Orman Ve Doğa Bilimleri', value: 'Tarım, Orman Ve Doğa Bilimleri' },
        { label: 'Teknikhane', value: 'Teknikhane' },
        { label: 'Yayınlar', value: 'Yayınlar' },
        { label: 'Tanıtım ve Medya', value: 'Tanıtım ve Medya' },
        { label: 'Spor Komisyonu', value: 'Spor Komisyonu' },
        { label: 'Kişisel Gelişim', value: 'Kişisel Gelişim' },
        { label: 'Konya İmar', value: 'Konya İmar' },
        { label: 'İmar Topluluğu Üyesi Değilim', value: 'İmar Topluluğu Üyesi Değilim' }
      ];
    const createAndDownloadExcel = async () => {
        try {
            const ws = XLSX.utils.json_to_sheet([
                ...komisyonSayilari,
                { komisyon: 'Genel Toplam', kisiSayisi: genelToplam }
            ]);

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Komisyonlar");

            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([wbout], { type: 'application/octet-stream' });
            saveAs(blob, 'komisyonlar.xlsx');
        } catch (error) {
            console.error("Excel dosyası oluşturulurken hata oluştu: ", error);
        }
    };

    return (
        <div style={styles.insankaynaklariContainer}>
            <div style={styles.insankaynaklariHeaderrr}>
                <p style={styles.reportTitle}>İNSAN KAYNAKLARI RAPORU</p>
                <div style={styles.divider} />
            </div>
            <div style={styles.registerInputRow}>
                <label style={styles.itemTextHeader}>Tarih Seçiniz:</label>
                <DatePicker selected={selectedDate} onChange={handleDateChange} dateFormat="dd-MM-yyyy" />
            </div>
            <div style={styles.registerInputRow}>
      <MdEvent size={25} color="#737373" />
      <select value={selectedCommission} onChange={handleCommissionChange}>
        <option value="">Komisyon Seçiniz</option>
        {commissionOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>

    <div style={styles.registerInputRow}>
      <MdLocationOn size={25} color="#737373" />
      <select value={selectedCity} onChange={handleCityChange}>
        <option value="">İl Seçiniz</option>
        {cityItems.map(item => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
    </div>

    <div style={styles.registerInputRow}>
      <MdLocationCity size={25} color="#737373" />
      <select value={selectedDistrict} onChange={handleDistrictChange} disabled={!selectedCity || !districtItems.length}>
        <option value="">İlçe Seçiniz</option>
        {districtItems.map((item) => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
    </div>

            <div>
                <div style={styles.insankaynaklariTableHeader}>
                    <p style={styles.insankaynaklariTableHeaderCell}>Komisyon</p>
                    <p style={styles.insankaynaklariTableHeaderCell}>Kişi</p>
                </div>
                {komisyonSayilari.map((item, index) => (
                    <div key={index} style={styles.insankaynaklariTableRow}>
                        <p style={styles.insankaynaklariTableCellBaslik}>{item.komisyon}</p>
                        <div style={styles.insankaynaklariDivider} />
                        <p style={styles.insankaynaklariTableCell}>{item.kisiSayisi}</p>
                    </div>
                ))}
                <div style={styles.insankaynaklariTableFooter}>
                    <p style={styles.insankaynaklariTableCellBaslik}>Genel Toplam</p>
                    <p style={styles.insankaynaklariTableCell}>{genelToplam}</p>
                </div>
            </div>
            <div style={styles.insankaynaklaributtonview}>
                <button onClick={createPDF} style={styles.downloadButton}>
                    <p style={styles.downloadButtonText}>PDF İndir</p>
                </button>
                <button onClick={createAndDownloadExcel} style={styles.downloadButton2}>
                    <p style={styles.downloadButtonText2}>Excel İndir</p>
                </button>
            </div>
        </div>
    );
};

const styles = {
    insankaynaklariContainer: {
        padding: 20,
        backgroundColor: '#f5f5f5',
    },
    insankaynaklariHeaderrr: {
        marginBottom: 20,
    },
    reportTitle: {
        fontSize: 24,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 10,
    },
    divider: {
        height: 1,
        backgroundColor: '#000',
        marginVertical: 10,
    },
    insankaynaklariTableHeader: {
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '1px solid #000',
        paddingBottom: 5,
        marginBottom: 5,
    },
    insankaynaklariTableHeaderCell: {
        flex: 1,
        fontWeight: 'bold',
        fontSize: 16,
        textAlign: 'center',
    },
    insankaynaklariTableRow: {
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '1px solid #ccc',
        paddingVertical: 10,
    },
    insankaynaklariTableCellBaslik: {
        flex: 1,
        fontSize: 14,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    insankaynaklariTableCell: {
        flex: 1,
        fontSize: 14,
        textAlign: 'center',
    },
    insankaynaklariTableFooter: {
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #000',
        paddingTop: 10,
        marginTop: 10,
    },
    insankaynaklaributtonview: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    downloadButton: {
        backgroundColor: '#007bff',
        padding: 10,
        borderRadius: 5,
        border: 'none',
        cursor: 'pointer',
        width: '50%'
    },
    downloadButtonText: {
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
        margin: 0,
    },
    downloadButton2: {
        backgroundColor: '#28a745',
        padding: 10,
        borderRadius: 5,
        border: 'none',
        cursor: 'pointer',
        width: '50%'
    },
    downloadButtonText2: {
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
        margin: 0,
    },
    registerInputRow: {
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    itemTextHeader: {
        fontWeight: 'bold',
        marginBottom: 5,
        marginRight: 10
    },
};

export default InsanKaynaklariScreen;
