import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import cityData from '../il_ilceler.json';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { MdLocationOn, MdLocationCity, MdEvent, MdWork } from 'react-icons/md';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const InsanKaynaklariRaporScreen = () => {
  const navigate = useNavigate();
  const firebaseConfig = {
    apiKey: "AIzaSyDdugV4yuPfYBfVdTs6xEEFhrB6H9XgvRA",
    authDomain: "dernek-9b26c.firebaseapp.com",
    databaseURL: "https://dernek-9b26c-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "dernek-9b26c",
    storageBucket: "dernek-9b26c.appspot.com",
    messagingSenderId: "58054737120",
    appId: "1:58054737120:web:c4535a79d85d6a63102485",
    measurementId: "G-N3BL5L2NZN"
  };

  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [kullaniciBilgileri, setKullaniciBilgileri] = useState([]);
  const [expandedIds, setExpandedIds] = useState(new Set());
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [cityItems, setCityItems] = useState([]);
  const [selectedCommission, setSelectedCommission] = useState('');
  const [districtItems, setDistrictItems] = useState([]);
  const [selectedCityName, setSelectedCityName] = useState('');
  const [selectedDistrictName, setSelectedDistrictName] = useState('');
  const [originalKullaniciBilgileri, setOriginalKullaniciBilgileri] = useState([]);

  const [showNoJob, setShowNoJob] = useState(false);
  useEffect(() => {
    const checkUserId = async () => {
      const userId = await localStorage.getItem('userId');
      if (!userId) {
        navigate('/login', { replace: true });
      }
    };
    checkUserId();
  }, [navigate]);

 
  useEffect(() => {
    fetchData();
  }, [selectedDate, selectedCommission, selectedCityName, selectedDistrictName]);

  const toggleShowNoJob = () => {
    if (showNoJob) {
      // Geri alma durumu: orijinal kullanıcı bilgilerini geri yükle
      setKullaniciBilgileri(originalKullaniciBilgileri);
    } else {
      // İlk kez yedekleme yap
      if (originalKullaniciBilgileri.length === 0) {
        setOriginalKullaniciBilgileri([...kullaniciBilgileri]);
      }
      // Görevi bulunmayanları göster
      const filteredKullaniciBilgileri = kullaniciBilgileri.filter(kullanici => 
        kullanici.otherJob === 'İmar Topluluğu Üyesi Değilim'
      );
      setKullaniciBilgileri(filteredKullaniciBilgileri);
    }
    setShowNoJob(!showNoJob);
  };
  
  // fetchData fonksiyonunu aşağıdaki gibi güncelleyin:
  const fetchData = () => {
    const db = getDatabase();
    const kullaniciRef = ref(db, '/kullanicilar');
    const gorevRef = ref(db, 'GorevBilgiler');
  
    onValue(kullaniciRef, (snapshot) => {
        let tempKullaniciBilgileri = [];
        snapshot.forEach((kullaniciSnapshot) => {
            const data = kullaniciSnapshot.val();
            let kullaniciData = {
                id: kullaniciSnapshot.key,
                fullName: data.fullName ? data.fullName.toUpperCase() : 'Bilinmiyor',
                ageDate: data.ageDate || '',
                department: data.department || '',
                email: data.email || '',
                employmentStatus: data.employmentStatus || '',
                extraGorev: data.extraGorev || '',
                faculty: data.faculty || '',
                graduationYear: data.graduationYear || '',
                highSchool: data.highSchool || '',
                langueDetails: data.langueDetails || '',
                maritalStatus: data.maritalStatus || '',
                merkezTasra: data.merkezTasra || '',
                otherDescription: data.otherDescription || '',
                otherJob: data.otherJob || '',
                phoneNumber: data.phoneNumber || '',
                selectedCityName: data.selectedCityName || '',
                selectedDistrictName: data.selectedDistrictName || '',
                selectedGorev: data.selectedGorev || '',
                selectedKademe: data.selectedKademe || '',
                selectedUniversity: data.selectedUniversity || '',
                yukseklisans: data.yukseklisans || ''
            };
            tempKullaniciBilgileri.push(kullaniciData);
        });
        setKullaniciBilgileri(tempKullaniciBilgileri);
  
        // Görev bilgilerini filtrelemek için gerekli değil
        onValue(gorevRef, (gorevSnapshot) => {
            const gorevData = gorevSnapshot.val();
            const filteredUserIds = new Set();
            const globalUserRef = ref(db, `/kullanicilar/${localStorage.getItem('userId')}`);
            onValue(globalUserRef, (globalSnapshot) => {
                const globalUserData = globalSnapshot.val();
  
                if (gorevData) {
                    Object.entries(gorevData).forEach(([userId, gorevler]) => {
                        Object.values(gorevler).forEach((gorev) => {
                            const gorevBaslangicTarihi = new Date(gorev.GorevbaslangicTarihi);
                            const gorevBitisTarihi = new Date(gorev.GorevBitisTarihi);
                            const currentDate = new Date(selectedDate);
  
                            if (
                                currentDate >= gorevBaslangicTarihi &&
                                currentDate <= gorevBitisTarihi &&
                                (globalUserData && (globalUserData.IKonay || gorev.otherJob === globalUserData.otherJob))
                            ) {
                                filteredUserIds.add(userId);
                            }
                        });
                    });
                }
  
                let filteredKullaniciBilgileri = tempKullaniciBilgileri.filter((kullanici) =>
                    filteredUserIds.has(kullanici.id) &&
                    (selectedCommission === '' || kullanici.otherJob === selectedCommission) &&
                    (selectedCityName === '' || kullanici.selectedCityName === selectedCityName) &&
                    (selectedDistrictName === '' || kullanici.selectedDistrictName === selectedDistrictName)
                );
  
                setKullaniciBilgileri(filteredKullaniciBilgileri.length > 0 ? filteredKullaniciBilgileri : tempKullaniciBilgileri);
            });
        });
    });
  };
  
  

  const handleCityChange = (e) => {
    const value = e.target.value;
    setSelectedCity(value);
    loadDistricts(value);
    const selectedCityItem = cityItems.find(item => item.value === value);
    setSelectedCityName(selectedCityItem ? selectedCityItem.label : '');
    setSelectedDistrict('');
    setSelectedDistrictName('');
  };

  const handleDistrictChange = (e) => {
    const value = e.target.value;
    setSelectedDistrict(value);
    const selectedDistrictItem = districtItems.find(item => item.value === value);
    setSelectedDistrictName(selectedDistrictItem ? selectedDistrictItem.label : '');
  };

  const handleCommissionChange = (e) => {
    setSelectedCommission(e.target.value);
  };
  

  const toggleUserDetails = (id) => {
    const newExpandedIds = new Set(expandedIds);
    if (newExpandedIds.has(id)) {
      newExpandedIds.delete(id);
    } else {
      newExpandedIds.add(id);
    }
    setExpandedIds(newExpandedIds);
  };

  const createPDF = () => {
    const docDefinition = {
      pageOrientation: 'landscape',
      content: [
        { text: 'Kişisel Gelişim Raporu', style: 'header' },
        {
          table: {
            headerRows: 1,
            widths: Array(21).fill('auto'),
            body: [
              [
                { text: 'Ad Soyad', fontSize: 6, bold: true },
                { text: 'Doğum Tarihi', fontSize: 6, bold: true },
                { text: 'Departman', fontSize: 6, bold: true },
                { text: 'E-mail', fontSize: 6, bold: true },
                { text: 'İstihdam Durumu', fontSize: 6, bold: true },
                { text: 'Ek Görev', fontSize: 6, bold: true },
                { text: 'Fakülte', fontSize: 6, bold: true },
                { text: 'Mezuniyet Yılı', fontSize: 6, bold: true },
                { text: 'Lise', fontSize: 6, bold: true },
                { text: 'Dil Detayları', fontSize: 6, bold: true },
                { text: 'Medeni Durum', fontSize: 6, bold: true },
                { text: 'Merkez/Taşra', fontSize: 6, bold: true },
                { text: 'Diğer Açıklamalar', fontSize: 6, bold: true },
                { text: 'Komisyon', fontSize: 6, bold: true },
                { text: 'Telefon Numarası', fontSize: 6, bold: true },
                { text: 'Şehir', fontSize: 6, bold: true },
                { text: 'İlçe', fontSize: 6, bold: true },
                { text: 'Seçilen Görev', fontSize: 6, bold: true },
                { text: 'Seçilen Kademe', fontSize: 6, bold: true },
                { text: 'Seçilen Üniversite', fontSize: 6, bold: true },
                { text: 'Yüksek Lisans', fontSize: 6, bold: true }
              ],
              ...kullaniciBilgileri.map(kullanici => [
                { text: kullanici.fullName || '', fontSize: 6 },
                { text: kullanici.ageDate || '', fontSize: 6 },
                { text: kullanici.department || '', fontSize: 6 },
                { text: kullanici.email || '', fontSize: 6 },
                { text: kullanici.employmentStatus || '', fontSize: 6 },
                { text: kullanici.extraGorev || '', fontSize: 6 },
                { text: kullanici.faculty || '', fontSize: 6 },
                { text: kullanici.graduationYear || '', fontSize: 6 },
                { text: kullanici.highSchool || '', fontSize: 6 },
                { text: kullanici.langueDetails || '', fontSize: 6 },
                { text: kullanici.maritalStatus || '', fontSize: 6 },
                { text: kullanici.merkezTasra || '', fontSize: 6 },
                { text: kullanici.otherDescription || '', fontSize: 6 },
                { text: kullanici.otherJob || '', fontSize: 6 },
                { text: kullanici.phoneNumber || '', fontSize: 6 },
                { text: kullanici.selectedCityName || '', fontSize: 6 },
                { text: kullanici.selectedDistrictName || '', fontSize: 6 },
                { text: kullanici.selectedGorev || '', fontSize: 6 },
                { text: kullanici.selectedKademe || '', fontSize: 6 },
                { text: kullanici.selectedUniversity || '', fontSize: 6 },
                { text: kullanici.yukseklisans || '', fontSize: 6 }
              ])
            ]
          },
          layout: {
            paddingLeft: () => 1,
            paddingRight: () => 1,
            paddingTop: () => 1,
            paddingBottom: () => 1,
          }
        }
      ],
      styles: {
        header: {
          fontSize: 12,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        table: {
          margin: [0, 5, 0, 15]
        }
      }
    };

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(docDefinition).download('kisisel_gelisim_raporu.pdf');
  };

  const createAndDownloadExcel = () => {
    const dataToExport = kullaniciBilgileri.map(kullanici => ({
      'Ad Soyad': kullanici.fullName || '',
      'Doğum Tarihi': kullanici.ageDate || '',
      'Departman': kullanici.department || '',
      'E-mail': kullanici.email || '',
      'İstihdam Durumu': kullanici.employmentStatus || '',
      'Ek Görev': kullanici.extraGorev || '',
      'Fakülte': kullanici.faculty || '',
      'Mezuniyet Yılı': kullanici.graduationYear || '',
      'Lise': kullanici.highSchool || '',
      'Dil Detayları': kullanici.langueDetails || '',
      'Medeni Durum': kullanici.maritalStatus || '',
      'Merkez/Taşra': kullanici.merkezTasra || '',
      'Diğer Açıklamalar': kullanici.otherDescription || '',
      'Komisyon': kullanici.otherJob || '',
      'Telefon Numarası': kullanici.phoneNumber || '',
      'Şehir': kullanici.selectedCityName || '',
      'İlçe': kullanici.selectedDistrictName || '',
      'Seçilen Görev': kullanici.selectedGorev || '',
      'Seçilen Kademe': kullanici.selectedKademe || '',
      'Seçilen Üniversite': kullanici.selectedUniversity || '',
      'Yüksek Lisans': kullanici.yukseklisans || ''
    }));

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'KişiselGelişimRaporu');
    const wbout = XLSX.write(wb, { type: 'binary' });
    const buf = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < wbout.length; ++i) {
      view[i] = wbout.charCodeAt(i) & 0xFF;
    }
    saveAs(new Blob([buf], { type: 'application/octet-stream' }), 'kisisel_gelisim_raporu.xlsx');
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const loadCities = () => {
    const cities = cityData.cityset.CITY.map(city => ({
        label: city['_cityname'],
        value: city['_cityid']
    }));
    setCityItems(cities);
};

const loadDistricts = (cityId) => {
    const city = cityData.cityset.CITY.find(city => city['_cityid'] === cityId);
    if (city && city.DISTRICT) {
        const districts = city.DISTRICT.map(district => ({
            label: district['DISTNAME'],
            value: district['DISTID']
        }));
        setDistrictItems(districts);
    } else {
        setDistrictItems([]);
    }
};

useEffect(() => {
    loadCities();
}, []);
const commissionOptions = [
  { label: 'Adilane', value: 'Adilane' },
  { label: 'Dış İlişkiler', value: 'Dış İlişkiler' },
  { label: 'Genel Sekreterlik', value: 'Genel Sekreterlik' },
  { label: 'Gönül Dostları', value: 'Gönül Dostları' },
  { label: 'Hukuk Ve Muhasebat', value: 'Hukuk Ve Muhasebat' },
  { label: 'Alınteri ve Emek', value: 'Alınteri ve Emek' },
  { label: 'İdari Ve Mali İşler', value: 'İdari Ve Mali İşler' },
  { label: 'İmar Akademi', value: 'İmar Akademi' },
  { label: 'İnsan Kaynakları', value: 'İnsan Kaynakları' },
  { label: 'Kardeşlik Ve Birlik', value: 'Kardeşlik Ve Birlik' },
  { label: 'Kültür Ve Sanat', value: 'Kültür Ve Sanat' },
  { label: 'Maarif ve Medeniyet', value: 'Maarif ve Medeniyet' },
  { label: 'Mekteb-i Meva', value: 'Mekteb-i Meva' },
  { label: 'Mekteb-i Şifa', value: 'Mekteb-i Şifa' },
  { label: 'Tarım, Orman Ve Doğa Bilimleri', value: 'Tarım, Orman Ve Doğa Bilimleri' },
  { label: 'Teknikhane', value: 'Teknikhane' },
  { label: 'Yayınlar', value: 'Yayınlar' },
  { label: 'Tanıtım ve Medya', value: 'Tanıtım ve Medya' },
  { label: 'Spor Komisyonu', value: 'Spor Komisyonu' },
  { label: 'Kişisel Gelişim', value: 'Kişisel Gelişim' },
  { label: 'Konya İmar', value: 'Konya İmar' },
  { label: 'İmar Topluluğu Üyesi Değilim', value: 'İmar Topluluğu Üyesi Değilim' }
];

return (
  <div style={styles.insanKaynaklariRaporKisiselGelisimContainer}>
    <div style={styles.registerInputRow}>
      <label style={styles.itemTextHeader}>Tarih Seçiniz:</label>
      <DatePicker selected={selectedDate} onChange={handleDateChange} dateFormat="dd-MM-yyyy" />
    </div>
    <div style={styles.registerInputRow}>
      <MdEvent size={25} color="#737373" />
      <select value={selectedCommission} onChange={handleCommissionChange}>
        <option value="">Komisyon Seçiniz</option>
        {commissionOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>

    <div style={styles.registerInputRow}>
      <MdLocationOn size={25} color="#737373" />
      <select value={selectedCity} onChange={handleCityChange}>
        <option value="">İl Seçiniz</option>
        {cityItems.map(item => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
    </div>

    <div style={styles.registerInputRow}>
      <MdLocationCity size={25} color="#737373" />
      <select value={selectedDistrict} onChange={handleDistrictChange} disabled={!selectedCity || !districtItems.length}>
        <option value="">İlçe Seçiniz</option>
        {districtItems.map((item) => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
    </div>

    <div style={styles.insanKaynaklariRaporRegisterInputRow}>
      {kullaniciBilgileri.map((item) => (
        <div key={item.id} style={styles.insanKaynaklariRaporListItem}>
          <div style={styles.header2}>
            <button onClick={() => toggleUserDetails(item.id)} style={styles.button}>
              <div style={styles.header}>
                <p style={styles.buttonText}>{item.fullName}</p>
              </div>
            </button>
          </div>
          {expandedIds.has(item.id) && (
            <div style={styles.expandedContent}>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>Doğum Tarihi: </p>
                <p style={styles.itemText}>{item.ageDate}</p>
              </div>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>Departman: </p>
                <p style={styles.itemText}>{item.department}</p>
              </div>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>E-mail: </p>
                <p style={styles.itemText}>{item.email}</p>
              </div>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>İstihdam Durumu: </p>
                <p style={styles.itemText}>{item.employmentStatus}</p>
              </div>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>Ek Görev: </p>
                <p style={styles.itemText}>{item.extraGorev}</p>
              </div>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>Fakülte: </p>
                <p style={styles.itemText}>{item.faculty}</p>
              </div>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>Mezuniyet Yılı: </p>
                <p style={styles.itemText}>{item.graduationYear}</p>
              </div>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>Lise: </p>
                <p style={styles.itemText}>{item.highSchool}</p>
              </div>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>Dil Detayları: </p>
                <p style={styles.itemText}>{item.langueDetails}</p>
              </div>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>Medeni Durum: </p>
                <p style={styles.itemText}>{item.maritalStatus}</p>
              </div>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>Merkez/Taşra: </p>
                <p style={styles.itemText}>{item.merkezTasra}</p>
              </div>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>Diğer Açıklamalar: </p>
                <p style={styles.itemText}>{item.otherDescription}</p>
              </div>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>Komisyon: </p>
                <p style={styles.itemText}>{item.otherJob}</p>
              </div>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>Telefon Numarası: </p>
                <p style={styles.itemText}>{item.phoneNumber}</p>
              </div>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>Şehir: </p>
                <p style={styles.itemText}>{item.selectedCityName}</p>
              </div>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>İlçe: </p>
                <p style={styles.itemText}>{item.selectedDistrictName}</p>
              </div>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>Seçilen Görev: </p>
                <p style={styles.itemText}>{item.selectedGorev}</p>
              </div>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>Seçilen Kademe: </p>
                <p style={styles.itemText}>{item.selectedKademe}</p>
              </div>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>Seçilen Üniversite: </p>
                <p style={styles.itemText}>{item.selectedUniversity}</p>
              </div>
              <div style={styles.kisiselgelisimitems}>
                <p style={styles.itemTextHeader}>Yüksek Lisans: </p>
                <p style={styles.itemText}>{item.yukseklisans}</p>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
    <div style={styles.insankaynaklaributtonview}>
      <button onClick={createPDF} style={styles.downloadButton}>
        <p style={styles.downloadButtonText}>PDF İndir</p>
      </button>
      <button onClick={toggleShowNoJob} style={styles.downloadButton3}>
    <p style={styles.downloadButtonText}>{showNoJob ? "Geri Al" : "Görevi Bulunmayanlar"}</p>
</button>
      <button onClick={createAndDownloadExcel} style={styles.downloadButton2}>
        <p style={styles.downloadButtonText2}>Excel İndir</p>
      </button>
    </div>
  </div>
);
};


const styles = {
  insanKaynaklariRaporKisiselGelisimContainer: {
    margin: 20,
  },
  insanKaynaklariRaporRegisterInputRow: {
    marginBottom: 20,
  },
  downloadButton: {
    backgroundColor: 'red',
    color: '#fff',
    padding: 10,
    borderRadius: 5,
    border: 'none',
    cursor: 'pointer',
    marginRight: 10,
    width: '50%',
  },
  registerInputRow: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
},
  downloadButtonText: {
    margin: 0,
    fontSize: 20
  },
  downloadButton2: {
    backgroundColor: '#28a745',
    color: '#fff',
    padding: 10,
    borderRadius: 5,
    border: 'none',
    cursor: 'pointer',
    marginRight: 10,
    width: '50%',
    fontSize: 20
  },
  downloadButton3: {
    backgroundColor: '#333',
    color: '#fff',
    padding: 10,
    borderRadius: 5,
    border: 'none',
    cursor: 'pointer',
    marginRight: 10,
    width: '50%',
    fontSize: 20
  },
  downloadButtonText2: {
    margin: 0,
  },
  insanKaynaklariRaporListItem: {
    border: '1px solid #ccc',
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
    width: '100%', // Tüm genişliği kapsaması için
    boxSizing: 'border-box', // Padding ve border ile birlikte tam genişlik için
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' // Gölgelendirme eklendi
    
  },
  header: {
    marginBottom: 10,
  },
  header2: {

    justifyContent: 'center',
    alingItems: 'center',
    display: 'flex'

  },
  itemTextHeader: {
    fontWeight: 'bold',
    marginBottom: 5,
    marginRight: 10
  },
  insankaynaklaributtonview: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
},
  itemTextHeader2: {
    fontWeight: 'bold',
    marginBottom: 5,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  itemText: {
    marginBottom: 5,
  },
  kisiselgelisimitems: {
    marginTop: 5,
    display: 'flex'
  },
  expandedContent: {
    transition: 'all 0.3s ease', // Smooth transition for expanded content
    padding: '10px 0', // İçeriklerin daha rahat okunması için padding ekledik
  },
  button: {
    width: '100%',
    textAlign: 'center', // Butonun ortasında yazsın

    border: 'none',
    cursor: 'pointer',
    
    color: '#000', // İsimleri beyaz yapıyoruz
    borderRadius: 40, // Tüm köşeleri başlangıçta sıfırlıyoruz
    borderTopLeftRadius: 40, // Sol üst köşeye radius veriyoruz
    borderTopRightRadius: 40, // Sağ üst köşeye radius veriyoruz
    position: 'relative',
    height: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
    
  },
  buttonText: {
    
    width: '100%',
    
    fontWeight: 'bold',
    fontSize: '15px'
  },
};

export default InsanKaynaklariRaporScreen;
