
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, onValue, set,push } from 'firebase/database';  // Firebase veritabanı işlemleri için
import { BrowserRouter as Router, Route } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import {
  
    MdEdit, // Düzeltme kalem ikonu, her türlü düzenleme için
    MdPeople, // Üye sayısı veya toplantı katılımcıları için insanlar ikonu
    MdMonetizationOn, // Mali işler için para ikonu
    MdEvent, // Planlanmış etkinlikler için etkinlik ikonu
    MdStar,
    MdSave
} from 'react-icons/md';


const CreateDuyuruScreen = ({ navigation }) => {
    const userId = localStorage.getItem("userId");
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    useEffect(() => {
      const checkUserId = async () => {
        const userId = await localStorage.getItem('userId');
        if (!userId) {
          navigate('/login', { replace: true });
        }
      };
    
      checkUserId();
    }, [navigate]);
    
    const handleSendPress = () => {
      if (!title.trim() || !description.trim()) {
        window.alert('Uyarı: Lütfen tüm alanları doldurunuz.');
        return;
      }
  
      const newDuyuru = {
        title: title.trim(),
        description: description.trim(),
        // Tarih ve zaman bilgisi eklenebilir, örneğin:
        createdAt: moment().format('YYYY-MM-DD / HH:mm:ss') // Şu anki tarih ve saati formatlayarak ekle
      };
  
      const db = getDatabase();
      const newDuyuruRef = push(ref(db, 'duyurular'));
      set(newDuyuruRef, newDuyuru)
        .then(() => {
          window.alert('Başarılı: Duyuru başarıyla oluşturuldu.');
          // Formu temizle veya başka bir sayfaya yönlendir
          setTitle('');
          setDescription('');
          navigate('/home');
        })
        .catch((error) => {
          console.error('Duyuru oluşturulurken hata oluştu:', error);
          window.alert('Hata: Duyuru oluşturulamadı, lütfen tekrar deneyin.');
        });
    };
  
    return (
      <div style={styles.createDuyuruContainer}>
       <img src={require('../assets/duyuru.png')}
          style={styles.duyurulogo}
        />
        <div style={styles.createDuyuruInputContainer}>
          <MdEdit name="format-title" size={24} color="#737373" style={styles.createDuyuruIcon} />
          <input 
            style={styles.createDuyuruInput}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            placeholder="Başlık"
            
          />
        </div>
        <div style={styles.createDuyuruInputContainer}>
          <MdEdit name="text" size={24} color="#737373" style={styles.createDuyuruIcon} />
          <input 
            style={styles.createDuyuruInput}
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            placeholder="Açıklama"
        
          />
        </div>
        <button 
          style={styles.createDuyuruSendButton}
          onClick={handleSendPress}
        >
           <img src={require('../assets/background4.png')}
            style={styles.backgroundImage}
          />
          <p style={styles.createDuyuruSendButtonText}>Gönder</p>
        </button>
      </div>
    );
  };
  
  const styles = {
    createDuyuruContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      paddingTop: '200px'
    },
    duyurulogo: {
      width: '150px',
      marginBottom: '20px',
    },
    createDuyuruInputContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px',
      borderBottom: '1px solid #ccc',
      width: '100%',
    },
    createDuyuruIcon: {
      marginRight: '10px',
    },
    createDuyuruInput: {
      flex: 1,
      padding: '10px',
      fontSize: '16px',
      borderBottom: 'none',
      outline: 'none',
      border: 'none',
      backgroundColor: 'transparent',
    },
    createDuyuruSendButton: {
      marginTop: '20px',
      padding: '10px 20px',
      backgroundColor: '#0056b3',
      color: 'white',
      borderRadius: '5px',
      fontSize: '16px',
      fontWeight: 'bold',
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden',
    },
    createDuyuruSendButtonText: {
      textAlign: 'center',
    },
    backgroundImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: -1,
      opacity: 0.3,
    },
  };
  

export default CreateDuyuruScreen;