import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';  // Firebase veritabanı işlemleri için
import { BrowserRouter as Router, Route } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';

const ProfileScreen = ({ navigation }) => {
  const navigate = useNavigate();
    // Kullanıcı bilgilerini saklamak için state
    const [userInfo, setUserInfo] = useState(null);

    const [hasIkPermission, setHasIkPermission] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };
  useEffect(() => {
    const checkUserId = async () => {
      const userId = await localStorage.getItem('userId');
      if (!userId) {
        navigate('/login', { replace: true });
      }
    };
  
    checkUserId();
  }, [navigate]);
  
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = isMobile ? mobileStyles : desktopStyles;
  
  // Her bir detay için kullanılabilecek bir yardımcı bileşen
  const ProfileDetail = ({ label, value }) => (
    <div style={{ marginBottom: 10 }}>
      <p style={styles.profileLabel}>{label}</p>
      <p style={styles.profileText}>{value}</p>
    </div>
  );
  
  
  useEffect(() => {
    // Global değişkenden kullanıcı ID'sini alın
    const userId = localStorage.getItem("userId");
  
    if (userId) {
      const db = getDatabase();
      const userRef = ref(db, 'kullanicilar/' + userId);
  
      // Veritabanından kullanıcı bilgilerini dinle
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setUserInfo(data);
        }
      }, {
        onlyOnce: true
      });
    }
  }, []);
  
    // Eğer kullanıcı bilgileri henüz yüklenmediyse, bir yükleme göstergesi veya boş bir ekran gösterebilirsiniz
    if (!userInfo) {
      return <p>Yükleniyor...</p>;
    }
    
    return (
   
      <div style={styles.profileContainer}>
         <div style={styles.profileSectionHeader}>
     <p style={styles.profileHeader}>{userInfo.fullName.toUpperCase()}</p>
        
      </div>
  
      <div style={styles.profileSection}>
        <ProfileDetail label="E-posta" value={userInfo.email} />
        <ProfileDetail label="Telefon" value={userInfo.phoneNumber} />
        <ProfileDetail label="Şehir" value={userInfo.selectedCityName} />
        <ProfileDetail label="İlçe" value={userInfo.selectedDistrictName} />
        <ProfileDetail label="Lise" value={userInfo.highSchool} />
        <ProfileDetail label="Üniversite" value={userInfo.selectedUniversity} />
        <ProfileDetail label="Fakülte" value={userInfo.faculty} />
        <ProfileDetail label="Bölüm" value={userInfo.department} />
        <ProfileDetail label="Mezuniyet Yılı" value={userInfo.graduationYear} />
        <ProfileDetail label="Doğum Tarihi" value={userInfo.ageDate} />
        <ProfileDetail label="Yüksek Lisans/Doktora Bilgisi" value={userInfo.yukseklisans} />
        <ProfileDetail label="Yabancı Dil Bilgisi" value={userInfo.langueDetails} />
        <ProfileDetail label="Medeni Durum" value={userInfo.maritalStatus} />
        <ProfileDetail label="Çalışma Durumu" value={userInfo.employmentStatus} />
        <ProfileDetail label="Çalışılan Kurum" value={userInfo.otherJob} />
        <ProfileDetail label="Görev ve Pozisyon" value={userInfo.otherDescription} />
        <ProfileDetail label="Merkez Taşra Ayrımı" value={userInfo.merkezTasra} />
        <ProfileDetail label="Görev Yaptığınız Kademe" value={userInfo.selectedKademe} />
        <ProfileDetail label="Göreviniz" value={userInfo.selectedGorev} />
        <ProfileDetail label="Diğer Görev Bilgisi" value={userInfo.extraGorev} />
        <ProfileDetail label="Ek Bilgiler" value={userInfo.additionalInfo} />
      </div>
      <p style={styles.infotext}>Profilde yapılacak değişiklikler için üst kurulla iletişime geçiniz.</p>
    </div>
    );
  };

  const desktopStyles = {
    profileContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '20px',
      backgroundColor: '#f0f0f0', // Arka plan rengi
    },
    infotext:{
      fontSize: '20px',
      fontWeight: 'bold',
      color: 'black',
      textAlign: 'center',
      marginBottom: '10px',
    },
    profileSectionHeader: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '20px',
    },
    profileHeader: {
      fontSize: '40px',
      fontWeight: 'bold',
      color: '#333',
      textAlign: 'center',
      marginBottom: '10px',
    },
    profileSection: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '20px',
      backgroundColor: 'white', // Arka plan rengi
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Gölge efekti
      borderRadius: '10px', // Kenar yuvarlaklığı
      maxWidth: '800px', // Maksimum genişlik
    },
    profileLabel: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#666',
      fontSize: 25,
      marginBottom: '5px',
    },
    profileText: {
      fontSize: '14px',
      color: '#333',
      fontSize:22,
      marginBottom: '10px',
    }
  };
  
  const mobileStyles = {
    profileContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '10px',
      backgroundColor: '#f0f0f0',
    },
    profileSectionHeader: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '15px',
    },
    profileHeader: {
      fontSize: '30px',
      fontWeight: 'bold',
      color: '#333',
      textAlign: 'center',
      marginBottom: '8px',
    },
    profileSection: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '15px',
      backgroundColor: 'white',
      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
      borderRadius: '8px',
      maxWidth: '90%',
      margin: '0 auto',
    },
    profileLabel: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#666',
      marginBottom: '5px',
    },
    profileText: {
      fontSize: '16px',
      color: '#333',
      marginBottom: '8px',
    },
    infotext:{
      fontSize: '20px',
      fontWeight: 'bold',
      color: 'black',
      textAlign: 'center',
      marginBottom: '10px',
    },
  };
  
export default ProfileScreen;