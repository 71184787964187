import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { signInWithEmailAndPassword, sendPasswordResetEmail , getAuth, createUserWithEmailAndPassword, auth } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';
import { 
 
    MdEmail
   
  } from 'react-icons/md';

const ForgotPasswordScreen = ({ navigation }) => {
    const [email, setEmail] = useState(''); // E-posta için state
    const auth = getAuth();
    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    const handleSendPress = () => {
      if (email.trim() === '') {
        window.alert('Uyarı: Lütfen e-posta adresinizi giriniz.');
        return;
      } if (!validateEmail(email)) {
        window.alert('Hata: Geçersiz e-posta formatı.');
        return;
      }
      
      sendPasswordResetEmail(auth, email.trim())
      .then(() => {
        window.alert(
          'Başarılı: Eğer e-posta adresiniz sistemimizde kayıtlıysa, şifre sıfırlama talimatları e-posta adresinize gönderilecektir.'
        
        );
      })
        .catch((error) => {
          if (error.code === 'auth/user-not-found') {
            window.alert('Hata: Bu e-posta adresi sistemimizde kayıtlı değil.');
          } else {
            window.alert('Hata: Bir hata oluştu, lütfen daha sonra tekrar deneyiniz.');
          }
        });
    };
  
  
    return (
      <div style={styles.forgotpasswordContainer}>
       <div style={styles.forgotpasswordimageContainer}>
       <img src={require('../assets/forgoticon.png')} // Resminizi buraya koyun
            style={styles.forgotpasswordIconImage}
          />
          </div>
        <div style={styles.forgotpasswordInputContainer}>
          <MdEmail name="email" size={20} color="#737373" style={styles.forgotpasswordIcon} />
          <input 
            style={styles.forgotpasswordInput} 
            onChange={(e) => setEmail(e.target.value)}
            value={email} 
            placeholder="E-posta adresinizi girin"
         
          
          />
        </div>

        <button 
          style={styles.forgotpasswordSendButton} 
          onClick={handleSendPress}
        >
         <img src={require('../assets/background4.png')}// Resminizin yolu
      style={styles.backgroundImage}
    />
          <p style={styles.forgotpasswordSendButtonText}>Gönder</p>
        </button>
      </div>
    );
  };
  const styles = {
    forgotpasswordContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 10,
      padding: 20,
      minHeight: '100vh',
    },
    forgotpasswordimageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 20,
    },
    forgotpasswordIconImage: {
      width: 300,
      height: 300,
      borderRadius: 200,
      marginBottom: 20
    },
    forgotpasswordInputContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: '#ffffff',
      borderRadius: 5,
      padding: 10,
      marginBottom: 20,
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      width: '100%',
      maxWidth: 400,
      marginBottom: 20
    },
    forgotpasswordIcon: {
      marginRight: 10,
    },
    forgotpasswordInput: {
      flex: 1,
      height: 40,
      border: 'none',
      outline: 'none',
      fontSize: 16,
    },
    forgotpasswordSendButton: {
      backgroundColor: '#333333',
      borderRadius: 5,
      paddingVertical: 10,
      paddingHorizontal: 20,
      cursor: 'pointer',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative', // Resim için
      width: '100%',
      maxWidth: 200,
    },
    forgotpasswordSendButtonText: {
      color: '#ffffff',
      fontSize: 16,
      zIndex: 1, // Resmin altında kalmaması için
      
    },
    backgroundImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: 5,
      opacity: 0.3, // Resmin şeffaflığı
    },
  };
  

export default ForgotPasswordScreen;
