import React, { useState } from 'react';

const ConfirmationPopup = ({ message, onConfirm, onCancel }) => {
    return (
        <div style={styles.overlay}>
            <div style={styles.popupContainer}>
                <p>{message}</p>
                <div style={styles.buttonContainer}>
                    <button style={styles.cancelButton} onClick={onCancel}>Hayır</button>
                    <button style={styles.confirmButton} onClick={onConfirm}>Evet</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Arka planı siyah ve bulanık yapar
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999, // Diğer bileşenlerin üzerine çıkmasını sağlar
    },
    popupContainer: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        maxWidth: '400px', // Pop-up'ı büyütmek için genişliği artırabilirsiniz
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
    },
    cancelButton: {
        backgroundColor: '#e74c3c',
        color: '#fff',
        padding: '10px 20px',
        borderRadius: '5px',
        marginRight: '10px',
        cursor: 'pointer',
    },
    confirmButton: {
        backgroundColor: '#2ecc71',
        color: '#fff',
        padding: '10px 20px',
        borderRadius: '5px',
        cursor: 'pointer',
    },
};

export default ConfirmationPopup;
