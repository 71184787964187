import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';

const PersonalDevelopmentScreen = () => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [noDataCount, setNoDataCount] = useState(0); // No data count state
    const [noDataToday, setNoDataToday] = useState(false); // Today's no data state


    useEffect(() => {
        const db = getDatabase();
        const userId = localStorage.getItem("userId");
        if (userId) {
            const userRef = ref(db, 'gorevler/' + userId);
            onValue(userRef, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const allDates = Object.keys(data).sort((a, b) => a.localeCompare(b));
                    const missionsWithCompleteDates = calculateCompleteDateRange(allDates, data);
                    setNoDataCount(missionsWithCompleteDates.filter(mission => !Object.keys(mission.data).length).length);
    
                    // Check if today's date has data
                    const todayDate = formatDate(new Date());
                    setNoDataToday(!data[todayDate] || Object.keys(data[todayDate]).length === 0);
                } else {
                    const currentDate = new Date();
                    const pastWeekDates = getPastWeekDates(currentDate);
                    const emptyMissions = pastWeekDates.map(date => ({
                        date: formatDate(date),
                        data: {}
                    }));
                    setNoDataCount(emptyMissions.length);
    
                    // If no data exists, today also has no data
                    const todayDate = formatDate(new Date());
                    setNoDataToday(true);
                }
            });
        }
    }, []);
    
    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Fetch no data count from Firebase
    useEffect(() => {
        const db = getDatabase();
        const userId = localStorage.getItem("userId");
        if (userId) {
            const userRef = ref(db, 'gorevler/' + userId);
            onValue(userRef, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const allDates = Object.keys(data).sort((a, b) => a.localeCompare(b));
                    const missionsWithCompleteDates = calculateCompleteDateRange(allDates, data);
                    setNoDataCount(missionsWithCompleteDates.filter(mission => !Object.keys(mission.data).length).length);
                } else {
                    const currentDate = new Date();
                    const pastWeekDates = getPastWeekDates(currentDate);
                    const emptyMissions = pastWeekDates.map(date => ({
                        date: formatDate(date),
                        data: {}
                    }));
                    setNoDataCount(emptyMissions.length);
                }
            });
        }
    }, []);

    const calculateCompleteDateRange = (allDates, data) => {
        let dateArray = [];
        let startDate = new Date(allDates[0].split('-').reverse().join('-'));
        let currentDate = new Date();
      
        while (startDate <= currentDate) {
          let formattedDate = formatDate(startDate);
          dateArray.push({
            date: formattedDate,
            data: data[formattedDate] || {}
          });
          startDate.setDate(startDate.getDate() + 1);
        }
        return dateArray;
      };
      
      const formatDate = (date) => {
        return [
          String(date.getDate()).padStart(2, '0'),
          String(date.getMonth() + 1).padStart(2, '0'),
          date.getFullYear(),
        ].join('-');
      };
      
      const getPastWeekDates = (currentDate) => {
        let dates = [];
        for (let i = 0; i < 7; i++) {
            const date = new Date(currentDate);
            date.setDate(currentDate.getDate() - i);
            dates.push(date);
        }
        return dates;
    };

    const styles = isMobile ? mobileStyles : desktopStyles;

    return (
        <div style={styles.personalDevelopmentContainer}>
            <div style={styles.personalDevelopmentHeader}>
                <p style={styles.personalDevelopmentTitle}>KİŞİSEL GELİŞİM SİSTEMİ</p>
            </div>
            <div style={styles.imageContainer}>
                <img 
                    src={require('../assets/ust.png')} // Yolu doğru şekilde güncelleyin
                    style={styles.roundedImage}
                />
            </div>
            <button style={styles.personalDevelopmentButton} onClick={() => navigate('/daymission')}>
    {noDataToday && (
        <span style={styles.exclamationMark}>!</span> // Exclamation mark for no data today
    )}
    <p style={styles.personalDevelopmentButtonText}>GÖREVLER</p>
</button>

            <button style={styles.personalDevelopmentButton} onClick={() => navigate('/pastmission')}>
            {noDataCount > 0 && (
                    <span style={styles.badge}>{noDataCount}</span> // Notification badge
                )}
                <p style={styles.personalDevelopmentButtonTextLast}>GEÇMİŞ</p>
              
            </button>
            
        </div>
    );
};
const desktopStyles = {
    personalDevelopmentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        minHeight: '80vh',
        backgroundColor: '#f0f0f0',
        position: 'relative'
    },
    exclamationMark: {
        backgroundColor: 'red',
        color: 'white',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '-10px',
        right: '-10px',
        fontSize: '17px',
    },
    badge: {
        backgroundColor: 'red',
        color: 'white',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '-10px',
        right: '-10px',
        marginBottom: '20px',
        fontSize: '12px',
        fontSize: 18
    },
    personalDevelopmentHeader: {
        textAlign: 'center',
        position: 'absolute', // Başlık sayfanın en üstünde
        top: 0, // En üstte
        width: '100%', // Tam genişlik
        backgroundColor: '#f0f0f0', // Başlığın arka planı
        paddingTop: '20px',
        paddingBottom: '10px',
    },
    personalDevelopmentTitle: {
        fontSize: '40px',
        fontWeight: 'bold',
        color: '#333', // Başlık rengi
    },
    imageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '40px', // Resim ve butonlar arası boşluk
    },
    roundedImage: {
        width: '200px',
        height: '200px',
        
        objectFit: 'cover',
    },
    personalDevelopmentButton: {
        backgroundColor: 'white',
        color: 'black',
        padding: '15px 60px',
        fontSize: '18px',
        fontWeight: 'bold',
        borderRadius: '25px',
        cursor: 'pointer',
        marginBottom: '10px',
        border: '2px solid black', // Siyah çerçeve
        width: '80%',
        position: 'relative',
        boxShadow: '0 2px 5px rgba(0,0,0,0.9)',
        border: 'none', // Çizgiyi kaldırmak için eklenen özellik
        outline: 'none', // Çizgiyi kaldırmak için eklenen özellik 
    },
    personalDevelopmentButtonText: {
        color: 'black', // Görevler butonu yazı rengi
    },
    personalDevelopmentButtonLast: {
        backgroundColor: '#333', // Koyu gri (siyaha yakın)
        color: 'white',
        padding: '15px 60px',
        fontSize: '18px',
        fontWeight: 'bold',
        borderRadius: '25px',
        cursor: 'pointer',
        marginBottom: '10px',
        border: 'none',
        width: '80%',
    },
    personalDevelopmentButtonTextLast: {
        color: 'black', // Geçmiş butonu yazı rengi
    }
};
const mobileStyles = {
    personalDevelopmentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
        minHeight: '80vh',
        backgroundColor: '#f0f0f0',
        position: 'relative',
    },
    exclamationMark: {
        backgroundColor: 'red',
        color: 'white',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '-10px',
        right: '-10px',
        fontSize: '17px',
    },
    personalDevelopmentHeader: {
        textAlign: 'center',
        position: 'absolute',
        top: 0,
        width: '100%',
        backgroundColor: '#f0f0f0',
        paddingTop: '10px',
        paddingBottom: '5px',
    },
    personalDevelopmentTitle: {
        fontSize: '30px',
        fontWeight: 'bold',
        color: '#333',
    },
    imageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
    },
    roundedImage: {
        width: '150px',
        height: '150px',
        
        objectFit: 'cover',
    },
    personalDevelopmentButton: {
        backgroundColor: 'white',
        color: 'black',
        padding: '10px 30px',
        fontSize: '16px',
        fontWeight: 'bold',
        borderRadius: '20px',
        cursor: 'pointer',
        marginBottom: '8px',
        border: '2px solid black',
        width: '90%',
        position: 'relative' ,
          boxShadow: '0 2px 5px rgba(0,0,0,0.9)',
    border: 'none', // Çizgiyi kaldırmak için eklenen özellik
    outline: 'none', // Çizgiyi kaldırmak için eklenen özellik
    },
    personalDevelopmentButtonText: {
        color: 'black',
    },
    personalDevelopmentButtonLast: {
        backgroundColor: '#333',
        color: 'white',
        padding: '10px 30px',
        fontSize: '16px',
        fontWeight: 'bold',
        borderRadius: '20px',
        cursor: 'pointer',
        marginBottom: '8px',
        border: 'none',
        width: '90%',
    },
    personalDevelopmentButtonTextLast: {
        color: 'black',
    },
    badge: {
        backgroundColor: 'red',
        color: 'white',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '-10px',
        right: '-10px',
        marginBottom: '20px',
        fontSize: '12px',
        fontSize: 18
    },
};

export default PersonalDevelopmentScreen;
