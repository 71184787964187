import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, onValue , get} from 'firebase/database';  
import { BrowserRouter as Router, Route } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';

import { 
    MdEdit, 
    MdCheckCircle, 
    MdSwapHoriz, 
    MdAnnouncement, 
    MdPeople, 
    MdNotifications 
} from 'react-icons/md';

const TaskManagementScreen = ({ navigation }) => {
    const userId = localStorage.getItem("userId");
    const navigate = useNavigate();
    const [unapprovedCount, setUnapprovedCount] = useState(0);
    const [unapprovedCount2, setUnapprovedCount2] = useState(0);
    const [userInfo, setUserInfo] = useState(null);
    const [hasIkPermission, setHasIkPermission] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    useEffect(() => {
      const checkUserId = async () => {
        const userId = await localStorage.getItem('userId');
        if (!userId) {
          navigate('/login', { replace: true });
        }
      };
    
      checkUserId();
    }, [navigate]);
    
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
  
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const styles = isMobile ? mobileStyles : desktopStyles;
  
    useEffect(() => {
      const loadUserDetails = async () => {
        const userId = localStorage.getItem('userId');
        if (userId) {
          global.userDetails = { userId: userId };
          await loadAdditionalUserData(userId);
        }
      };
    
      const loadAdditionalUserData = async (userId) => {
          const db = getDatabase();
          const userRef = ref(db, `kullanicilar/${userId}`);
          
          onValue(userRef, (snapshot) => {
            const userData = snapshot.val();
            if (userData && (userData.ik || userData.IKyonetim)) {
              setHasIkPermission(true);
              console.log("IK izni var: " + userId);
            } else {
              setHasIkPermission(false);
              console.log("IK izni yok: " + userId);
            }
          }, {
            onlyOnce: true
          });
        };
    
        loadUserDetails();
      }, [navigate]);
  
    useEffect(() => {
      const db = getDatabase();
      if (userId) {
        const userRef = ref(db, 'kullanicilar/' + userId);
        onValue(userRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            setUserInfo(data);
          }
        }, {
          onlyOnce: true
        });
      }
    }, []);
  
    useEffect(() => {
      if (userInfo) {
        const db = getDatabase();
        const usersRef = ref(db, 'kullanicilar');
        onValue(usersRef, (snapshot) => {
          const usersData = snapshot.val();
          if (usersData) {
            let unapprovedUsers = [];
            if (userInfo.IKyonetim) {
              unapprovedUsers = Object.keys(usersData).filter(key => 
                usersData[key].onay === true && 
                usersData[key].IKstatus === "belirlenmedi"
              );
            } else {
              unapprovedUsers = Object.keys(usersData).filter(key => {
                const user = usersData[key];
                const isOtherJobMatch = user.otherJob && userInfo.otherJob && user.otherJob === userInfo.otherJob;
                return user.onay === false && 
                       user.status === "belirlenmedi" && 
                       isOtherJobMatch;
              });
            }
            setUnapprovedCount(unapprovedUsers.length);
          } else {
            setUnapprovedCount(0);
          }
        });
      }
    }, [userInfo]);
    
    useEffect(() => {
      const db = getDatabase();
      const globalUserRef = ref(db, `kullanicilar/${userId}`);
    
      onValue(globalUserRef, (snapshot) => {
        const globalUserData = snapshot.val();
        if (globalUserData) {
          // Gorev değişiklikleri içinden koşullara uyanları filtrele
          const usersRef = ref(db, 'gorevdegisikligi');
          onValue(usersRef, (snapshot) => {
            const data = snapshot.val();
            if(data){
              let filteredKeys;
              if (globalUserData.IKyonetim === true) {
                // Global kullanıcının IKyönetim alanı false ise eşleşenleri al
                filteredKeys = Object.keys(data).filter(key => 
                  data[key].onay === true && 
                  data[key].IKDurum === 'belirlenmedi'
                );
              } else {
                // Global kullanıcının IKyönetim alanı true ise sadece onay ve Durum durumuna göre filtrele
                filteredKeys = Object.keys(data).filter(key => 
                  data[key].yeniKomisyon === globalUserData.otherJob && 
                  data[key].onay === false && 
                  data[key].Durum === 'belirlenmedi'
                );
              }
              setUnapprovedCount2(filteredKeys.length);
            } else {
              setUnapprovedCount2(0); // Veri yoksa, sayacı 0 yap
            }
          });
        }
      });
    
      return () => {};
    }, []);
    
    const handleGorevDegisikligiNavigation = async () => {
      try {
        const userId = localStorage.getItem("userId");
        if (!userId) {
          console.error("Kullanıcı ID'si bulunamadı");
          return;
        }
  
        const db = getDatabase();
        const userRef = ref(db, `kullanicilar/${userId}`);
        const snapshot = await get(userRef);
  
        if (snapshot.exists()) {
          const userData = snapshot.val();
          if (userData.IKyonetim === true) {
            navigate('/ikgorevdegisiklik');
          } else {
            navigate('/gorevdegisiklik');
          }
        } else {
          console.error("Kullanıcı verisi bulunamadı");
        }
      } catch (error) {
        console.error("Veri alınırken hata oluştu: ", error);
      }
    };
  
    const handleOnayBekleyenlerNavigation = async () => {
      try {
        const userId = localStorage.getItem("userId");
        if (!userId) {
          console.error("Kullanıcı ID'si bulunamadı");
          return;
        }
    
        const db = getDatabase();
        const userRef = ref(db, `kullanicilar/${userId}`);
        const snapshot = await get(userRef);
    
        if (snapshot.exists()) {
          const userData = snapshot.val();
          if (userData.IKyonetim === true) {
            navigate('/ikconfirmation');
          } else {
            navigate('/confirmation');
          }
        } else {
          console.error("Kullanıcı verisi bulunamadı");
        }
      } catch (error) {
        console.error("Veri alınırken hata oluştu: ", error);
      }
    };
  
    return (
      <div style={styles.taskManagementContainer}>
        <p style={styles.taskManagementTitle}>GÖREV YÖNETİMİ</p>
        <div style={styles.divider}/>
  
        <div style={styles.taskManagementScrollView}>
  
          <div style={styles.taskManagementNotification}>
            <button style={styles.taskManagementButton} onClick={handleOnayBekleyenlerNavigation}>
              <MdCheckCircle size={30} style={styles.taskManagementIcon} />
              <p style={styles.taskManagementButtonText}>ONAY BEKLEYENLER</p>
              <div style={styles.taskManagementBadge}>
              <p style={styles.taskManagementBadgeText}>{unapprovedCount}</p>
            </div>
            </button>
          
          </div>
  
          <div style={styles.taskManagementNotification}>
            <button style={styles.taskManagementButton} onClick={handleGorevDegisikligiNavigation}>
              <MdSwapHoriz size={30} style={styles.taskManagementIcon} />
              <p style={styles.taskManagementButtonText}>Görev Değişikliği Onayı Bekleyenler</p>
              <div style={styles.taskManagementBadge}>
              <p style={styles.taskManagementBadgeText}>{unapprovedCount2}</p>
            </div>
            </button>
       
          </div>
  
          <div style={styles.taskManagementNotification}>
            <button style={styles.taskManagementButton} onClick={() => navigate('/createduyuru')}>
              <MdAnnouncement size={30} style={styles.taskManagementIcon} />
              <p style={styles.taskManagementButtonText}>DUYURU EKLE</p>
            </button>
          </div>
  
          <div style={styles.taskManagementNotification}>
            <button style={styles.taskManagementButton} onClick={() => navigate('/allusers')}>
              <MdPeople size={30} style={styles.taskManagementIcon} />
              <p style={styles.taskManagementButtonText}>TEŞKİLATI LİSTELE</p>
            </button>
          </div>
  
          <div style={styles.taskManagementNotification}>
            <button style={styles.taskManagementButton} onClick={() => navigate('/teskilatdegisik')}>
              <MdNotifications size={30} style={styles.taskManagementIcon} />
              <p style={styles.taskManagementButtonText}>TEŞKİLATTA DEĞİŞİKLİK BİLDİR</p>
            </button>
          </div>
  
        </div>  
      </div>
    );
  };

const commonStyles = {
  taskManagementButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 20px',
    backgroundColor: '#fff',
    color: 'white',
    borderRadius: '20px',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    textDecoration: 'none',
    width: '100%',
    maxWidth: '300px', // Buton boyutlarını eşitlemek için max-width eklendi
    position: 'relative', // Değişiklik burada yapıldı
    border: 'none', // Çizgiyi kaldırmak için eklenen özellik
    outline: 'none', 
    boxShadow: '0 2px 5px rgba(0,0,0,0.9)',
  },
  taskManagementIcon: {
    marginRight: '10px',
    position: 'absolute', // Değişiklik burada yapıldı
    left: '10px', // Değişiklik burada yapıldı
    color: 'black'
  },
  taskManagementBadge: {
    backgroundColor: '#ff4136',
    borderRadius: '20px',
    color: 'white',
    padding: '5px 10px',
    fontSize: '14px',
    marginLeft: '15px',
    width: 'fit-content',
  },
  taskManagementBadgeText: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  taskManagementButtonText: {
    textAlign: 'center', // Değişiklik burada yapıldı
    width: '100%', // Değişiklik burada yapıldı
    marginLeft: 20,
    color: 'black'
  },
};

const desktopStyles = {
  ...commonStyles,
  taskManagementContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  
    backgroundColor: '#f0f0f0',
    width: '100%',
    minHeight: '100vh',
  },
  taskManagementTitle: {
    position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      fontSize: '30px',
      fontWeight: 'bold',
      color: '#333',
       // Arka plan rengi, üst öğenin arka planının görünür olmasını sağlar
      zIndex: 1000, // Önde görünmesini sağlamak için yüksek bir z-index değeri
      padding: '10px 0',
      textAlign: 'center',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Hafif bir gölge ekleyerek öne çıkar

  },
  divider: {
    
    width: '100%',
    borderTop: '1px solid #ccc',
    margin: '15px 0',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Hafif bir gölge ekleyerek öne çıkar
  },
  taskManagementScrollView: {
    width: '100%',
    overflowY: 'auto',
  },
  taskManagementNotification: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
    padding: '10px',
    backgroundColor: '#f0f0f0',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    width: 'auto',
  },
};

const mobileStyles = {
  ...commonStyles,
  taskManagementContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
   
    backgroundColor: '#f0f0f0',
    width: '100%',
    minHeight: '100vh',
  },
  taskManagementTitle: {
    position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#333',
       // Arka plan rengi, üst öğenin arka planının görünür olmasını sağlar
      zIndex: 1000, // Önde görünmesini sağlamak için yüksek bir z-index değeri
      
      textAlign: 'center',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Hafif bir gölge ekleyerek öne çıkar

  },
  divider: {
    
    width: '100%',
    borderTop: '1px solid #ccc',
    margin: '15px 0',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Hafif bir gölge ekleyerek öne çıkar
  },
  taskManagementScrollView: {
    width: '100%',
    overflowY: 'auto',
  },
  taskManagementNotification: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '8px',
    padding: '8px',
    
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    width: 'auto',
  },
};

export default TaskManagementScreen;
