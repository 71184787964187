// Footer.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdAccountCircle, MdCampaign, MdHome } from 'react-icons/md';

const Footer = () => {
  const [activeTab, setActiveTab] = useState('home');
  const navigate = useNavigate();

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    switch (tabName) {
      case 'home':
        navigate('/home');
        break;
      case 'announcement':
        navigate('/seeduyuru');
        break;
      case 'profile':
        navigate('/profile');
        break;
      default:
        break;
    }
  };

  return (
    <div style={styles.homeBottomMenu}>
      <button
        style={{
          ...styles.homeIconItem,
          ...styles.homeIconItemAnnouncement,
          ...(activeTab === 'announcement' ? styles.homeIconItemActive : {}),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => handleTabClick('announcement')}
      >
        <MdCampaign name="bullhorn" size={40} color="black" />
      </button>

      <button
        style={{
          ...styles.homeIconCenter,
          ...(activeTab === 'home' ? styles.homeIconItemActive : {}),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => handleTabClick('home')}
      >
        <MdHome name="home" size={50} color="black" />
      </button>

      <button
        style={{
          ...styles.homeIconItem,
          ...styles.homeIconItemProfile,
          ...(activeTab === 'profile' ? styles.homeIconItemActive : {}),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => handleTabClick('profile')}
      >
        <MdAccountCircle name="account" size={40} color="black" />
      </button>
    </div>
  );
};

const styles = {
  homeBottomMenu: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: 'gray',
    color: 'white',
    padding: '10px 0',
    height: '80px',
    borderRadius: '60px 60px 0 0',
    boxShadow: '0 -6px 20px rgba(0,0,0,0.5)',
    background: 'linear-gradient(to right, #8888, #333)',
  },
  homeIconItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 18,
    cursor: 'pointer',
    margin: '0 10px',
    borderRadius: '50%',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    padding: '10px',
    textAlign: 'center',
    alignItems: 'center',
    width: '70px',
    height: '70px',
    transition: 'background-color 0.3s ease',
  },
  homeIconItemAnnouncement: {
    backgroundColor: 'white',
  },
  homeIconItemProfile: {
    backgroundColor: 'white',
  },
  homeIconItemActive: {
    backgroundColor: 'white',
    color: 'black',
  },
  homeIconCenter: {
    position: 'absolute',
    top: -20,
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: 'white',
    borderRadius: '50%',
    padding: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    cursor: 'pointer',
    width: '80px',
    height: '80px',
  },
};

export default Footer;
