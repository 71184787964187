import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import provincesUniversities from '../province-universities.json';
import cityData from '../il_ilceler.json';
import gorevler from '../gorevlerr.json';

import { MdLocationOn, MdLocationCity, MdEvent, MdWork } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const KisiselGelisimScreen = ({ navigation }) => {
    const navigate = useNavigate();
    const userId = localStorage.getItem("userId");
    const firebaseConfig = {
        apiKey: "AIzaSyDdugV4yuPfYBfVdTs6xEEFhrB6H9XgvRA",
        authDomain: "dernek-9b26c.firebaseapp.com",
        databaseURL: "https://dernek-9b26c-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "dernek-9b26c",
        storageBucket: "dernek-9b26c.appspot.com",
        messagingSenderId: "58054737120",
        appId: "1:58054737120:web:c4535a79d85d6a63102485",
        measurementId: "G-N3BL5L2NZN"
    };

    const app = initializeApp(firebaseConfig);
    const database = getDatabase(app);
    const [kullaniciBilgileri, setKullaniciBilgileri] = useState([]);
    const [baslangicDate, setBaslangicDate] = useState(new Date());
    const [bitisDate, setBitisDate] = useState(new Date());
    const [selectedCommission, setSelectedCommission] = useState('');

    const [selectedCity, setSelectedCity] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [cityItems, setCityItems] = useState([]);
    const [districtItems, setDistrictItems] = useState([]);
    const [selectedCityName, setSelectedCityName] = useState('');
    const [selectedDistrictName, setSelectedDistrictName] = useState('');
    useEffect(() => {
        const checkUserId = async () => {
          const userId = await localStorage.getItem('userId');
          if (!userId) {
            navigate('/login', { replace: true });
          }
        };
      
        checkUserId();
      }, [navigate]);
      
    const commissionOptions = [
        { label: 'Adilane', value: 'Adilane' },
        { label: 'Dış İlişkiler', value: 'Dış İlişkiler' },
        { label: 'Genel Sekreterlik', value: 'Genel Sekreterlik' },
        { label: 'Gönül Dostları', value: 'Gönül Dostları' },
        { label: 'Hukuk Ve Muhasebat', value: 'Hukuk Ve Muhasebat' },
        { label: 'Alınteri ve Emek', value: 'Alınteri ve Emek' },
        { label: 'İdari Ve Mali İşler', value: 'İdari Ve Mali İşler' },
        { label: 'İmar Akademi', value: 'İmar Akademi' },
        { label: 'İnsan Kaynakları', value: 'İnsan Kaynakları' },
        { label: 'Kardeşlik Ve Birlik', value: 'Kardeşlik Ve Birlik' },
        { label: 'Kültür Ve Sanat', value: 'Kültür Ve Sanat' },
        { label: 'Maarif ve Medeniyet', value: 'Maarif ve Medeniyet' },
        { label: 'Mekteb-i Meva', value: 'Mekteb-i Meva' },
        { label: 'Mekteb-i Şifa', value: 'Mekteb-i Şifa' },
        { label: 'Tarım, Orman Ve Doğa Bilimleri', value: 'Tarım, Orman Ve Doğa Bilimleri' },
        { label: 'Teknikhane', value: 'Teknikhane' },
        { label: 'Yayınlar', value: 'Yayınlar' },
        { label: 'Tanıtım ve Medya', value: 'Tanıtım ve Medya' },
        { label: 'Spor Komisyonu', value: 'Spor Komisyonu' },
        { label: 'Kişisel Gelişim', value: 'Kişisel Gelişim' },
        { label: 'Konya İmar', value: 'Konya İmar' },
        { label: 'İmar Topluluğu Üyesi Değilim', value: 'İmar Topluluğu Üyesi Değilim' }
    ];

    useEffect(() => {
        const gorevlerRef = ref(database, '/gorevler');
        const userRef = ref(database, `/kullanicilar/${userId}`);

        onValue(userRef, (userSnapshot) => {
            const userIKonay = userSnapshot.val().IKonay;
            const userOtherJob = userSnapshot.val().otherJob;

            onValue(gorevlerRef, (snapshot) => {
                let tempKullaniciBilgileri = [];

                snapshot.forEach((kullaniciSnapshot) => {
                    let commissionSet = new Set();
                    let academicGoalSet = new Set();
                    let artAreaSet = new Set();
                    let dateEntries = [];
                    let hasDataInRange = false;
                    let latestFullName = '';
                    let selectedCityName = '';
                    let selectedDistrictName = '';

                    kullaniciSnapshot.forEach((tarihSnapshot) => {
                        const tarihKey = tarihSnapshot.key;
                        const data = tarihSnapshot.val();
                        const date = new Date(tarihKey.split('-').reverse().join('-'));

                        const baslangicDateTime = new Date(baslangicDate);
                        baslangicDateTime.setHours(0, 0, 0, 0);
                        const bitisDateTime = new Date(bitisDate);
                        bitisDateTime.setHours(23, 59, 59, 999);

                        if (date >= baslangicDateTime && date <= bitisDateTime) {
                            hasDataInRange = true;
                            dateEntries.push({ key: tarihKey, val: data });

                            latestFullName = data.fullName || latestFullName;
                            selectedCityName = data.selectedCityName || selectedCityName;
                            selectedDistrictName = data.selectedDistrictName || selectedDistrictName;
                            commissionSet.add(data.otherJob);
                            academicGoalSet.add(data.academicGoal);
                            artAreaSet.add(data.artArea);
                        }
                    });

                    if (hasDataInRange && (userIKonay === true || (userOtherJob && commissionSet.has(userOtherJob)))) {
                        let kullaniciData = {
                            id: kullaniciSnapshot.key,
                            fullName: latestFullName,
                            commission: [...commissionSet].join(', '),
                            academicGoal: [...academicGoalSet].join(', '),
                            academicGoalDate: '',
                            artArea: [...artAreaSet].join(', '),
                            selectedCityName,
                            selectedDistrictName,
                            spiritualDevelopmentScore: 0,
                            academicWorkDuration: 0,
                            familyUnity: 0,
                            artDuration: 0,
                            sportCount: 0,
                            organizationalManagementCount: 0,
                            meetingParticipationCount: 0,
                            financialContribution: 0,
                        };

                        dateEntries.forEach(entry => {
                            const entryData = entry.val;
                            kullaniciData.spiritualDevelopmentScore += Number(entryData.spiritualPoints || 0);
                            kullaniciData.academicWorkDuration += Number(entryData.academicWorkDuration || 0);
                            kullaniciData.familyUnity += (entryData.familyTime === 'Yapıldı' ? 1 : 0);
                            kullaniciData.artDuration += Number(entryData.artDuration || 0);
                            kullaniciData.sportCount += (entryData.sportTime === 'Yapıldı' ? 1 : 0);
                            kullaniciData.organizationalManagementCount += Number(entryData.committeeMemberCount || 0);
                            kullaniciData.meetingParticipationCount += Number(entryData.meetingAttendance || 0);
                            kullaniciData.financialContribution += Number(entryData.financialContribution || 0);
                        });

                        tempKullaniciBilgileri.push(kullaniciData);
                    }
                });

                setKullaniciBilgileri(tempKullaniciBilgileri.filter(kullanici => kullanici.fullName));
            }, { onlyOnce: true });
        });
    }, [baslangicDate, bitisDate]);

    const filteredKullaniciBilgileri = kullaniciBilgileri.filter(kullanici => {
        let matchesCommission = !selectedCommission || kullanici.commission.split(', ').some(comm => comm.includes(selectedCommission));
        let matchesCity = !selectedCityName || kullanici.selectedCityName === selectedCityName;
        let matchesDistrict = !selectedDistrictName || kullanici.selectedDistrictName === selectedDistrictName;

        return matchesCommission && matchesCity && matchesDistrict;
    });

    const handleDateChange = (date, type) => {
        if (type === 'baslangic') {
            setBaslangicDate(date);
        } else {
            setBitisDate(date);
        }
    };

    const loadCities = () => {
        const cities = cityData.cityset.CITY.map(city => ({
            label: city['_cityname'],
            value: city['_cityid']
        }));
        setCityItems(cities);
    };

    const loadDistricts = (cityId) => {
        const city = cityData.cityset.CITY.find(city => city['_cityid'] === cityId);
        if (city && city.DISTRICT) {
            const districts = city.DISTRICT.map(district => ({
                label: district['DISTNAME'],
                value: district['DISTID']
            }));
            setDistrictItems(districts);
        } else {
            setDistrictItems([]);
        }
    };

    useEffect(() => {
        loadCities();
    }, []);

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredKullaniciBilgileri);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Kullanıcı Bilgileri');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(data, 'kullanici_bilgileri.xlsx');
    };

    const exportToPDF = () => {
        const pdfContent = filteredKullaniciBilgileri.map(kullanici => [
            kullanici.fullName,
            kullanici.commission,
            kullanici.academicGoal,
            kullanici.academicGoalDate,
            kullanici.artArea,
            kullanici.selectedCityName,
            kullanici.selectedDistrictName,
            kullanici.spiritualDevelopmentScore,
            kullanici.academicWorkDuration,
            kullanici.familyUnity,
            kullanici.artDuration,
            kullanici.sportCount,
            kullanici.organizationalManagementCount,
            kullanici.meetingParticipationCount,
            kullanici.financialContribution
        ]);
    
        const docDefinition = {
            pageOrientation: 'landscape',  // Sayfayı yatay olarak ayarlama
            content: [
                {
                    table: {
                        headerRows: 1,
                        widths: ['9%', '9%', '9%', '9%', '9%', '5%', '5%', '6%', '6%', '5%', '5%', '5%', '5%', '5%', '5%'],
                        body: [
                            [
                                { text: 'Tam Adı', bold: true, fontSize: 7 },
                                { text: 'Komisyon', bold: true, fontSize: 7 },
                                { text: 'Akademik Hedef', bold: true, fontSize: 7 },
                                { text: 'Akademik Hedef Tarihi', bold: true, fontSize: 7 },
                                { text: 'Sanat Alanı', bold: true, fontSize: 7 },
                                { text: 'İl', bold: true, fontSize: 7 },
                                { text: 'İlçe', bold: true, fontSize: 7 },
                                { text: 'Ruhsal Gelişim Puanı', bold: true, fontSize: 7 },
                                { text: 'Akademik Çalışma Süresi', bold: true, fontSize: 7 },
                                { text: 'Aile Birliği', bold: true, fontSize: 7 },
                                { text: 'Sanat Süresi', bold: true, fontSize: 7 },
                                { text: 'Spor Sayısı', bold: true, fontSize: 7 },
                                { text: 'Organizasyon Yönetim Sayısı', bold: true, fontSize: 7 },
                                { text: 'Toplantı Katılım Sayısı', bold: true, fontSize: 7 },
                                { text: 'Mali Katkı', bold: true, fontSize: 7 }
                            ],
                            ...pdfContent.map(row => row.map(cell => ({ text: cell, fontSize: 7 })))
                        ]
                    }
                }
            ]
        };
    
        pdfMake.createPdf(docDefinition).download('kullanici_bilgileri.pdf');
    };
    
    
    

    return (
        <div>
        
            <h1 style={styles.itemTexttitle}>Kişisel Gelişim Ekranı</h1>
            <div style={styles.registerInputRow}>
                <label style={styles.itemTextHeader}>Başlangıç Tarihi:</label>
                <DatePicker selected={baslangicDate} onChange={(date) => handleDateChange(date, 'baslangic')}  style={styles.datePicker}  dateFormat="dd-MM-yyyy" />
            </div>
            <div style={styles.registerInputRow}>
                <label style={styles.itemTextHeader}>Bitiş Tarihi:</label>
                <DatePicker selected={bitisDate} onChange={(date) => handleDateChange(date, 'bitis')}  dateFormat="dd-MM-yyyy"/>
            </div>
            <div style={styles.registerInputRow}>
            <MdEvent name="city-variant-outline" size={25} color="#737373" />
                <select
                    value={selectedCommission}
                    onChange={e => setSelectedCommission(e.target.value)}
                >
                    <option value="">Komisyon Seçiniz</option>
                    {commissionOptions.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
            </div>

            {/* İl */}
            <div style={styles.registerInputRow}>
                <MdLocationOn name="city-variant-outline" size={25} color="#737373" />
                <select
                    onChange={(e) => {
                        const value = e.target.value;
                        setSelectedCity(value);
                        loadDistricts(value);
                        const selectedCityItem = cityItems.find(item => item.value === value);
                        setSelectedCityName(selectedCityItem ? selectedCityItem.label : '');
                        setSelectedDistrict('');
                    }}
                    value={selectedCity}
                >
                    <option value="">İl Seçiniz</option>
                    {cityItems.map(item => (
                        <option key={item.value} value={item.value}>{item.label}</option>
                    ))}
                </select>
                <p style={styles.requiredStar}>*</p>
            </div>

            {/* İlçe */}
            <div style={styles.registerInputRow}>
                <MdLocationCity name="city" size={25} color="#737373" />
                <select
                    onChange={(e) => {
                        const value = e.target.value;
                        setSelectedDistrict(value);
                        const selectedDistrictItem = districtItems.find(item => item.value === value);
                        setSelectedDistrictName(selectedDistrictItem ? selectedDistrictItem.label : '');
                    }}
                    value={selectedDistrict}
                    disabled={!selectedCity || !districtItems.length}
                >
                    <option value="" disabled>İlçe Seçiniz</option>
                    {districtItems.map((item) => (
                        <option key={item.value} value={item.value}>{item.label}</option>
                    ))}
                </select>
                <p style={styles.requiredStar}>*</p>
            </div>
            <div style={styles.insankaynaklaributtonview}>
            <button style={styles.downloadButton2} onClick={exportToExcel}>Excel'e Aktar</button>
            <button style={styles.downloadButton} onClick={exportToPDF}>PDF'ye Aktar</button>
            </div>
            <div>
                {filteredKullaniciBilgileri.map(kullanici => (
                    <div key={kullanici.id} style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
                        <h3>{kullanici.fullName}</h3>
                        <p><MdWork /> {kullanici.commission}</p>
                        <p><MdLocationCity /> {kullanici.selectedCityName}</p>
                        <p><MdLocationOn /> {kullanici.selectedDistrictName}</p>
                        <p><MdEvent /> Akademik Hedef: {kullanici.academicGoal}</p>
                        <p>Ruhsal Gelişim Puanı: {kullanici.spiritualDevelopmentScore}</p>
                        <p>Akademik Çalışma Süresi: {kullanici.academicWorkDuration}</p>
                        <p>Aile Birliği: {kullanici.familyUnity}</p>
                        <p>Sanat Süresi: {kullanici.artDuration}</p>
                        <p>Spor Sayısı: {kullanici.sportCount}</p>
                        <p>Organizasyon Yönetim Sayısı: {kullanici.organizationalManagementCount}</p>
                        <p>Toplantı Katılım Sayısı: {kullanici.meetingParticipationCount}</p>
                        <p>Mali Katkı: {kullanici.financialContribution}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

const styles = {
    kisiselgelisimContainer: {
        flex: 1,
        padding: 20,
        backgroundColor: '#fff',
    },
    itemTexttitle:{
        fontWeight: 'bold',
        marginBottom: 5,
        marginRight: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    divider: {
        height: 1,
        backgroundColor: '#ccc',
        marginVertical: 10,
    },
    itemTextHeader: {
        fontWeight: 'bold',
        marginBottom: 5,
        marginRight: 10
      },
    reportTitle6: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'center',
    },
    inputGroup: {
        marginBottom: 20,
    },
    label: {
        display: 'block',
        marginBottom: 5,
        fontWeight: 'bold',
    },
    buttonGroup: {
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'space-between',
    },
    insankaynaklaributtonview: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    downloadButton: {
        backgroundColor: 'red',
        color: '#fff',
        padding: 10,
        borderRadius: 5,
        border: 'none',
        cursor: 'pointer',
        marginRight: 10,
        width: '50%',
      },
      downloadButtonText: {
        margin: 0,
      }, datePicker: {
        width: '100%', // veya istediğiniz genişlik değeri
        // diğer stiller...
    },
      downloadButton2: {
        backgroundColor: '#28a745',
        color: '#fff',
        padding: 10,
        borderRadius: 5,
        border: 'none',
        cursor: 'pointer',
        marginRight: 10,
        width: '50%',
      },
      downloadButtonText2: {
        margin: 0,
      },
    listItem: {
        backgroundColor: '#f9f9f9',
        padding: 15,
        marginVertical: 8,
        borderRadius: 5,
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    itemTextHeader2: {
        fontWeight: 'bold',
        fontSize: 18,
    },
    registerInputRow: {
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    requiredStar: {
        color: 'red',
        marginLeft: '5px'
    },
};

export default KisiselGelisimScreen;
