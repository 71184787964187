import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';  // Firebase veritabanı işlemleri için
import { BrowserRouter as Router, Route } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';

const SeeDuyuruScreen = () => {
  const navigate = useNavigate();
    const [seeduyuruDuyurular, setSeeduyuruDuyurular] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    useEffect(() => {
      const checkUserId = async () => {
        const userId = await localStorage.getItem('userId');
        if (!userId) {
          navigate('/login', { replace: true });
        }
      };
    
      checkUserId();
    }, [navigate]);
    
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const styles = isMobile ? mobileStyles : desktopStyles;
  
    useEffect(() => {
        const db = getDatabase();
        const duyuruRef = ref(db, 'duyurular');
    
        onValue(duyuruRef, (snapshot) => {
          const data = snapshot.val();
          const duyuruList = [];
          for (let id in data) {
            duyuruList.push({ id, ...data[id] });
          }
          // En son 30 duyuruyu almak için diziyi ters çevir ve slice() ile kırp
          setSeeduyuruDuyurular(duyuruList.reverse().slice(0, 50));
        });
      return () => {
        // Dinleyiciyi kaldır
      };
    }, []);
  
    const renderSeeduyuruItem = ({ item }) => (
      <div style={styles.seeduyuruDuyuruItem}>
        <p style={styles.seeduyuruDuyuruTitle}>{item.title}</p>
        <p style={styles.seeduyuruDuyuruDescription}>{item.description}</p>
        <p style={styles.seeduyuruDuyuruDate}>{item.createdAt}</p>
      </div>
    );
  
     return (
        <div style={styles.seeduyuruContainer}>
          <div style={styles.confirmationHeaderContainer}>
            <p style={styles.taskManagementTitle}>DUYURULAR</p>
            <div style={styles.divider}/>
          </div>
          {seeduyuruDuyurular.map((item) => (
            <div key={item.id} style={styles.seeduyuruDuyuruItem}>
              <p style={styles.seeduyuruDuyuruTitle}>{item.title}</p>
              <p style={styles.seeduyuruDuyuruDescription}>{item.description}</p>
              <p style={styles.seeduyuruDuyuruDate}>{item.createdAt}</p>
            </div>
          ))}
        </div>
      );
    
  };
  
  const desktopStyles = {
    seeduyuruContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      backgroundColor: '#f0f0f0', // Arka plan rengi
    },
    confirmationHeaderContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '20px',
    },
    taskManagementTitle: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#333',
      textAlign: 'center',
      marginBottom: '10px',
    },
    divider: {
      width: '100%',
      height: '2px',
      backgroundColor: '#ccc',
      marginBottom: '20px',
    },
    seeduyuruDuyuruItem: {
      backgroundColor: 'white',
      padding: '15px',
      margin: '10px 0',
      borderRadius: '10px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Gölge efekti
      width: '100%',
      maxWidth: '800px', // Maksimum genişlik
    },
    seeduyuruDuyuruTitle: {
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#000',
      marginBottom: '5px',
    },
    seeduyuruDuyuruDescription: {
      fontSize: '16px',
      color: '#666',
      marginBottom: '5px',
    },
    seeduyuruDuyuruDate: {
      fontSize: '14px',
      color: '#999',
      textAlign: 'right',
    }
  };

  const mobileStyles = {
    seeduyuruContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px', // Daha küçük padding
      backgroundColor: '#f0f0f0', // Arka plan rengi
    },
    confirmationHeaderContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '10px', // Daha küçük margin
    },
    taskManagementTitle: {
      fontSize: '18px', // Daha küçük yazı boyutu
      fontWeight: 'bold',
      color: '#333',
      textAlign: 'center',
      marginBottom: '5px', // Daha küçük margin
    },
    divider: {
      width: '100%',
      height: '1px', // Daha ince divider
      backgroundColor: '#ccc',
      marginBottom: '10px', // Daha küçük margin
    },
    seeduyuruDuyuruItem: {
      backgroundColor: 'white',
      padding: '10px', // Daha küçük padding
      margin: '5px 0', // Daha küçük margin
      borderRadius: '10px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Gölge efekti
      width: '100%',
      maxWidth: '100%', // Tam genişlik
    },
    seeduyuruDuyuruTitle: {
      fontSize: '16px', // Daha küçük yazı boyutu
      fontWeight: 'bold',
      color: '#000',
      marginBottom: '3px', // Daha küçük margin
    },
    seeduyuruDuyuruDescription: {
      fontSize: '12px', // Daha küçük yazı boyutu
      color: '#666',
      marginBottom: '3px', // Daha küçük margin
    },
    seeduyuruDuyuruDate: {
      fontSize: '10px', // Daha küçük yazı boyutu
      color: '#999',
      textAlign: 'right',
    }
  };
  export default SeeDuyuruScreen;