import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDatabase, ref, get, set, onValue } from 'firebase/database';
import { MdVisibility, MdLocationOn, MdLocationCity, MdHome, MdWork, MdGroup, MdHelp, MdSend } from 'react-icons/md';

import cityData from '../il_ilceler.json';
import gorevler from '../gorevlerr.json';



const TeskilatDegisiklikScreen = () => {
    const location = useLocation();
    const userId2 = location.state.userId2;
    const navigate = useNavigate();
    const [selectedKademe, setSelectedKademe] = useState(null);
    const [userData, setUserData] = useState(null);
    const [reason, setReason] = useState('');
    const [merkezTasra, setMerkezTasra] = useState(null);
    const [otherJob, setOtherJob] = useState(null);
    const [selectedGorev, setSelectedGorev] = useState(null);
    const [currentUserGorev, setCurrentUserGorev] = useState('');
    const [komisyonAdi, setKomisyonAdi] = useState('');
    const [namesurname, Setnamesurname] = useState('');
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [cityItems, setCityItems] = useState([]);
    const [districtItems, setDistrictItems] = useState([]);
    const [selectedCityName, setSelectedCityName] = useState('');
    const [selectedDistrictName, setSelectedDistrictName] = useState('');
    const [selectedKomisyon, setSelectedKomisyon] = useState('');
    const [komisyonGorevleri, setKomisyonGorevleri] = useState([]);
useEffect(() => {
  const checkUserId = async () => {
    const userId = await localStorage.getItem('userId');
    if (!userId) {
      navigate('/login', { replace: true });
    }
  };

  checkUserId();
}, [navigate]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const db = getDatabase();
                const userRef = ref(db, `kullanicilar/${userId2}`);
                const snapshot = await get(userRef);
                if (snapshot.exists()) {
                    setUserData(snapshot.val());
                } else {
                    console.error("Kullanıcı verileri bulunamadı");
                }
            } catch (error) {
                console.error("Kullanıcı verileri çekilirken hata oluştu: ", error);
            }
        };

        if (userId2) {
            fetchUserData();
        }
    }, [userId2]);

    const loadCities = () => {
        const cities = cityData.cityset.CITY.map(city => ({
            label: city['_cityname'],
            value: city['_cityid']
        }));
        setCityItems(cities);
    };

    useEffect(() => {
        loadCities();
    }, []);

    const onCityChange = (value) => {
        setSelectedCity(value);
        loadDistricts(value);
        const selectedCityItem = cityItems.find(item => item.value === value);
        setSelectedCityName(selectedCityItem ? selectedCityItem.label : '');
        setSelectedDistrict(null);
    };

    const loadDistricts = (cityId) => {
        const city = cityData.cityset.CITY.find(city => city['_cityid'] === cityId);
        if (city && city.DISTRICT) {
            const districts = city.DISTRICT.map(district => ({
                label: district['DISTNAME'],
                value: district['DISTID']
            }));
            setDistrictItems(districts);
        } else {
            setDistrictItems([]);
        }
    };

    const getCurrentUserGorev = async () => {
        try {
            const db = getDatabase();
            const userRef = ref(db, 'kullanicilar/' + userId2);
            onValue(userRef, (snapshot) => {
                const data = snapshot.val();
                if (data && data.selectedGorev) {
                    setCurrentUserGorev(data.selectedGorev);
                    setKomisyonAdi(data.otherJob);
                    Setnamesurname(data.fullName);
                }
            });
        } catch (error) {
            console.error("Firebase'den veri alınırken hata oluştu: ", error);
        }
    };

    useEffect(() => {
        getCurrentUserGorev();
    }, []);

    const getGorevlerForKomisyon = (komisyonAdi) => {
        const komisyon = gorevler.find(item => item.komisyon === komisyonAdi);
        return komisyon ? komisyon.birimler.map(birim => birim.birim_baslik) : [];
    };

    const handleKomisyonSelect = (komisyonAdi) => {
        setSelectedKomisyon(komisyonAdi);
        const gorevler = getGorevlerForKomisyon(komisyonAdi);
        setKomisyonGorevleri(gorevler);
    };

    const saveGorevDegisikligi = async () => {
        try {
            if (!userData) {
                console.error("Kullanıcı verileri yüklenmedi");
                return;
            }

            if (!userId2) {
                console.error("Kullanıcı ID'si bulunamadı");
                return;
            }

            const db = getDatabase();
            const gorevDegisikligiRef = ref(db, 'gorevdegisikligi/' + userId2);

            const gorevDegisikligiData = {
                MerkezTasra: merkezTasra || "Bilgi yok",
                IstekKademe: selectedKademe || "Bilgi yok",
                mevcutKomisyonAdi: komisyonAdi || "Bilgi yok",
                mevcutGorev: currentUserGorev || "Bilgi yok",
                yeniKomisyon: otherJob || "Bilgi yok",
                yeniGorev: selectedGorev || "Bilgi yok",
                sehir: selectedCityName || "Bilgi yok",
                ilce: selectedDistrictName || "Bilgi yok",
                neden: reason || "Bilgi yok",
                mevcutGorevDurumu: "Mevcut",
                onay: false,
                Durum: "belirlenmedi",
                Bolum: userData.department || "Bilgi yok",
                Eposta: userData.email || "Bilgi yok",
                EkGorev: userData.extraGorev || "Bilgi yok",
                Fakulte: userData.faculty || "Bilgi yok",
                DogumTarihi: userData.ageDate || "Bilgi yok",
                AdSoyad: userData.fullName || "Bilgi yok",
                Üniversite: userData.selectedUniversity || "Bilgi yok",
                MezuniyetYili: userData.graduationYear || "Bilgi yok",
                Lise: userData.highSchool || "Bilgi yok",
                YabanciDilBilgisi: userData.langueDetails || "Bilgi yok",
                MedeniDurum: userData.maritalStatus || "Bilgi yok",
                TelefonNumarasi: userData.phoneNumber || "Bilgi yok"
            };

            await set(gorevDegisikligiRef, gorevDegisikligiData);
            window.alert("Başarılı: Görev başarıyla kaydedildi.");
        } catch (error) {
            console.error("Görev değişikliği kaydedilirken hata oluştu: ", error);
            window.alert("Hata: Görev kaydedilirken bir hata oluştu: " + error.message);
        }
    };

    const KademeRadioButton = ({ value, selectedValue, onClick }) => (
        <label style={styles.radioButton}>
            <input
                type="radio"
                name="kademe"
                value={value}
                checked={selectedValue === value}
                onChange={onClick}
                style={styles.radioInput}
            />
            <div style={styles.radioOuterCircle}>
                <div style={selectedValue === value ? styles.radioInnerCircleSelected : styles.radioInnerCircle} />
            </div>
            <p style={styles.radioText}>{value}</p>
        </label>
    );
    
    const RadioButton3 = ({ value, selectedValue, onClick }) => (
        <label style={styles.radioButton}>
            <input
                type="radio"
                name="komisyon"
                value={value}
                checked={selectedValue === value}
                onChange={onClick}
                style={styles.radioInput}
            />
            <div style={styles.radioOuterCircle}>
                <div style={selectedValue === value ? styles.radioInnerCircleSelected : styles.radioInnerCircle} />
            </div>
            <p style={styles.radioText}>{value}</p>
        </label>
    );

    const GorevRadioButton = ({ value, selectedValue, onClick }) => (
        <label style={styles.radioButton}>
            <input
                type="radio"
                name="gorev"
                value={value}
                checked={selectedValue === value}
                onChange={onClick}
                style={styles.radioInput}
            />
            <div style={styles.radioOuterCircle}>
                <div style={selectedValue === value ? styles.radioInnerCircleSelected : styles.radioInnerCircle} />
            </div>
            <p style={styles.radioText}>{value}</p>
        </label>
    );

    const GorevRadioButtons = () => (
        <div style={styles.radioGroup3}>
            {komisyonGorevleri.map((gorev, index) => (
                <GorevRadioButton 
                    key={index}
                    value={gorev} 
                    selectedValue={selectedGorev} 
                    onClick={() => setSelectedGorev(gorev)} 
                />
            ))}
        </div>
    );

    const RadioButton4 = ({ value, selectedValue, onClick }) => (
        <label style={styles.radioButton}>
            <input
                type="radio"
                name="merkezTasra"
                value={value}
                checked={selectedValue === value}
                onChange={onClick}
                style={styles.radioInput}
            />
            <div style={styles.radioOuterCircle}>
                <div style={selectedValue === value ? styles.radioInnerCircleSelected : styles.radioInnerCircle} />
            </div>
            <p style={styles.radioText}>{value}</p>
        </label>
    );

    const MerkezTasraRadioButtons = () => (
        <div style={styles.registerInputRow}>
            <div style={styles.radioGroup2}>
                <RadioButton4 
                    value="Genel Merkez" 
                    selectedValue={merkezTasra}
                    onClick={() => setMerkezTasra('Genel Merkez')} 
                />
                <RadioButton4 
                    value="Taşra" 
                    selectedValue={merkezTasra}
                    onClick={() => setMerkezTasra('Taşra')} 
                />
            </div>
        </div>
    );

    return (
        <div style={styles.scrollViewStyle}>
            <div style={styles.missionchangeContainer}>
                
                <div style={styles.missionchangeheader}>
                <div style={styles.missionUserContainer}>
    <img src={require('../assets/missionuser.png')} style={styles.missionUserImage} />
</div>

                    <p style={styles.headerText}>{namesurname || 'İsim bilgisi yükleniyor...'}</p>
                    <p style={styles.headerText}>Mevcut Görev</p>
                    <p style={styles.gorevText}>{komisyonAdi || 'Görev bilgisi yükleniyor...'}</p>
                    <p style={styles.gorevText}>{currentUserGorev || 'Görev bilgisi yükleniyor...'}</p>
                </div>
                <div style={styles.registerInputRow}>
                    <MdLocationOn size={25} color="#737373" />
                    <select
                        onChange={(e) => onCityChange(e.target.value)}
                        value={selectedCity || ''}
                    >
                        {cityItems.map(item => (
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))}
                    </select>
                    <p style={styles.requiredStar}>*</p>
                </div>
                <div style={styles.registerInputRow}>
                    <MdLocationCity size={25} color="#737373" />
                    <select
                        onChange={(e) => {
                            const value = e.target.value;
                            setSelectedDistrict(value);
                            const selectedDistrictItem = districtItems.find(item => item.value === value);
                            setSelectedDistrictName(selectedDistrictItem ? selectedDistrictItem.label : '');
                        }}
                        value={selectedDistrict || ''}
                        disabled={!selectedCity || !districtItems.length}
                    >
                        <option value="" disabled>İlçe Seçiniz</option>
                        {districtItems.map((item) => (
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))}
                    </select>
                    <p style={styles.requiredStar}>*</p>
                </div>
                <div style={styles.kademeHeaderContainer}>
                    <MdHome size={25} color="#737373" />
                    <p style={styles.labelcalisma}>Merkez Taşra Ayrımı</p>
                    <p style={styles.requiredStar}>*</p>
                </div>
                <MerkezTasraRadioButtons />
                <div style={styles.kademeHeaderContainer}>
                    <MdWork size={25} color="#737373" />
                    <p style={styles.labelcalisma}>Görev Yaptığınız Kademe</p>
                    <p style={styles.requiredStar}>*</p>
                </div>
                <div style={styles.registerInputRow}>
                    <div style={styles.radioGroup2}>
                        <KademeRadioButton 
                            value="Ana Kademe" 
                            selectedValue={selectedKademe} 
                            onClick={() => setSelectedKademe('Ana Kademe')} 
                        />
                        <KademeRadioButton 
                            value="Üniversite" 
                            selectedValue={selectedKademe} 
                            onClick={() => setSelectedKademe('Üniversite')} 
                        />
                        <KademeRadioButton 
                            value="Hanımlar" 
                            selectedValue={selectedKademe} 
                            onClick={() => setSelectedKademe('Hanımlar')} 
                        />
                    </div>
                </div>
                <div style={styles.calismatextContainer}>
                <MdGroup size={25} color="#737373" />
                    <p style={styles.labelcalisma}>İmar Topluluğu Bünyesinde Görev Yapmak İstediğiniz Komisyon.</p>
                    <p style={styles.requiredStar}>*</p>
                </div>
                <div style={styles.registerInputRow}>
                    <div style={styles.radioGroup3}>
                        {['Adilane', 'Dış İlişkiler', 'Genel Sekreterlik', 'Gönül Dostları', 'Hukuk Ve Muhasebat', 'Alınteri ve Emek', 'İdari Ve Mali İşler', 'İmar Akademi', 'İnsan Kaynakları', 'Kardeşlik Ve Birlik', 'Kültür Ve Sanat', 'Maarif ve Medeniyet', 'Mekteb-i Meva',
                          'Mekteb-i Şifa', 'Tarım, Orman Ve Doğa Bilimleri', 'Teknikhane', 'Yayınlar', 'Tanıtım ve Medya', 'Spor Komisyonu', 'Kişisel Gelişim', 'Konya İMAR'].map((item) => (
                            <RadioButton3 
                                key={item} 
                                value={item} 
                                selectedValue={otherJob} 
                                onClick={() => {
                                    setOtherJob(item);
                                    handleKomisyonSelect(item);
                                }} 
                            />
                        ))}
                    </div>
                </div>
                <div style={styles.kademeHeaderContainer}>
                    <MdWork size={25} color="#737373" />
                    <p style={styles.labelcalisma}>Talep Edeceğiniz Görev</p>
                    <p style={styles.requiredStar}>*</p>
                </div>
                <div style={styles.registerInputRow}>
                    <div style={styles.radioGroup2}>
                        <GorevRadioButtons />
                    </div>
                </div>
                <div style={styles.inputContainer}>
                    <MdHelp size={25} color="#737373" />
                    <input 
                        style={styles.reasonInput} 
                        placeholder="Neden" 
                        value={reason} 
                        maxLength={150}
                        onChange={(e) => setReason(e.target.value)}
                    />
                </div>
                <div style={styles.missionUserContainer}>
                <button style={styles.sendButton} onClick={saveGorevDegisikligi}>
                    <p style={styles.sendButtonText}>Gönder</p>
                    <MdSend size={20} color="white" />
                </button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    radioButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 20,
        cursor: 'pointer',
        position: 'relative',
        marginBottom: 10, // Alt alta geçişi sağlamak için margin ekleyelim
    },
    radioInput: {
        position: 'absolute',
        opacity: 0,
        cursor: 'pointer',
    },
    radioOuterCircle: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        borderWidth: 2,
        borderColor: '#555555',
        borderStyle: 'solid',
        marginRight: 10,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    calismatextContainer:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
    },
    radioInnerCircle: {
        width: 12,
        height: 12,
        borderRadius: '50%',
        backgroundColor: 'transparent',
    },
    radioInnerCircleSelected: {
        width: 12,
        height: 12,
        borderRadius: '50%',
        backgroundColor: '#28a745', // Yeşil renk
    },
    radioText: {
        fontSize: 16,
        color: '#333333',
    },
    // Diğer stiller...
    scrollViewStyle: {
        flex: 1,
        backgroundColor: '#f0f0f0',
        padding: 20,
    },
    missionchangeContainer: {
        width: '100%',
        backgroundColor: '#ffffff',
        borderRadius: 10,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        padding: 20,
    },
    missionUserContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20,
    },
    missionUserImage: {
        width: 100,
        height: 100,
        borderRadius: '50%',
        objectFit: 'cover',
    },
    missionchangeheader: {
        alignItems: 'center',
        marginBottom: 20,
        justifyContent: 'center',
    },
    headerText: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 5,
        color: '#333333',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    gorevText: {
        fontSize: 16,
        color: '#555555',
        marginBottom: 5,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    registerInputRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
    },
    radioButtonRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
    },
    radioButton3: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 20,
        cursor: 'pointer',
    },
    radioGroup2: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    labelcalisma: {
        fontSize: 16,
        marginLeft: 10,
        color: '#333333',
    },
    requiredStar: {
        fontSize: 16,
        color: 'red',
    },
    kademeHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
    },
    radioGroup3: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '100%',
        maxWidth: '100%',
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderColor: '#000000',
        marginBottom: 20,
    },
    reasonInput: {
        fontSize: 16,
        marginLeft: 10,
        width: '90%',
        paddingVertical: 5,
        border: '1px solid #ccc',
        borderRadius: 5,
        paddingLeft: 10,
    },
    sendButton: {
        backgroundColor: '#333333',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        marginTop: 20,
        cursor: 'pointer',
        border: 'none',
        width: '30%'
    },
    sendButtonText: {
        color: '#ffffff',
        fontSize: 16,
        marginRight: 10,
    },
};



export default TeskilatDegisiklikScreen;
