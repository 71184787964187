import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';  // Firebase veritabanı işlemleri için
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

const AllUsersScreen = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const checkUserId = async () => {
            const userId = await localStorage.getItem('userId');
            if (!userId) {
                navigate('/login', { replace: true });
            }
        };

        checkUserId();
    }, [navigate]);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const styles = isMobile ? mobileStyles : desktopStyles;

    const translateKey = (key) => {
        const translations = {
            ageDate: "Doğum Tarihi",
            selectedCityName: "Şehir",
            department: "Bölüm",
            additionalInfo: "Ek Bilgiler",
            selectedDistrictName: "İlçe",
            email: "E-posta",
            employmentStatus: "Çalışma Durumu",
            extraGorev: "Ek Görev",
            faculty: "Fakülte",
            fullName: "Ad Soyad",
            graduationYear: "Mezuniyet Yılı",
            highSchool: "Lise",
            langueDetails: "Yabancı Dil Bilgisi",
            maritalStatus: "Medeni Durum",
            merkezTasra: "Merkez/Taşra",
            onay: "Onay Durumu",
            status: "durum",
            otherDescription: "Diğer Açıklama",
            otherJob: "Diğer İş",
            phoneNumber: "Telefon Numarası",
            selectedGorev: "Seçilen Görev",
            selectedKademe: "Seçilen Kademe",
            selectedUniversity: "Üniversite",
            yukseklisans: "Yüksek Lisans/Doktora"
        };
        return translations[key] || key;
    };

    useEffect(() => {
        const db = getDatabase();
        const usersRef = ref(db, 'kullanicilar');

        onValue(usersRef, (snapshot) => {
            const data = snapshot.val();
            const unapprovedUsers = Object.keys(data).map(key => ({
                id: key,
                ...data[key]
            }));

            setUsers(unapprovedUsers);
            setFilteredUsers(unapprovedUsers); // Tüm kullanıcıları başlangıçta ayarla
        });
    }, []);

    const filterUsers = (query) => {
        setSearchQuery(query);
        if (query === '') {
            setFilteredUsers(users);
        } else {
            setFilteredUsers(users.filter(user => user.fullName.toLowerCase().includes(query.toLowerCase())));
        }
    };

    const showUserDetails = (user) => {
        setSelectedUser(user);
        setModalVisible(true);
    };

    const UserDetailsModal = ({ visible, user, onClose }) => {
        return (
            <Modal
                animationType="slide"
                transparent={true}
                isOpen={modalVisible}
                onRequestClose={onClose}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)', // Arka plan rengini ayarla
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '400px', // Modal genişliğini burada ayarlayın
                        maxHeight: '80%', // Modalın maksimum yüksekliğini sınırla
                        overflow: 'auto', // İçeriği taşarsa kaydırma çubuğu ekle
                        padding: '20px', // İç padding
                        border: '2px solid black', // İnce çerçeve ekle
                        borderRadius: '50px', // Köşelere yuvarlaklık ekle
                    }
                }}
            >
                <div style={styles.centeredView}>
                    <div style={styles.modalView}>
                        <div>
                            {Object.entries(user).map(([key, value]) => {
                                if (key !== 'status' && key !== 'id' && key !== 'onay' && key !== 'push_token' && key !== 'IKonay' && key !== 'IKyonetim' && key !== 'ik' && key !== 'onay' && key !== 'IKstatus') {
                                    return (
                                        <div key={key} style={styles.modalItem}>
                                            <p style={styles.modalItemTitle}>{translateKey(key)}:</p>
                                            <p style={styles.modalItemText}>{value.toString()}</p>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                        <div style={styles.modalButtonsContainer}>
                            <button
                                style={styles.modalButton2}
                                onClick={() => setModalVisible(!modalVisible)}
                            >
                                <p style={styles.modalButtonText}>Kapat</p>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    return (
        <div style={{ flex: 1 }}>
            <div style={styles.confirmationHeaderContainer}>
                <p style={styles.taskManagementTitle}>Kullanıcılar</p>
                <input
                    type="text"
                    placeholder="Kullanıcı adı ara..."
                    value={searchQuery}
                    onChange={(e) => filterUsers(e.target.value)}
                    style={styles.searchInput}
                />
                <div style={styles.divider} />
            </div>
            {filteredUsers.map(item => (
                <div key={item.id} style={styles.confirmationListItem}>
                    <p style={styles.confirmationListItemText}>{item.fullName}</p>
                    <button style={styles.confirmationButton} onClick={() => showUserDetails(item)}>
                        <p style={styles.confirmationButtonText}>İncele</p>
                    </button>
                </div>
            ))}
            <UserDetailsModal
                visible={modalVisible}
                user={selectedUser}
                onClose={() => setModalVisible(false)}
            />
        </div>
    );
};

const desktopStyles = {
    confirmationHeaderContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
       
        backgroundColor: '#f0f0f0',
        width: '100%',
    },
    taskManagementTitle: {
        fontSize: '45px',
        fontWeight: 'bold',
        color: '#333',
        marginBottom: '20px',
    },
    searchInput: {
        fontSize: '20px',
    
        marginBottom: '20px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: '50%',
    },
    divider: {
        width: '100%',
        borderTop: '1px solid #ccc',
        margin: '20px 0',
    },
    confirmationListItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        
        borderBottom: '1px solid #ccc',
    },
    confirmationListItemText: {
        flex: 1,
        fontSize: '20px',
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    confirmationButton: {
        marginLeft: '10px',
       
        backgroundColor: '#0056b3',
        color: 'white',
        borderRadius: '5px',
        fontSize: '16px',
        fontWeight: 'bold',
        cursor: 'pointer',
        boxShadow: '0 2px 5px rgba(0,0,0,0.9)',
        border: 'none', // Çizgiyi kaldırmak için eklenen özellik
        outline: 'none', // Çizgiyi kaldırmak için eklenen özellik
    },
    confirmationButtonText: {
        textAlign: 'center',
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    modalItem: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '10px',
    },
    modalItemTitle: {
        fontWeight: 'bold',
        marginRight: '5px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    modalItemText: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalButtonsContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '20px',
    },
    modalButton2: {
        padding: '10px 20px',
        backgroundColor: '#4CAF50', // Örnek farklı bir arka plan rengi
        color: 'white',
        borderRadius: '5px',
        fontSize: '16px',
        fontWeight: 'bold',
        cursor: 'pointer',
        marginLeft: '10px',
        textDecoration: 'none',
        width: '100%',
    },
    modalButtonClose: {
        backgroundColor: '#f44336', // Örnek farklı bir arka plan rengi
    },
    modalButtonText: {
        textAlign: 'center',
        marginLeft: '10px',
    },
};
const mobileStyles = {
    confirmationHeaderContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
       
        backgroundColor: '#DCDCDC',
        width: '100%',
    },
    taskManagementTitle: {
        fontSize: '30px',
        fontWeight: 'bold',
        color: '#333',
        marginBottom: '10px',
    },
    searchInput: {
        fontSize: '16px',
        padding: '8px',
        marginBottom: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: '80%',
    },
    divider: {
        width: '100%',
        borderTop: '1px solid #ccc',
        margin: '10px 0',
    },
    confirmationListItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px',
        borderBottom: '1px solid #ccc',
    },
    confirmationListItemText: {
        flex: 1,
        fontSize: '16px',
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    confirmationButton: {
        marginLeft: '5px',
        padding: '5px 10px',
        backgroundColor: '#0056b3',
        color: 'white',
        borderRadius: '5px',
        fontSize: '14px',
        fontWeight: 'bold',
        cursor: 'pointer',
        height: '30px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        boxShadow: '0 2px 5px rgba(0,0,0,0.9)',
        border: 'none', // Çizgiyi kaldırmak için eklenen özellik
        outline: 'none', // Çizgiyi kaldırmak için eklenen özellik
    },
    confirmationButtonText: {
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalView: {
        margin: 10,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 20,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    modalItem: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '10px',
    },
    modalItemTitle: {
        fontWeight: 'bold',
        marginRight: '5px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    modalItemText: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalButtonsContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '10px',
    },
    modalButton2: {
        padding: '10px 15px',
        backgroundColor: '#4CAF50',
        color: 'white',
        borderRadius: '5px',
        fontSize: '14px',
        fontWeight: 'bold',
        cursor: 'pointer',
        marginLeft: '5px',
        textDecoration: 'none',
        width: '100%',
    },
    modalButtonClose: {
        backgroundColor: '#f44336',
    },
    modalButtonText: {
        textAlign: 'center',
        marginLeft: '5px',
    },
};

export default AllUsersScreen;
