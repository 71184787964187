
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';  // Firebase veritabanı işlemleri için
import { BrowserRouter as Router, Route } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';

const MissionSystemScreen2 = () => {
    const userId = localStorage.getItem("userId");
    const navigate = useNavigate();
    const [userOtherJob, setUserOtherJob] = useState('');
    const [userSelectedGorev, setUserSelectedGorev] = useState('');
    const [fullName, setFullName] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    useEffect(() => {
      const checkUserId = async () => {
        const userId = await localStorage.getItem('userId');
        if (!userId) {
          navigate('/login', { replace: true });
        }
      };
    
      checkUserId();
    }, [navigate]);
    
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
  
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const styles = isMobile ? mobileStyles : desktopStyles;
  
    // Kullanıcı verilerini çekme fonksiyonu
    const fetchUserData = (userId) => {
      const db = getDatabase();
      const userRef = ref(db, `kullanicilar/${userId}`);
  
      onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData) {
          setFullName(userData.fullName || 'İsim Bilinmiyor');
          setUserOtherJob(userData.otherJob || 'Belirtilmemiş');
          setUserSelectedGorev(userData.selectedGorev || 'Belirtilmemiş');
        }
      });
    };
  
    useEffect(() => {
      const fetchUserDataAndSet = async () => {
        try {
          const userId = await localStorage.getItem('userId');
          if (userId) {
            fetchUserData(userId); // Fonksiyonu çağır
          } else {
            console.error("Kullanıcı ID'si bulunamadı");
          }
        } catch (error) {
          console.error("Hata: ", error);
        }
      };
  
      fetchUserDataAndSet();
    }, []);
    return (
      <div style={styles.missionSystem2Container}>
        <p style={styles.missionSystem2Title}>GÖREV SİSTEMİ</p>
  
        <div style={styles.divider}/>
        <div style={styles.missionSystem2AvatarContainer}>
          {/* Avatar için Image bileşeni ya da özel bir bileşen kullanabilirsiniz */}
          <img src={require('../assets/missionuser.png')} // Avatar resminizin yolu
            style={styles.missionSystem2Avatar}
          />
          <p style={styles.missionSystem2Name}>{fullName}</p>
          <p style={styles.missionSystem3CurrentTask}>Mevcut Görev: {'\n'} {userOtherJob}{'\n'} {userSelectedGorev} </p>
        </div>
  
       
  
        <p style={styles.missionSystem2InfoText}>Görev tanımları hakkında bilgi almak için tıklayınız.</p>
      </div>
    );
  };
  const desktopStyles = {
    missionSystem2Container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
   
      top: 0,
      backgroundColor: '#f4f4f4',
      width: '100%',
      minHeight: '100vh',
      position: 'fixed',
      left: '50%', // Ekle
      transform: 'translateX(-50%)' // Ekle
    },
    missionSystem2Title: {
      
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      fontSize: '30px',
      fontWeight: 'bold',
      color: '#333',
       // Arka plan rengi, üst öğenin arka planının görünür olmasını sağlar
      zIndex: 1000, // Önde görünmesini sağlamak için yüksek bir z-index değeri
      padding: '10px 0',
      textAlign: 'center',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Hafif bir gölge ekleyerek öne çıkar

    },
    divider: {
      position: 'fixed',
      width: '100%',
      borderTop: '1px solid #ccc',
      margin: '20px 0',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Hafif bir gölge ekleyerek öne çıkar
    },
    missionSystem2AvatarContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '20px',
    },
    missionSystem2Avatar: {
      width: '250px',
      height: '240px',
      borderRadius: '50%',
      marginBottom: '10px',
    },
    missionSystem2Name: {
      fontSize: '25px',
      fontWeight: 'bold',
      color: 'black',
    },
    missionSystem3CurrentTask: {
      fontSize: '20px',
      color: 'black',
      textAlign: 'center',
      marginTop: '10px',
    },
    missionSystem2InfoText: {
      fontSize: '17px',
      color: '#555',
      marginTop: '20px',
      textAlign: 'center',
    },
  };
  const mobileStyles = {
    missionSystem2Container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
     
      top: 0,
      backgroundColor: '#f4f4f4',
      width: '100%',
      minHeight: '100vh',
      position: 'fixed',
      left: '50%', // Ekle
      transform: 'translateX(-50%)' // Ekle
    },
    missionSystem2Title: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#333',
       // Arka plan rengi, üst öğenin arka planının görünür olmasını sağlar
      zIndex: 1000, // Önde görünmesini sağlamak için yüksek bir z-index değeri
      padding: '10px 0',
      textAlign: 'center',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Hafif bir gölge ekleyerek öne çıkar

    },
    divider: {
      position: 'fixed',
      width: '100%',
      borderTop: '1px solid #ccc',
      margin: '15px 0',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Hafif bir gölge ekleyerek öne çıkar
    },
    missionSystem2AvatarContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '15px',
    },
    missionSystem2Avatar: {
      width: '200px',
      height: '190px',
      borderRadius: '50%',
      marginBottom: '8px',
    },
    missionSystem2Name: {
      fontSize: '20px',
      fontWeight: 'bold',
      color: 'black',
    },
    missionSystem3CurrentTask: {
      fontSize: '16px',
      color: 'black',
      textAlign: 'center',
      marginTop: '8px',
    },
    missionSystem2InfoText: {
      fontSize: '14px',
      color: 'black',
      marginTop: '15px',
      textAlign: 'center',
    },
  };
  

export default MissionSystemScreen2;