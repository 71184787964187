
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, onValue, update } from 'firebase/database';  // Firebase veritabanı işlemleri için
import { BrowserRouter as Router, Route } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';





const IKRedUsersScreen = ({ navigation }) => {
    const [users, setUsers] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const navigate = useNavigate();
    useEffect(() => {
      const checkUserId = async () => {
        const userId = await localStorage.getItem('userId');
        if (!userId) {
          navigate('/login', { replace: true });
        }
      };
    
      checkUserId();
    }, [navigate]);
    
  // Anahtar kelimeyi Türkçe'ye çevirme fonksiyonu
  const translateKey = (key) => {
    const translations = {
      ageDate: "Doğum Tarihi",
      selectedCityName: "Şehir",
      department: "Bölüm",
      additionalInfo: "Ek Bilgiler",
      selectedDistrictName: "İlçe",
      email: "E-posta",
      employmentStatus: "Çalışma Durumu",
      extraGorev: "Ek Görev",
      faculty: "Fakülte",
      fullName: "Ad Soyad",
      graduationYear: "Mezuniyet Yılı",
      highSchool: "Lise",
      langueDetails: "Yabancı Dil Bilgisi",
      maritalStatus: "Medeni Durum",
      merkezTasra: "Merkez/Taşra",
      onay: "Onay Durumu",
      status: "durum",
      otherDescription: "Diğer Açıklama",
      otherJob: "Diğer İş",
      phoneNumber: "Telefon Numarası",
      selectedGorev: "Seçilen Görev",
      selectedKademe: "Seçilen Kademe",
      selectedUniversity: "Üniversite",
      yukseklisans: "Yüksek Lisans/Doktora"
      // Diğer anahtar kelimeler eklenebilir
    };
    return translations[key] || key;
  };
  
  
  
    useEffect(() => {
      const db = getDatabase();
      const usersRef = ref(db, 'kullanicilar');
  
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
        const unapprovedUsers = Object.keys(data).filter(key => 
          data[key].IKonay === false && data[key].IKstatus === "onaylanmadı"
        )
          .map(key => {
            return {
              id: key,
              ...data[key]
            };
          });
  
        setUsers(unapprovedUsers);
      });
    }, []);
  
    
  
    const showUserDetails = (user) => {
      setSelectedUser(user);
      setModalVisible(true);
    };
  
    const renderItem = ({ item }) => (
      <div style={styles.confirmationListItem}>
        <p style={styles.confirmationListItemText}>{item.fullName}</p>
        <button style={styles.confirmationButton} onClick={() => showUserDetails(item)}>
          <p style={styles.confirmationButtonText}>İncele</p>
        </button>
      </div>
    );
  
  
    
  
    // Kullanıcı bilgilerini gösteren Modal pop-up'ı
  const UserDetailsModal = ({ visible, user, onClose }) => {
    return (
        <Modal
        isOpen={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)', // Arka plan rengini ayarla
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '400px', // Modal genişliğini burada ayarlayın
            maxHeight: '80%', // Modalın maksimum yüksekliğini sınırla
            overflow: 'auto', // İçeriği taşarsa kaydırma çubuğu ekle
            padding: '20px', // İç padding
            border: '2px solid black', // İnce çerçeve ekle
            borderRadius: '50px', // Köşelere yuvarlaklık ekle
          }
        }}
      >
        <div style={styles.centeredView}>
          <div style={styles.modalView}>
            <div>
              {Object.entries(user).map(([key, value]) => {
                if (key !== 'status' && key !== 'id' && key !== 'onay' && key !== 'IKstatus' && key !== 'IKonay' && key !== 'IKdurum' && key !== 'IKyonetim' && key !== 'ik') {
                  return (
                    <div key={key} style={styles.modalItem}>
                      <p style={styles.modalItemTitle}>{translateKey(key)}:</p>
                      <p style={styles.modalItemText}>{value.toString()}</p>
                    </div>
                  );
                }
              })}
            </div>
            <div style={styles.modalButtonsContainer}>
             
  
                <button
                  style={styles.modalButton}
                  onClick={() => setModalVisible(!modalVisible)}
                >
                  <p style={styles.modalButtonText}>Kapat</p>
                </button>
  
               
              </div>
          </div>
        </div>
      </Modal>
    );
  };
  
    
    return (
      <div style={{ flex: 1 }}>
      <div style={styles.confirmationHeaderContainer}>
       <p style={styles.taskManagementTitle}>Reddedilen Kullanıcılar</p>
        <div style={styles.divider}/>
  </div>
  
  <div>
          {users.map(user => (
            <div key={user.id} style={styles.confirmationListItem}>
              <p style={styles.confirmationListItemText}>{user.fullName.toUpperCase()}</p>
              <button style={styles.confirmationButton} onClick={() => showUserDetails(user)}>
                <p style={styles.confirmationButtonText}>İncele</p>
              </button>
            </div>
          ))}
        </div>
       <UserDetailsModal
          visible={modalVisible}
          user={selectedUser}
          onClose={() => setModalVisible(false)}
        />
    </div>
    );
  };



  const styles = {
    confirmationHeaderContainer: {
      
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20,
    
    },
    taskManagementTitle: {
        fontSize: 24,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center',
        display:'flex'
    },
    divider: {
        height: 1,
        backgroundColor: 'gray',
        flex: 1,
        marginLeft: 10,
    },
    confirmationListItem: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10,
        display: 'flex',
        borderBottomWidth: 1,
        borderBottomColor: 'lightgray',
    },
    confirmationListItemText: {
        fontSize: 18,
        fontWeight: 'bold'
    },
    confirmationButton: {
        backgroundColor: 'blue',
        paddingVertical: 5,
        paddingHorizontal: 10,
        borderRadius: 5,
        color: 'white',
        display: 'flex',
        width: '15%',
        alignItems: 'center',
        justifyContent: 'center',
        height: '40px',
        fontWeight: 'bold',
        border: 'none',
        cursor: 'pointer',
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    modalItem: {
        flexDirection: 'row',
        marginBottom: 10,
        display: 'flex',
    },
    modalItemTitle: {
        fontWeight: 'bold',
        marginRight: 5,
    },
    modalItemText: {
        flex: 1,
    },
    modalButtonsContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 20,
    },
    modalButton: {
        borderRadius: 5,
        paddingVertical: 10,
        paddingHorizontal: 20,
        marginLeft: 10,
        width: '100%',
        backgroundColor: 'darkgray'
    },
    modalButtonText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 17
    },
};


export default IKRedUsersScreen;