import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';  // Firebase veritabanı işlemleri için
import { BrowserRouter as Router, Route } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';



const MissionSystemScreen3 = () => {
    const userId = localStorage.getItem("userId");
    const navigate = useNavigate();
    const [hasIkApproval, setHasIkApproval] = useState(false); // Kullanıcının IK onayını kontrol etmek için state
   
    const [userOtherJob, setUserOtherJob] = useState('');
    const [userSelectedGorev, setUserSelectedGorev] = useState('');
    const [fullName, setFullName] = useState('');
    const [hasIkPermission, setHasIkPermission] = useState(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    useEffect(() => {
      const checkUserId = async () => {
        const userId = await localStorage.getItem('userId');
        if (!userId) {
          navigate('/login', { replace: true });
        }
      };
    
      checkUserId();
    }, [navigate]);
    
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const styles = isMobile ? mobileStyles : desktopStyles;
  
    
    useEffect(() => {
      // Kullanıcı bilgilerini yükleyen asenkron fonksiyon
      const loadUserDetails = async () => {
        const userId = localStorage.getItem('userId');
        if (userId) {
          // Kullanıcı ID'sini global değişkene ata
          global.userDetails = { userId: userId }; // global nesne kullanımını minimize etmek iyi bir pratiktir
          //requestUserPermission();
          await loadAdditionalUserData(userId);
        } else {
          // Kullanıcı ID yoksa, Login ekranına yönlendir
          //navigate('/login', { replace: true });
    
        }
      };
    
      // Kullanıcıya özgü verileri yükleyen fonksiyon
      const loadAdditionalUserData = async (userId) => {
          const db = getDatabase();
          const userRef = ref(db, `kullanicilar/${userId}`); // "kullanicilar" yerine "users" kullanıldı varsayım olarak
          
          onValue(userRef, (snapshot) => {
            const userData = snapshot.val();
            if (userData && (userData.ik || userData.IKyonetim)) {
              setHasIkPermission(true);
              console.log("IK izni var: " + userId);
            } else {
              setHasIkPermission(false);
              console.log("IK izni yok: " + userId);
            }
          }, {
            onlyOnce: true
          });
        };
    
        loadUserDetails();
      }, [navigate]); // veya [navigate] eğer React Router v6 kullanıyorsanız
    
    // Kullanıcı verilerini çekme fonksiyonu
    const fetchUserData = (userId) => {
      const db = getDatabase();
      const userRef = ref(db, `kullanicilar/${userId}`);
  
      onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData) {
          setFullName(userData.fullName || 'İsim Bilinmiyor');
          setUserOtherJob(userData.otherJob || 'Belirtilmemiş');
          setUserSelectedGorev(userData.selectedGorev || 'Belirtilmemiş');
        }
      });
    };
  
    useEffect(() => {
      const fetchUserDataAndSet = async () => {
        try {
          const userId = await localStorage.getItem('userId');
          if (userId) {
            fetchUserData(userId); // Fonksiyonu çağır
          } else {
            console.error("Kullanıcı ID'si bulunamadı");
          }
        } catch (error) {
          console.error("Hata: ", error);
        }
      };
  
      fetchUserDataAndSet();
    }, []);
  
  
    const [unapprovedCount, setUnapprovedCount] = useState(0);
    const [unapprovedCount2, setUnapprovedCount2] = useState(0);
   const [userInfo, setUserInfo] = useState(null);
  
  
    useEffect(() => {
      // Global değişkenden kullanıcı ID'sini alın
      const userId = global.userDetails?.userId;
      const db = getDatabase();
    
      if (userId) {
        const userRef = ref(db, 'kullanicilar/' + userId);
    
        // Veritabanından kullanıcı bilgilerini dinle
        onValue(userRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            setUserInfo(data);
          }
        }, {
          onlyOnce: true
        });
      }
    }, []);
  
    
    useEffect(() => {
      if (userInfo) {
        const db = getDatabase();
        const usersRef = ref(db, 'kullanicilar');
    
        onValue(usersRef, (snapshot) => {
          const usersData = snapshot.val();
          if (usersData) {
            let unapprovedUsers = [];
            if (userInfo.IKyonetim) {
              // Kullanıcının IKyonetim alanı true ise, onay true ve status belirlenmedi olanları say
              unapprovedUsers = Object.keys(usersData).filter(key => 
                usersData[key].onay === true && 
                usersData[key].IKstatus === "belirlenmedi"
              );
            } else {
              // Kullanıcının IKyonetim alanı false ise, normal sayımı yap
              unapprovedUsers = Object.keys(usersData).filter(key => {
                const user = usersData[key];
                const isOtherJobMatch = user.otherJob && userInfo.otherJob && user.otherJob === userInfo.otherJob;
                return user.onay === false && 
                       user.status === "belirlenmedi" && 
                       isOtherJobMatch;
              });
            }
            setUnapprovedCount(unapprovedUsers.length);
          } else {
            setUnapprovedCount(0); // Veri yoksa, sayacı 0 yap
          }
        });
      }
    }, [userInfo]); // userInfo değiştiğinde bu useEffect çalışır
  
    useEffect(() => {
      const db = getDatabase();
      const globalUserRef = ref(db, `kullanicilar/${userId}`);
    
      onValue(globalUserRef, (snapshot) => {
        const globalUserData = snapshot.val();
        if (globalUserData) {
          // Gorev değişiklikleri içinden koşullara uyanları filtrele
          const usersRef = ref(db, 'gorevdegisikligi');
          onValue(usersRef, (snapshot) => {
            const data = snapshot.val();
            if(data){
              let filteredKeys;
              if (globalUserData.IKyonetim === true) {
                // Global kullanıcının IKyönetim alanı false ise eşleşenleri al
                filteredKeys = Object.keys(data).filter(key => 
                  data[key].onay === true && 
                  data[key].IKDurum === 'belirlenmedi'
                );
              } else {
                // Global kullanıcının IKyönetim alanı true ise sadece onay ve Durum durumuna göre filtrele
                filteredKeys = Object.keys(data).filter(key => 
                  data[key].yeniKomisyon === globalUserData.otherJob && 
                  data[key].onay === false && 
                  data[key].Durum === 'belirlenmedi'
                );
              }
              setUnapprovedCount2(filteredKeys.length);
            } else {
              setUnapprovedCount2(0); // Veri yoksa, sayacı 0 yap
            }
          });
        }
      });
    
      return () => {
        // Burada veritabanı dinleyicilerini temizleme işlemi yapabilirsiniz
      };
    }, []);
    
    return (
      <div style={styles.missionSystem3Container}>
        <p style={styles.missionSystem3Title}>GÖREV SİSTEMİ</p>
  
        <div style={styles.divider}/>
        <div style={styles.missionSystem3AvatarContainer}>
          {/* Avatar için Image bileşeni ya da özel bir bileşen kullanabilirsiniz */}
          <img src={require('../assets/orta.png')} // Avatar resminizin yolu
            style={styles.missionSystem3Avatar}
          />
          <p style={styles.missionSystem3Name}>{fullName}</p>
          <p style={styles.missionSystem3CurrentTask}>Mevcut Görev: {'\n'} {userOtherJob}{'\n'} {userSelectedGorev} </p>
        </div>
  
       
        <button style={styles.missionSystem3Button} onClick={() => navigate('/taskmanagement')}>
          <p style={styles.missionSystem3ButtonText}>GÖREV YÖNETİMİ</p>
          {hasIkApproval && (unapprovedCount + unapprovedCount2 > 0) && ( // Eğer IK onayı varsa ve bildirim sayısı 0'dan büyükse bu View'ı göster
      <div style={styles.taskManagementBadge2}>
        <p style={styles.taskManagementBadgeText}>{unapprovedCount + unapprovedCount2} </p>
      </div>
    )}
        </button>
  
        <p style={styles.missionSystem3InfoText}>Görev tanımları hakkında bilgi almak için tıklayınız.</p>
      </div>
    );
  };
  const desktopStyles = {
    missionSystem3Container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
     
      top: 0,
      backgroundColor: '#f4f4f4',
      width: '100%',
      minHeight: '100vh',
      position: 'fixed',
      left: '50%', // Ekle
      transform: 'translateX(-50%)' // Ekle
    },
    missionSystem3Title: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      fontSize: '30px',
      fontWeight: 'bold',
      color: '#333',
       // Arka plan rengi, üst öğenin arka planının görünür olmasını sağlar
      zIndex: 1000, // Önde görünmesini sağlamak için yüksek bir z-index değeri
      padding: '10px 0',
      textAlign: 'center',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Hafif bir gölge ekleyerek öne çıkar
    },
    divider: {
      position: 'fixed',
      width: '100%',
      top: 0,
      borderTop: '1px solid #ccc', // Ayırıcı çizgi
      margin: '20px 0', // Üst ve alt boşluk
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)', 
    },
    missionSystem3AvatarContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '20px', // Alt boşluk
    },
    missionSystem3Avatar: {
      width: '170px', // Genişlik
      height: '170px', // Yükseklik
      borderRadius: '50%', // Yuvarlak şekil
      marginBottom: '10px', // Resim altındaki boşluk
    },
    missionSystem3Name: {
      fontSize: '25px',
      fontWeight: 'bold',
      color: 'black', // İsim rengi
    },
    missionSystem3CurrentTask: {
      fontSize: '20px',
      color: 'black', // Görev bilgisi rengi
      textAlign: 'center', // Metin hizalama
      marginTop: '10px', // Üst boşluk
    },
    missionSystem3Button: {
      padding: '10px 20px', // İç boşluk
      backgroundColor: '#fff', // Buton arka plan rengi
      color: 'white', // Buton metin rengi
      borderRadius: '5px', // Kenar yuvarlaklığı
      fontSize: '16px', // Metin boyutu
      fontWeight: 'bold', // Metin kalınlığı
      cursor: 'pointer', // Fare imleci
      display: 'flex', // Flexbox kullanımı
      alignItems: 'center', // İçerik ortalaması
      justifyContent: 'center', // İçerik ortalaması
      textDecoration: 'none', // Metin altı çizgi kaldırma
      width: 'fit-content', // İçerik genişliği kadar genişler
      boxShadow: '0 2px 5px rgba(0,0,0,0.9)',
      borderRadius: '40px', // Kenar yuvarlaklığı
      margin: '10px', // Çevresinde boşluk
    },
    missionSystem3ButtonText: {
      textAlign: 'center', // Metin hizalaması
      color: 'black'
    },
    taskManagementBadge: {
      backgroundColor: '#ff4136', // Rozet arka plan rengi
      borderRadius: '40px', // Kenar yuvarlaklığı
      color: 'white', // Rozet metin rengi
      padding: '5px 10px', // İç boşluk
      fontSize: '20px', // Metin boyutu
      marginLeft: '20px', // Sol boşluk
      width: '40px'
    },
    taskManagementBadgeText: {
      fontWeight: 'bold', // Metin kalınlığı
      fontSize: '20px'
    },
    missionSystem3InfoText: {
      fontSize: '17px',
      color: '#555', // Bilgi metni rengi
      marginTop: '20px', // Üst boşluk
      textAlign: 'center', // Metin hizalama
    }
  };
  const mobileStyles = {
    missionSystem3Container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
     
      top: 0,
      backgroundColor: '#f4f4f4',
      width: '100%',
      minHeight: '100vh',
      position: 'fixed',
    },
    missionSystem3Title: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#333',
       // Arka plan rengi, üst öğenin arka planının görünür olmasını sağlar
      zIndex: 1000, // Önde görünmesini sağlamak için yüksek bir z-index değeri
      padding: '10px 0',
      textAlign: 'center',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Hafif bir gölge ekleyerek öne çıkar
    },
    divider: {
      position: 'fixed',
      width: '100%',
      top: 0,
      borderTop: '1px solid #ccc', // Ayırıcı çizgi
      margin: '20px 0', // Üst ve alt boşluk
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)', 
    },
    missionSystem3AvatarContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '15px',
    },
    missionSystem3Avatar: {
      width: '170px', // Genişlik
      height: '170px', // Yükseklik
      borderRadius: '50%',
      marginBottom: '8px',
    },
    missionSystem3Name: {
      fontSize: '20px',
      fontWeight: 'bold',
      color: 'black',
    },
    missionSystem3CurrentTask: {
      fontSize: '16px',
      color: 'black',
      textAlign: 'center',
      marginTop: '8px',
    },
    missionSystem3Button: {
      padding: '8px 16px',
      backgroundColor: '#fff',
      color: 'white',
      borderRadius: '5px',
      fontSize: '14px',
      fontWeight: 'bold',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0 2px 5px rgba(0,0,0,0.9)',
      textDecoration: 'none',
      width: 'fit-content',
      margin: '8px',
    },
    missionSystem3ButtonText: {
      textAlign: 'center',
      color: 'black'
    },
    taskManagementBadge: {
      backgroundColor: '#ff4136',
      borderRadius: '20px',
      color: 'white',
      padding: '5px 10px',
      fontSize: '14px',
      marginLeft: '15px',
      width: 'fit-content',
    },
    taskManagementBadgeText: {
      fontWeight: 'bold',
      fontSize: '14px',
    },
    missionSystem3InfoText: {
      fontSize: '14px',
      color: '#555',
      marginTop: '15px',
      textAlign: 'center',
    },
  };
  

export default MissionSystemScreen3;
