import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'; // Web için yönlendirme
import DatePicker from 'react-datepicker'; // Web için tarih seçici
import 'react-datepicker/dist/react-datepicker.css';

import './registerr.css'; // Stil dosyasını içe aktarın

import moment from 'moment';
import 'moment/locale/tr';  // Türkçe dil desteği için
import { 
  MdLockOutline, 
  MdEmail, 
  MdAccountCircle, 

  MdHome, // Evlilik
  MdPhone, // Telefon
  MdLocationOn, // İl
  MdLocationCity, // İlçe
  MdSchool, // Lise (Üniversite ile aynı icon kullanılmış)
  MdBook, // Bölüm
  MdEvent, // Takvim (Tekrar kullanılmış)
  MdLanguage, // Yabancı Dil
  MdWork, // İş
  MdBusiness, // Bina
  MdSentimentDissatisfied, // Çalışmayan Kullanıcı
  MdAssignment, // Görev
  MdInfo // Ek Bilgi
} from 'react-icons/md';

import { useNavigate } from 'react-router-dom';

// Firebase ve diğer gereksinimler
import { signInWithEmailAndPassword, sendPasswordResetEmail, getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, set, push, onValue, update, get } from 'firebase/database';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import provincesUniversities from '../province-universities.json';
import cityData from '../il_ilceler.json';
import gorevler from '../gorevlerr.json';





import 'moment/locale/tr';  // Türkçe dil desteği için
const RegisterScreen = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [city, setCity] = useState('');
    const [district, setDistrict] = useState('');
    const [highSchool, setHighSchool] = useState('');
   
    const [university, setUniversity] = useState('');
    const [faculty, setFaculty] = useState('');
    const [department, setDepartment] = useState('');
    const [graduationYear, setGraduationYear] = useState('');
    const [yukseklisans, setYukseklisans] = useState('');
    const [langueDetails, setLangue] = useState('');
    const [maritalStatus, setMaritalStatus] = useState(null);
    const [employmentStatus, setEmploymentStatus] = useState(null);
    const [otherJob, setOtherJob] = useState('İmar Topluluğu Üyesi Değilim');
    const [otherDescription, setOtherDescription] = useState('');
    const [merkezTasra, setMerkezTasra] = useState('');

    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const [selectedKademe, setSelectedKademe] = useState(null);
    const [selectedGorev, setSelectedGorev] = useState('Bir Görevim Bulunmamaktadır');
    const [extraGorev, setExtraGorev] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [birthDate, setBirthDate] = useState(new Date());
    const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
    const [selectedUniversity, setSelectedUniversity] = useState('');

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
  
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const styles = isMobile ? mobileStyles : desktopStyles;
  
  
    const universityList = provincesUniversities.reduce((list, item) => {
      item.universities.forEach(university => {
        list.push({ label: university.name, value: university.name });
      });
      return list;
    }, []);
  
     // İlk başta, seçim için gerekli state'leri tanımlayın
     const [selectedCity, setSelectedCity] = useState(null);
     const [selectedDistrict, setSelectedDistrict] = useState(null);
     const [cityItems, setCityItems] = useState([]);
     const [districtItems, setDistrictItems] = useState([]);
     const [selectedCityName, setSelectedCityName] = useState('');
     
     const [selectedDistrictName, setSelectedDistrictName] = useState('');
  
    const loadCities = () => {
      const cities = cityData.cityset.CITY.map(city => ({
        label: city['_cityname'],
        value: city['_cityid']
        
      }));
    // cities dizisini logla
  
    setCityItems(cities);
      
    };
  
    // İlçeleri yükleyen fonksiyon
    const loadDistricts = (cityId) => {
      const city = cityData.cityset.CITY.find(city => city['_cityid'] === cityId);
      if (city && city.DISTRICT) {
        const districts = city.DISTRICT.map(district => ({
          label: district['DISTNAME'],
          value: district['DISTID']
        }));
        setDistrictItems(districts);
      } else {
        setDistrictItems([]);
      }
    };
  
    // ComponentDidMount'da şehirleri yükle
    useEffect(() => {
      loadCities();
    }, []);
  
    
    const onCityChange = (value) => {
      setSelectedCity(value);
      loadDistricts(value);
    
      // Seçilen şehrin adını bulun ve kaydedin
      const selectedCity = cityItems.find(item => item.value === value);
      setSelectedCityName(selectedCity ? selectedCity.label : '');
    
      setSelectedDistrict(null);
    };
    
  
    const showDatePicker = () => {
      setDatePickerVisibility(true);
  };
  
  const hideDatePicker = () => {
      setDatePickerVisibility(false);
  };
  
  const handleConfirm = (date) => {
    // `date` parametresini bir Date nesnesine dönüştürün
    const selectedDate = new Date(date.date);
    setBirthDate(selectedDate);
    hideDatePicker();
  };
  const formatDate = (date) => {
  if (!(date instanceof Date)) return 'Tarih seç';
  
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ay 0'dan başlar
  const year = date.getFullYear();
  
  return `${day}/${month}/${year}`;
  };
  
  
  
  
  
  
  
    const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    };
    const firebaseConfig = {
      apiKey: "AIzaSyDdugV4yuPfYBfVdTs6xEEFhrB6H9XgvRA",
      authDomain: "dernek-9b26c.firebaseapp.com",
      databaseURL: "https://dernek-9b26c-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "dernek-9b26c",
      storageBucket: "dernek-9b26c.appspot.com",
      messagingSenderId: "58054737120",
      appId: "1:58054737120:web:c4535a79d85d6a63102485",
      measurementId: "G-N3BL5L2NZN"
    };
    
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth(app);
  
    
  
    const saveUserToFirebase = () => {
  
      if (!validateForm()) return;
        // Tüm alanların doldurulup doldurulmadığını kontrol et
  
  
  // E-posta adresinin doğru formatı takip edip etmediğini kontrol et
  if (!validateEmail(email)) {
    window.alert("Uyarı: Geçerli bir e-posta adresi giriniz.");
    return;
  }
  
   // Şifre ve şifre tekrarını kontrol et
   if (password !== password2) {
    window.alert("Uyarı: Şifreler eşleşmiyor.");
    return;
  }
  createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
  
        // Kullanıcı ID'si ile birlikte veritabanı yolu oluştur
        const db = getDatabase();
        const userRef = ref(db, 'kullanicilar/' + user.uid);
  
        const newUser = {
          email,
          fullName,
          phoneNumber,
          selectedCityName,
          selectedDistrictName,
          highSchool,
          selectedUniversity,
          faculty,
          department,
          graduationYear,
          ageDate: moment(birthDate).format('DD/MM/YYYY'),
          status: otherJob === "İmar Topluluğu Üyesi Değilim" ? "onaylandı" : "belirlenmedi",
          yukseklisans,
          IKstatus: "belirlenmedi",
          langueDetails,
          maritalStatus,
          employmentStatus,
          otherJob,
          otherDescription,
          selectedKademe,
          selectedGorev, 
          additionalInfo,
          onay: otherJob === "İmar Topluluğu Üyesi Değilim" ? true : false,
        };
  
        // Kullanıcı bilgilerini veritabanına kaydet
        set(userRef, newUser)
        .then(() => {
          // Kayıt başarılı olduğunda bir alert göster
          window.alert("Başarılı, Kayıt işlemi başarılı.");
      
          // Kullanıcıyı login sayfasına yönlendir
          navigate('/login'); // Burada 'LoginScreen' giriş ekranınızın adı olmalıdır
        })
        .catch((error) => {
          // Hata durumunda bir alert göster
          window.alert("Hata, Kayıt sırasında bir sorun oluştu: " + error.message);
        });
      })
      .catch((error) => {
        if (error.code === 'auth/email-already-in-use') {
            window.alert("Hata: Bu e-posta adresiyle daha önce kayıt yapılmış.");
        } else {
          console.error('Kullanıcı kaydı sırasında bir hata oluştu: ', error);
          window.alert("Hata: Kayıt sırasında bir hata oluştu.");
        }
      });
    };
    
  
    
  
    const KademeRadioButton = ({ value, selectedValue, onClick }) => {
      return (
        <button style={styles.radioButton} onClick={onClick}>
          <div style={selectedValue === value ? { ...styles.radioCircle, ...styles.selectedRadioCircle } : styles.radioCircle}></div>
          <p style={styles.radioText}>{value}</p>
        </button>
      );
    };
  

  
   
    
    const RadioButton = ({ label, selected, onClick }) => {
      return (
        <div style={styles.radioButton} onClick={onClick}>
          <div style={selected ? {...styles.radioCircle, ...styles.selectedRadioCircle} : styles.radioCircle}></div>
          <span style={styles.radioText}>{label}</span>
        </div>
      );
    };
    
  
    const RadioButton2 = ({ value, selectedValue, onClick }) => {
      return (
        <button style={styles.radioButton2} onClick={onClick}>
       
          <div style={selectedValue === value ? { ...styles.radioCircle, ...styles.selectedRadioCircle } : styles.radioCircle} />
          <p style={styles.radioText}>{value}</p>
        </button>
      );
    };
    
   
    
  
    const getIconName = (value) => {
      switch (value) {
        case 'Kamu': return 'briefcase-account'; // İkon adınızı buraya yazın
        case 'Özel Sektör': return 'office-building'; // İkon adınızı buraya yazın
        case 'Öğrenci': return 'school'; // İkon adınızı buraya yazın
        case 'Mezun Çalışmıyor': return 'account-off-outline'; // İkon adınızı buraya yazın
        default: return ''; // Varsayılan ikon adı
      }
    };
    const [selectedKomisyon, setSelectedKomisyon] = useState('');
    const [komisyonGorevleri, setKomisyonGorevleri] = useState([]);
    
  
    const getGorevlerForKomisyon = (komisyonAdi) => {
      const komisyon = gorevler.find(item => item.komisyon === komisyonAdi);
      return komisyon ? komisyon.birimler.map(birim => birim.birim_baslik) : [];
    };
    
  
  
    const handleKomisyonSelect = (komisyonAdi) => {
      setSelectedKomisyon(komisyonAdi);
      const gorevler = getGorevlerForKomisyon(komisyonAdi);
      setKomisyonGorevleri(gorevler);
    };
    
  
  
  
    // Seçilen komisyonun görevlerini gösteren RadioButtons
  
    const validateForm = () => {
      // Boş alanları kontrol et
      if (!email || !password || !password2 || !fullName || !phoneNumber || !selectedCity || !selectedDistrict || !highSchool || !selectedUniversity || !faculty || !department || !graduationYear || !yukseklisans || !langueDetails || !maritalStatus || !employmentStatus  || !selectedKademe ) {
        window.alert("Uyarı: Lütfen tüm alanları doldurunuz.");
        return false;
      }
    
      // Şifre uzunluğunu kontrol et
      if (password.length < 8) {
        window.alert("Uyarı: Şifre en az 8 karakter olmalıdır.");
        return false;
      }
    
      // Diğer metin alanlarının uzunluğunu kontrol et
      const maxCharFields = [fullName, phoneNumber, highSchool, faculty, department, yukseklisans, langueDetails, otherDescription, extraGorev, additionalInfo];
      const isAnyFieldTooLong = maxCharFields.some(field => field.length > 200);
    
      if (isAnyFieldTooLong) {
        window.alert("Uyarı: Metin alanları maksimum 200 karakter olmalıdır.");
        return false;
      }
    
      // E-posta formatını kontrol et
      if (!validateEmail(email)) {
        window.alert("Uyarı: Geçerli bir e-posta adresi giriniz.");
        return false;
      }
    
      // Şifrelerin eşleşip eşleşmediğini kontrol et
      if (password !== password2) {
        window.alert("Uyarı: Şifreler eşleşmiyor.");
        return false;
      }
    
      return true; // Tüm kontroller geçti
    };
    
  
    return (
      <div style={styles.registerContainer}>
        {/* Ek Açıklamalar */}
        <div style={styles.registerInputRow}>
        
          
        </div>
          {/* Ek Açıklamalar */}
          <div style={styles.registerInputRow}>
        
          
        </div>
        {/* E-posta */}
        <div style={styles.registerInputRow}>
        
          <MdEmail name="email" size={25} color="#737373" />
          <input placeholder="E-posta" style={styles.registerTextInput}  value={email} onChange={(e) => setEmail(e.target.value)} />
          <p style={styles.requiredStar}>*</p>
        </div>
        
        <div style={styles.registerInputRow}>
          <MdLockOutline name="lock" size={25} color="#737373" />
                <input
        placeholder="Şifre"
        style={styles.registerTextInput}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        type="password" // HTML için doğru input type (secureTextEntry yerine)
      />
          <p style={styles.requiredStar}>*</p>
        </div>
  
        <div style={styles.registerInputRow}>
          <MdLockOutline name="lock" size={25} color="#737373" />
          <input placeholder="Şifre Tekrar" style={styles.registerTextInput}  value={password2} onChange={(e) => setPassword2(e.target.value)} type="password" />
          <p style={styles.requiredStar}>*</p>
        </div>
        
        {/* Ad Soyad */}
        <div style={styles.registerInputRow}>
          <MdAccountCircle name="account" size={25} color="#737373" />
          <input placeholder="Adınız Soyadınız" style={styles.registerTextInput} value={fullName} onChange={(e) => setFullName(e.target.value)}  />
          <p style={styles.requiredStar}>*</p>
        </div>
  
       

<div style={styles.registerInputRow4}>
  <MdEvent name="calendar" size={25} color="gray" />
  <p style={styles.labelextra2}>Doğum Tarihi</p>
  <div style={styles.datePickerContainer}>  {/* Yeni stil ile sarmaladık */}
    <DatePicker
      selected={birthDate}
      onChange={(date) => setBirthDate(date)}
      dateFormat="dd/MM/yyyy"
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      maxDate={new Date()}
      style={styles.dateepickerr}
    />
  </div>
</div>


  
      {/* Medeni Durum */}
      <div style={styles.registerInputRow}>
  <MdHome name="ring" size={25} color="#737373" />
  <div style={styles.radioGroup}>
    <RadioButton label="Evli" selected={maritalStatus === 'Evli'} onClick={() => setMaritalStatus('Evli')} />
    <RadioButton label="Bekar" selected={maritalStatus === 'Bekar'} onClick={() => setMaritalStatus('Bekar')} />
  </div>
  <p style={styles.requiredStar}>*</p>
</div>
  
        {/* Telefon */}
        <div style={styles.registerInputRow}>
          <MdPhone name="phone" size={25} color="#737373" />
          <input placeholder="Telefon Numarası" style={styles.registerTextInput} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}  />
          <p style={styles.requiredStar}>*</p>
        </div>

        {/* İl */}
        <div style={styles.registerInputRow}>
        
          <MdLocationOn name="city-variant-outline" size={25} color="#737373" />
          <select
  onChange={(e) => {
    const value = e.target.value;
    setSelectedCity(value);
    loadDistricts(value);
    const selectedCityItem = cityItems.find(item => item.value === value);
    setSelectedCityName(selectedCityItem ? selectedCityItem.label : '');
    setSelectedDistrict(null);
  }}
  value={selectedCity || ''} // 'null' veya 'undefined' yerine boş string kullanımı
>
  {cityItems.map(item => (
    <option key={item.value} value={item.value}>{item.label}</option>
  ))}
</select>

          <p style={styles.requiredStar}>*</p>
        </div>
  
        {/* İlçe */}
        <div style={styles.registerInputRow}>
          <MdLocationCity name="city" size={25} color="#737373" />
          <select
  onChange={(e) => {
    const value = e.target.value;
    setSelectedDistrict(value);

    // Seçilen ilçenin ismini bul ve kaydet
    const selectedDistrictItem = districtItems.find(item => item.value === value);
    setSelectedDistrictName(selectedDistrictItem ? selectedDistrictItem.label : '');
  }}
  value={selectedDistrict || ''}  // Kontrollü bir bileşen olduğundan, 'null' veya 'undefined' yerine boş bir string verilmeli.
  disabled={!selectedCity || !districtItems.length}
>
  <option value="" disabled>İlçe Seçiniz</option>  // Placeholder olarak kullanılır
  {districtItems.map((item) => (
    <option key={item.value} value={item.value}>{item.label}</option>
  ))}
</select>

          <p style={styles.requiredStar}>*</p>
        </div>
  
        {/* Lise */}
        <div style={styles.registerInputRow}>
          <MdSchool name="school-outline" size={25} color="#737373" />
          <input placeholder="Lise / Mezun Olunan Lise" style={styles.registerTextInput} value={highSchool} onChange={(e) => setHighSchool(e.target.value)} />
          <p style={styles.requiredStar}>*</p>
        </div>
  
        {/* Üniversite */}
        <div style={styles.registerInputRow}>
          <MdSchool name="bank" size={25} color="#737373" />
                 
          <select
  onChange={(e) => setSelectedUniversity(e.target.value)}
  value={selectedUniversity || ''} // Eğer `selectedUniversity` değeri `null` veya `undefined` ise, boş bir string kullanılır.
>
  <option value="" disabled>Bir üniversite seçin</option> // Placeholder olarak kullanılır
  {universityList.map((uni) => (
    <option key={uni.value} value={uni.value}>{uni.label}</option>
  ))}
</select>

   <p style={styles.requiredStar}>*</p>
  
  
        </div>
  
        {/* Fakülte */}
        <div style={styles.registerInputRow}>
          <MdBook name="bank" size={25} color="#737373" />
          <input placeholder="Fakülte / Mezun Olunan Fakülte" style={styles.registerTextInput} value={faculty} onChange={(e) => setFaculty(e.target.value)} />
          <p style={styles.requiredStar}>*</p>
        </div>
  
        {/* Bölüm */}
        <div style={styles.registerInputRow}>
          <MdBook name="book" size={25} color="#737373" />
          <input placeholder="Bölüm / Mezun Olunan Bölüm" style={styles.registerTextInput} value={department} onChange={(e) => setDepartment(e.target.value)} />
          <p style={styles.requiredStar}>*</p>
        </div>
  
        {/* Mezuniyet Yılı */}
        <div style={styles.registerInputRow}>
          <MdEvent name="calendar-check-outline" size={25} color="#737373" />
          <input placeholder="Mezuniyet Yılı" style={styles.registerTextInput} value={graduationYear} onChange={(e) => setGraduationYear(e.target.value)}  />
          <p style={styles.requiredStar}>*</p>
        </div>
  
        {/* Yüksek Lisans/Doktora Bilgisi */}
        <div style={styles.registerInputRow}>
          <MdSchool name="school-outline" size={25} color="#737373" />
          <input 
            placeholder="Yüksek Lisans/Doktora Bilgisi" 
            style={styles.registerTextInput} 
            value={yukseklisans} onChange={(e) => setYukseklisans(e.target.value)}
            
          
          />
            <p style={styles.requiredStar}>*</p>
        </div>
  
        {/* Yabancı Dil Bilgisi */}
        <div style={styles.registerInputRow}>
          <MdLanguage name="translate" size={25} color="#737373" />
          <input 
            placeholder="Yabancı Dil - Sınav Yılı ve Puanı" 
            style={styles.registerTextInput} 
            value={langueDetails} onChange={(e) => setLangue(e.target.value)}
           
          />
            <p style={styles.requiredStar}>*</p>
        </div>
  
        <div style={styles.calismatextContainer}>
        <MdWork name="briefcase" size={25} color="#737373"  />
        <p style={styles.labelcalisma}>Çalışma Durumu</p>
        <p style={styles.requiredStar}>*</p>
        </div>
  
        {/* Çalışma Durumu */}
        <div style={styles.registerInputRow}>
      
      <div style={styles.radioGroup2}>
        <RadioButton2 value="Kamu" selectedValue={employmentStatus} onClick={() => setEmploymentStatus('Kamu')} />
        <RadioButton2 value="Özel Sektör" selectedValue={employmentStatus} onClick={() => setEmploymentStatus('Özel Sektör')} />
        <RadioButton2 value="Öğrenci" selectedValue={employmentStatus} onClick={() => setEmploymentStatus('Öğrenci')} />
        <RadioButton2 value="Mezun Çalışmıyor" selectedValue={employmentStatus} onClick={() => setEmploymentStatus('Mezun Çalışmıyor')} />
      </div>
    </div>
  
        {/* Çalışılan Kurum */}
        <div style={styles.registerInputRow}>
          <MdBusiness name="office-building-outline" size={25} color="#737373" />
          <input placeholder="Çalışılan Kurum" style={styles.registerTextInput}  />
          <p style={styles.requiredStar}>*</p>
        </div>
  
        {/* Görev ve Pozisyon */}
        <div style={styles.registerInputRow}>
          <MdAssignment name="account-tie-outline" size={25} color="#737373" />
          <input placeholder="Görev ve Pozisyon"   value={otherDescription} onChange={(e) => setOtherDescription(e.target.value)} style={styles.registerTextInput} />
          <p style={styles.requiredStar}>*</p>
        </div>
  
  
  
  
    
  
        <div style={styles.kademeHeaderContainer}>
          <MdAssignment name="account-tie" size={25} color="#737373" />
          <p style={styles.labelcalisma}>Görev Almak İstiyor Musunuz?</p>
          <p style={styles.requiredStar}>*</p>
        </div>
  
                <div style={styles.registerInputRow}>
          
            <div style={styles.radioGroup2}>
              <KademeRadioButton 
                value="Evet" 
                selectedValue={selectedKademe} 
                onClick={() => setSelectedKademe('Evet')} 
              />
              <KademeRadioButton 
                value="Hayır" 
                selectedValue={selectedKademe} 
                onClick={() => setSelectedKademe('Hayır')} 
              />
           <KademeRadioButton 
                value="Görevim Bulunmaktadır" 
                selectedValue={selectedKademe} 
                onClick={() => setSelectedKademe('Görevim Bulunmaktadır')} 
              />
            </div>
          </div>
  
  
         
  
    
  

  
  
  <p style={styles.labelextra}>Belirtmek istediğiniz hususlar varsa yazınız</p>
  <div style={styles.registerInputRow}>
    <MdInfo name="comment-text-outline" size={25} color="#737373" />
    <input 
      placeholder="Ek bilgiler" 
      style={styles.registerTextInput} 
      value={additionalInfo}
      onChange={(e) => setAdditionalInfo(e.target.value)}
      
    />
    
  </div>
  
  <div style={styles.buttonContainer}>
    <button
       onClick={saveUserToFirebase}
       style={styles.submitButton}
    >
    
  
      <p style={styles.submitButtonText}>Başvuruyu Gönder</p>
      <MdEmail name="send" size={20} color="white" style={styles.iconStyle} />
    </button>
  </div>
  
       
      </div>
    );
  };
  const desktopStyles = {
    registerContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px'
    },
    registerInputRow: {
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    registerInputRow2: {
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center'
    },
    registerInputRow4: {
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center' // Burada ekledim
    },
    datePickerContainer: {  // Yeni eklenen stil
  
     
      width: '100%',
    },
    dateepickerr: {
      
    },
    radioButton: {
      margin: '10px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center'
    },
    radioButton2: {
      display: 'flex',
      alignItems: 'center',
      margin: '10px',
      cursor: 'pointer'
    },
    buttonContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      margin: 20
    },
    radioButton3: {
      display: 'flex',
      alignItems: 'center',
      margin: '10px',
      cursor: 'pointer'
    },
    radioButtonRow: {
      display: 'flex',
      justifyContent: 'center'
    },
    radioCircle: {
      width: '20px',
      height: '20px',
      borderRadius: '50%',  // Eklendi: Yuvarlak kenar çerçevesi için
      border: '2px solid #666',
      display: 'inline-block',
      marginRight: '8px'
    },
    selectedRadioCircle: {
      backgroundColor: '#4CAF50'
      
    },
    radioText: {
      marginLeft: '8px',
      color: 'black',
      fontSize: '15px'
    },
    radioGroup: {
      display: 'flex'
    },
    radioGroup2: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
         flexWrap: 'wrap'
    },
    registerTextInput: {
      marginLeft: '10px',
      padding: '8px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      width: '100%'
    },
    requiredStar: {
      color: 'red'
    },
    submitButton: {
      backgroundColor: '#4B0082', // Lacivertimsi arka plan rengi
      color: 'white', // Beyaz metin rengi
      padding: '10px 20px', // Dolgu
      with: '40%' ,
      border: 'none', // Kenarlık yok
      borderRadius: '5px', // Köşe yarıçapı
      cursor: 'pointer', // İşaretçi stilini değiştirme
      display: 'flex', // Yatay hizalama
      alignItems: 'center', // Dikey hizalama
      justifyContent: 'center', // İçerik hizalama
      fontSize: '16px', // Yazı boyutu
      fontWeight: 'bold', // Kalın yazı
    },
    submitButtonText: {
      margin: '0 10px'
    },
    iconStyle: {
      marginLeft: '5px'
    },
    calismatextContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    labelextra: {
      fontStyle: 'italic',
    },
    labelextra2: {
      fontStyle: 'italic',
      marginLeft: 20,

     
   
    },
    kademeHeaderContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  };
  
  const mobileStyles = {
    registerContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px', // Padding'i küçülttüm
    },
    registerInputRow: {
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    registerInputRow2: {
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center',
    },
    registerInputRow4: {
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center' // Burada ekledim
    },
    datePickerContainer: {  // Yeni eklenen stil
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    dateepickerr: {
      
    },
    radioButton: {
      margin: '8px', // Margin'i küçülttüm
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    radioButton2: {
      display: 'flex',
      alignItems: 'center',
      margin: '8px', // Margin'i küçülttüm
      cursor: 'pointer',
    },
    buttonContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      margin: '15px', // Margin'i küçülttüm
    },
    radioButton3: {
      display: 'flex',
      alignItems: 'center',
      margin: '8px', // Margin'i küçülttüm
      cursor: 'pointer',
    },
    radioButtonRow: {
      display: 'flex',
      justifyContent: 'center',
    },
    dogumtext: {
    },
    radioCircle: {
      width: '18px', // Boyutunu küçülttüm
      height: '18px',
      borderRadius: '50%',
      border: '2px solid #666',
      
      display: 'inline-block',
      marginRight: '6px', // Margin'i küçülttüm
    },
    selectedRadioCircle: {
      backgroundColor: '#4CAF50',
    },
    radioText: {
      marginLeft: '6px', // Margin'i küçülttüm
      color: 'black',
      fontSize: '12px'
    },
    radioGroup: {
      display: 'flex',
    },
    radioGroup2: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
         flexWrap: 'wrap'
    },
    registerTextInput: {
      marginLeft: '10px',
      padding: '6px', // Padding'i küçülttüm
      border: '1px solid #ccc',
      borderRadius: '4px',
      width: '100%',
    },
    requiredStar: {
      color: 'red',
    },
    submitButton: {
      backgroundColor: '#4B0082', // Lacivertimsi arka plan rengi
      color: 'white', // Beyaz metin rengi
      padding: '10px 20px', // Dolgu
      width: '100%', // Tam genişlik
      border: 'none', // Kenarlık yok
      borderRadius: '5px', // Köşe yarıçapı
      cursor: 'pointer', // İşaretçi stilini değiştirme
      display: 'flex', // Yatay hizalama
      alignItems: 'center', // Dikey hizalama
      justifyContent: 'center', // İçerik hizalama
      fontSize: '16px', // Yazı boyutu
      fontWeight: 'bold', // Kalın yazı
      marginBottom: '10px', // Butonlar arasında boşluk
    },
    submitButtonText: {
      margin: '0 10px',
    },
    iconStyle: {
      marginLeft: '5px',
    },
    calismatextContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    labelextra: {
      fontStyle: 'italic',
    },
    labelextra2: {
      fontStyle: 'italic',
      marginLeft: 20,
   
    },
    kademeHeaderContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    DatePickerstyle: {
      padding: 10,
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      borderRadius: 10,
      marginLeft: 5,
      width: '10%', // Genişlik yüzde olarak ayarlandı
      marginRight: 30,
      marginLeft: 20
    }
  };
  
  
  

export default RegisterScreen;
