
import React, { useState, useEffect } from 'react';
import { useNavigate ,useParams } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, set, push ,onValue, update, get} from 'firebase/database';
import moment from 'moment';
import Modal from 'react-modal';

import { Link } from 'react-router-dom';

const ReportScreen = () => {
  const navigate = useNavigate();
  const [hasIkPermission, setHasIkPermission] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

const handleResize = () => {
  setIsMobile(window.innerWidth < 768);
};

useEffect(() => {
  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);
useEffect(() => {
  const checkUserId = async () => {
    const userId = await localStorage.getItem('userId');
    if (!userId) {
      navigate('/login', { replace: true });
    }
  };

  checkUserId();
}, [navigate]);

const styles = isMobile ? mobileStyles : desktopStyles;
    const firebaseConfig = {
        apiKey: "AIzaSyDdugV4yuPfYBfVdTs6xEEFhrB6H9XgvRA",
        authDomain: "dernek-9b26c.firebaseapp.com",
        databaseURL: "https://dernek-9b26c-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "dernek-9b26c",
        storageBucket: "dernek-9b26c.appspot.com",
        messagingSenderId: "58054737120",
        appId: "1:58054737120:web:c4535a79d85d6a63102485",
        measurementId: "G-N3BL5L2NZN"
      };
      const app = initializeApp(firebaseConfig);
      const database = getDatabase(app);
    
    const userId = localStorage.getItem("userId"); // Kullanıcı ID'si
    const [isLoading, setIsLoading] = useState(false);
    const checkIKonayAndNavigate = async () => {
      setIsLoading(true);
      // Kullanıcının verisini çekme işlemi (örneğin, şu anki kullanıcı için)
      // Bu örnek, Firebase Realtime Database kullanıldığını varsaymaktadır
      const kullaniciRef = ref(database, '/kullanicilar/' + userId); // 'currentUserID' şu anki kullanıcının ID'si olmalı
      onValue(kullaniciRef, (snapshot) => {
        const userData = snapshot.val();
        
          // IKonay alanı true ise yönlendirme
          navigate('/insankaynaklarirapor');
     
        
        setIsLoading(false);
      }, {
        onlyOnce: true
      });
    };
  
    if (isLoading) {
      return <p>Yükleniyor...</p>; // Yükleniyor ekranı veya göstergesi
    }
    
    return (
      
     <div contentContainerStyle={styles.reportScrollView}>
     <div style={styles.reportContainer}>
            <p style={styles.reportTitle}>RAPOR SİSTEMİ</p>
        <div style={styles.divider} />
  
        <img src={require('../assets/reportsystem.png')} // Buraya resmin yolu gelecek
          style={styles.reportImage}
        />
        <button style={styles.reportButton}  onClick={() => navigate('/insankaynaklari')}>
          <p style={styles.reportButtonText}>ALT KOMİSYON İNSAN KAYNAĞI İŞLEMLERİ</p>
        </button>
        <button style={styles.reportButton}  onClick={checkIKonayAndNavigate}>
          <p style={styles.reportButtonText}>ALT KOMİSYON İNSAN KAYNAĞI İŞLEMLERİ TÜM BİLGİLER</p>
        </button>
        <button style={styles.reportButton2} onClick={() => navigate('/kisiselgelisim')}>
          <p style={styles.reportButtonText}>KİŞİSEL GELİŞİM İSTATİSTİKLERİ</p>
        </button>
        </div>
      
        </div>
       
    );
  };
  const desktopStyles = {
    reportScrollView: {
      flex: 1,
      padding: 20,
      backgroundColor: '#f0f0f0',
    },
    reportContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#ffffff',
      borderRadius: 10,
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      padding: 20,
      width: '100%',
      maxWidth: '600px',
      margin: '0 auto',
    },
    reportTitle: {
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 20,
      color: '#333333',
    },
    divider: {
      width: '100%',
      height: 1,
      backgroundColor: '#dddddd',
      marginBottom: 20,
    },
    reportImage: {
      width: '100%',
      height: 'auto',
      marginBottom: 20,
    },
    reportButton: {
      backgroundColor: '#333333',
      borderRadius: 5,
      paddingVertical: 10,
      paddingHorizontal: 20,
      marginBottom: 10,
      cursor: 'pointer',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    reportButton2: {
      backgroundColor: '#666666',
      borderRadius: 5,
      paddingVertical: 10,
      paddingHorizontal: 20,
      marginBottom: 10,
      cursor: 'pointer',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    reportButtonText: {
      color: '#ffffff',
      fontSize: 16,
    },
  };
  
  const mobileStyles = {
    reportScrollView: {
      flex: 1,
      padding: 10,
      backgroundColor: '#f0f0f0',
    },
    reportContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#ffffff',
      borderRadius: 10,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      padding: 15,
      width: '100%',
      maxWidth: '90%',
      margin: '10px auto',
    },
    reportTitle: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 15,
      color: '#333333',
      textAlign: 'center',
    },
    divider: {
      width: '100%',
      height: 1,
      backgroundColor: '#dddddd',
      marginBottom: 15,
    },
    reportImage: {
      width: '100%',
      height: 'auto',
      marginBottom: 15,
    },
    reportButton: {
      backgroundColor: '#333333',
      borderRadius: 5,
      paddingVertical: 8,
      paddingHorizontal: 15,
      marginBottom: 8,
      cursor: 'pointer',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    reportButton2: {
      backgroundColor: '#666666',
      borderRadius: 5,
      paddingVertical: 8,
      paddingHorizontal: 15,
      marginBottom: 8,
      cursor: 'pointer',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    reportButtonText: {
      color: '#ffffff',
      fontSize: 14,
    },
  };
  
export default ReportScreen;