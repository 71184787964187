


import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, onValue, update, push, query, orderByChild, equalTo, get ,set} from 'firebase/database';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import ConfirmationPopup from './ConfirmationPopup';

const IKGorevDegisiklikOnayScreen = () => {
    const navigate = useNavigate();
    const [pendingApprovals, setPendingApprovals] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [confirmationVisible2, setConfirmationVisible2] = useState(false);

    useEffect(() => {
        const checkUserId = async () => {
            const userId = await localStorage.getItem('userId');
            if (!userId) {
                navigate('/login', { replace: true });
            }
        };

        checkUserId();
    }, [navigate]);

    useEffect(() => {
        const db = getDatabase();
        const usersRef = ref(db, 'gorevdegisikligi');

        onValue(usersRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const unapprovedUsers = Object.keys(data).filter(key =>
                    data[key].onay === true && data[key].Durum === 'onaylandı' && data[key].IKDurum === 'belirlenmedi'
                )
                    .map(key => {
                        return {
                            id: key,
                            ...data[key]
                        };
                    });

                setPendingApprovals(unapprovedUsers);
            } else {
                setPendingApprovals([]); // Veri yoksa, boş liste ata
            }
        });

    }, []);

    const showUserDetails = (item) => {
        setSelectedUser(item);
        setModalVisible(true);
    };

    const rejectUser = (userId) => {
        setConfirmationVisible(true);
        setModalVisible(false);
    };

    const approveUser = (userId) => {
        setConfirmationVisible2(true);
        setModalVisible(false);
    };

    const closeAndResetModal = () => {
        setSelectedUser(null);
        setModalVisible(false);
    };

    const onApproveConfirm = (userId) => {
        const db = getDatabase();
        const currentDate = new Date().toISOString().split('T')[0]; // YYYY-MM-DD formatında bugünün tarihi

        const gorevDegisikligiRef = ref(db, `gorevdegisikligi/${userId}`);
        update(gorevDegisikligiRef, {
            IKonay: true,
            IKDurum: 'onaylandı'
        }).then(() => {
            console.log("Görev değişikliği onaylandı");
        }).catch(error => {
            console.error("Görev değişikliği onay hatası: ", error);
        });

        const userRef = ref(db, `kullanicilar/${userId}`);
        update(userRef, {
            otherJob: selectedUser.yeniKomisyon,
            selectedGorev: selectedUser.yeniGorev,
            selectedCityName: selectedUser.sehir,
            selectedDistrictName: selectedUser.ilce
        }).then(() => {
            const gorevBilgilerRef = ref(db, `GorevBilgiler/${userId}`);
            const newGorev = push(gorevBilgilerRef);
            set(newGorev, {
                Komisyon: selectedUser.yeniKomisyon,
                Gorev: selectedUser.yeniGorev,
                GorevbaslangicTarihi: currentDate,
                GorevBitisTarihi: currentDate,
            });
            window.alert("Başarılı: Görev değişikliği başarıyla kayıt edildi.");
        }).catch(error => {
            console.error("Görev değişikliği onay hatası: ", error);
        });
    };

    const onApproveDecline = (userId) => {
        const db = getDatabase();
        const gorevDegisikligiRef = ref(db, `gorevdegisikligi/${userId}`);
        update(gorevDegisikligiRef, {
            onay: false,
            Durum: 'onaylanmadı'
        }).then(() => {
            console.log("Görev değişikliği onaylanmadı");
        }).catch(error => {
            console.error("Görev değişikliği onay hatası: ", error);
        });
        window.alert("Başarılı: Görev değişikliği başarıyla reddedildi.");
    };

    return (
        <div style={styles.gorevdegisiklikonay}>
            <div style={styles.confirmationHeaderContainer}>
                <p style={styles.taskManagementTitle}>Onay Bekleyen Kullanıcılar</p>
                <div style={styles.divider} />
            </div>
            <div style={styles.listContainer}>
                {pendingApprovals.map((item) => (
                    <div key={item.id} style={styles.item} onClick={() => showUserDetails(item)}>
                        <p style={styles.title}>{item.AdSoyad.toUpperCase() || 'İsim Bilinmiyor'}</p>
                        <div style={styles.gorevdegisiklikonayrender}>
                            <p style={styles.title4}>Mevcut Komisyon: </p>
                            <p>{item.mevcutKomisyonAdi}</p>
                        </div>
                        <div style={styles.gorevdegisiklikonayrender}>
                            <p style={styles.title4}>Mevcut Görev: </p>
                            <p>{item.mevcutGorev}</p>
                        </div>
                        <div style={styles.gorevdegisiklikonayrender}>
                            <p style={styles.title4}>Yeni Komisyon İsteği: </p>
                            <p>{item.yeniKomisyon}</p>
                        </div>
                        <div style={styles.gorevdegisiklikonayrender}>
                            <p style={styles.title4}>Yeni Görev İsteği: </p>
                            <p>{item.yeniGorev}</p>
                        </div>
                    </div>
                ))}
            </div>

            {confirmationVisible && (
                <ConfirmationPopup
                    message={`${selectedUser.AdSoyad.toUpperCase()} kullanıcısını reddetmek istiyor musunuz?`}
                    onConfirm={() => {
                        onApproveDecline(selectedUser.id);
                        setModalVisible(false); // Modalı kapat
                        setConfirmationVisible(false); // Onay penceresini kapat
                    }}
                    onCancel={() => setConfirmationVisible(false)}
                />
            )}

            {confirmationVisible2 && (
                <ConfirmationPopup
                    message={`${selectedUser.AdSoyad.toUpperCase()} kullanıcısını onaylamak istiyor musunuz?`}
                    onConfirm={() => {
                        onApproveConfirm(selectedUser.id);
                        setModalVisible(false); // Modalı kapat
                        setConfirmationVisible2(false); // Onay penceresini kapat
                    }}
                    onCancel={() => setConfirmationVisible2(false)}
                />
            )}

            <div style={styles.modalButtonsContainer2}>
                <button
                    style={styles.modalButtonClose}
                    onClick={() => navigate('/ikdgorevdegisiklikreddedilenler')}
                >
                    <p style={styles.modalButtonText}>Reddedilenler</p>
                </button>

                <button
                    style={styles.modalButtonApprove}
                    onClick={() => navigate('/ikgorevdegisiklikonaylananlar')}
                >
                    <p style={styles.modalButtonText}>Onaylananlar</p>
                </button>
            </div>

            <Modal
                isOpen={modalVisible}
                onRequestClose={() => setModalVisible(false)}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)', // Arka plan rengini ayarla
                    },
                    content: {
                        top: '44%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '400px', // Modal genişliğini burada ayarlayın
                        maxHeight: '80%', // Modalın maksimum yüksekliğini sınırla
                        overflow: 'auto', // İçeriği taşarsa kaydırma çubuğu ekle
                        padding: '20px', // İç padding
                        border: '2px solid black', // İnce çerçeve ekle
                        borderRadius: '50px', // Köşelere yuvarlaklık ekle
                    }
                }}
            >
                <div style={styles.gorevdegisiklikCenteredView}>
                    <div style={styles.scroolviewStyles3}>
                        <div style={styles.gorevdegisiklikModalView}>
                            <p style={styles.gorevdegisiklikModalTitle}>Kullanıcı Detayları</p>

                            {selectedUser && (
                                <>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>Mevcut Komisyon:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.mevcutKomisyonAdi}</p>
                                    </div>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>Mevcut Görev:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.mevcutGorev}</p>
                                    </div>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>Yeni Komisyon İsteği:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.yeniKomisyon}</p>
                                    </div>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>Yeni Görev İsteği:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.yeniGorev}</p>
                                    </div>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>Ad Soyad:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.AdSoyad}</p>
                                    </div>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>Bölüm:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.Bolum}</p>
                                    </div>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>Doğum Tarihi:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.DogumTarihi}</p>
                                    </div>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>Ek Görevi:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.EkGorev}</p>
                                    </div>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>Eposta:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.Eposta}</p>
                                    </div>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>Fakülte:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.Fakulte}</p>
                                    </div>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>Lise:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.Lise}</p>
                                    </div>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>Medeni Durum:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.MedeniDurum}</p>
                                    </div>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>Merkez Taşra:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.MerkezTasra}</p>
                                    </div>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>Mezuniyet Yılı:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.MezuniyetYili}</p>
                                    </div>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>İlçe:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.ilce}</p>
                                    </div>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>Yabancı Dil Bilgisi:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.YabanciDilBilgisi}</p>
                                    </div>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>Mevcut Görev Durumu:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.mevcutGorevDurumu}</p>
                                    </div>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>Neden:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.neden}</p>
                                    </div>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>İl:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.sehir}</p>
                                    </div>
                                    <div style={styles.ortala}>
                                        <p style={styles.gorevdegisiklikDetailTitle}>Üniversite:</p>
                                        <p style={styles.gorevdegisiklikDetailText}>{selectedUser.Üniversite}</p>
                                    </div>
                                </>
                            )}
                            <div style={styles.modalButtonContainer}>
                                <button
                                    style={styles.modalButtonApprove}
                                    onClick={() => approveUser(selectedUser.id)}
                                >
                                    <p style={styles.modalButtonText}>Onayla</p>
                                </button>
                                <button
                                    style={styles.modalButton}
                                    onClick={closeAndResetModal}
                                >
                                    <p style={styles.modalButtonText}>Kapat</p>
                                </button>
                                <button
                                    style={styles.modalButtonClose}
                                    onClick={() => rejectUser(selectedUser.id)}
                                >
                                    <p style={styles.modalButtonText}>Reddet</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

  const styles = {
    container: {
      flex: 1,
    },
    gorevdegisiklikonay: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    confirmationHeaderContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 20,
    },
    taskManagementTitle: {
      fontSize: '24px',
      fontWeight: 'bold',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex'
    },
    divider: {
      height: '1px',
      backgroundColor: 'gray',
      flex: 1,
      marginLeft: '10px',
    },
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      maxHeight: '800px',
      marginLeft: '40px',
      marginRight: '40px',
      borderRadius: '30px',
      marginBottom: '100px',
      boxShadow: '0px 4px 8px rgba(0,0,0,0.4)' // Gölge efekti ekle
    },
    item: {
      padding: '20px',
      borderBottom: '1px solid lightgray',
      cursor: 'pointer',
      backgroundColor: 'white',
      boxShadow: '0px 2px 4px rgba(0,0,0,0.05)' // Her ögeye hafif gölge efekti
    },
    title: {
        fontSize: '20px',
        color: '#333',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        fontWeight: 'bold'
      },
    gorevdegisiklikonayrender: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '5px',
    },
    title4: {
      fontWeight: 'bold',
      marginRight: 10,
    },
    modalView: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '22px',
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    scrollView: {
      width: '100%',
    },
    modalContent: {
      backgroundColor: 'white',
      borderRadius: '20px',
      padding: '35px',
      alignItems: 'center',
      boxShadow: '0px 8px 16px rgba(0,0,0,0.2)' // Modal'a belirgin gölge efekti
    },
    modalTitle: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '20px',
    },
    detailItem: {
      marginBottom: '10px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    detailTitle: {
      fontWeight: 'bold',
      marginRight: '5px',
    },
    detailText: {
      flex: 1,
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginTop: '20px',
    },
    modalButton: {
      borderRadius: '5px',
      padding: '10px 20px',
      cursor: 'pointer',
      borderRadius: '20px',
    backgroundColor: 'darkgray'
    },
    modalButtonApprove: {
      backgroundColor: 'green',
      borderRadius: '20px',
      padding: '10px 20px',
      cursor: 'pointer',
      width: '50%'
   
    },
    modalButtonClose: {
      backgroundColor: 'red', 
      borderRadius: '20px',
      padding: '10px 20px',
      cursor: 'pointer',
      width: '50%'
    },
    modalButtonReject: {
      backgroundColor: 'orange',
    },
    buttonText: {
      color: 'white',
      fontWeight: 'bold',
    },
    modalButtonsContainer2: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        position: 'fixed', // Pozisyonu sabitler
        bottom: '0', // Alt kenardan pozisyonlandırır
        left: '0', // Sol kenardan başlamasını sağlar
        right: '0', // Sağ kenardan başlamasını sağlar
     
        
        padding: '10px 0', // Üst ve alt padding
        paddingBottom: '100px',
      
    },
    
    ortala: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '10px',
    },
    gorevdegisiklikDetailTitle: {
      fontSize: '16px',
      fontWeight: 'bold',
      marginBottom: '5px',
    },
    gorevdegisiklikDetailText: {
      fontSize: '16px',
    },
    gorevdegisiklikCenteredView: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    scroolviewStyles3: {
      overflowY: 'auto',
      display:'flex'
    },
    gorevdegisiklikModalView: {
      backgroundColor: 'white',
      borderRadius: '10px',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      boxShadow: '0px 8px 16px rgba(0,0,0,0.25)' // Daha belirgin gölge efekti
    },
    gorevdegisiklikModalTitle: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '20px',
    },
    modalButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: '20px',
    },
    modalButtonText: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'white',
    }
  };
  
  



export default IKGorevDegisiklikOnayScreen;