
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, onValue, update } from 'firebase/database';  // Firebase veritabanı işlemleri için
import { BrowserRouter as Router, Route } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';





const IKGorevDegisiklikOnaylananlarScreen = () => {
  const navigate = useNavigate();
    const [pendingApprovals, setPendingApprovals] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    
    const [selectedUser, setSelectedUser] = useState(null);
    useEffect(() => {
      const checkUserId = async () => {
        const userId = await localStorage.getItem('userId');
        if (!userId) {
          navigate('/login', { replace: true });
        }
      };
    
      checkUserId();
    }, [navigate]);
    
    useEffect(() => {
      const db = getDatabase();
      const usersRef = ref(db, 'gorevdegisikligi');
  
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
        if(data){
        const unapprovedUsers = Object.keys(data).filter(key => 
          data[key].IKonay === true && data[key].IKDurum === 'onaylandı'
        )
          .map(key => {
            return {
              id: key,
              ...data[key]
            };
          });
  
        setPendingApprovals(unapprovedUsers);
      } else {
        setPendingApprovals([]); // Veri yoksa, boş liste ata
      }
      });
    
    }, []);
  
  
    const renderItem = ({ item }) => (
      <button style={styles.item} onClick={() => showUserDetails(item)}>
        <p style={styles.title}>{item.AdSoyad || 'İsim Bilinmiyor'}</p>
        <div style={styles.gorevdegisiklikonayrender}>
        <p style={styles.title4}>Mevcut Komisyon: </p>
        <p>{item.mevcutKomisyonAdi}</p>
  </div>
    <div style={styles.gorevdegisiklikonayrender}>
        <p style={styles.title4}>Mevcut Görev: </p>
        <p>{item.mevcutGorev}</p>
  </div>
    <div style={styles.gorevdegisiklikonayrender}>
    
        <p style={styles.title4}>Yeni Komisyon İsteği: </p>
        <p>{item.yeniKomisyon}</p>
  </div>
   <div style={styles.gorevdegisiklikonayrender}>
        <p style={styles.title4}>Yeni Görev İsteği: </p>
        <p>{item.yeniGorev}</p>
    </div>
        {/* Diğer bilgileri burada gösterebilirsiniz */}
      </button>
    );
  
    const showUserDetails = (item) => {
      setSelectedUser(item);
      setModalVisible(true);
    };
  
  
    const closeAndResetModal = () => {
      setSelectedUser(null);
      setModalVisible(false);
    };
  
    
    return (
      <div style={styles.gorevdegisiklikonay}>
        <div style={styles.confirmationHeaderContainer}>
       <p style={styles.taskManagementTitle}>Onaylanan Kullanıcılar</p>
        <div style={styles.divider}/>
  </div>

         <div style={styles.listContainer}>
  {pendingApprovals.map((item) => (
    <div key={item.id} style={styles.item} onClick={() => showUserDetails(item)}>
      <p style={styles.title}>{item.AdSoyad.toUpperCase() || 'İsim Bilinmiyor'}</p>
      <div style={styles.gorevdegisiklikonayrender}>
        <p style={styles.title4}>Mevcut Komisyon: </p>
        <p>{item.mevcutKomisyonAdi}</p>
      </div>
      <div style={styles.gorevdegisiklikonayrender}>
        <p style={styles.title4}>Mevcut Görev: </p>
        <p>{item.mevcutGorev}</p>
      </div>
      <div style={styles.gorevdegisiklikonayrender}>
        <p style={styles.title4}>Yeni Komisyon İsteği: </p>
        <p>{item.yeniKomisyon}</p>
      </div>
      <div style={styles.gorevdegisiklikonayrender}>
        <p style={styles.title4}>Yeni Görev İsteği: </p>
        <p>{item.yeniGorev}</p>
      </div>
    </div>
  ))}
</div>
  
  
        
  <Modal
  isOpen={modalVisible}
  onRequestClose={() => setModalVisible(false)}
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)', // Arka plan rengini ayarla
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '400px', // Modal genişliğini burada ayarlayın
      maxHeight: '80%', // Modalın maksimum yüksekliğini sınırla
      overflow: 'auto', // İçeriği taşarsa kaydırma çubuğu ekle
      padding: '20px', // İç padding
      border: '2px solid black', // İnce çerçeve ekle
      borderRadius: '50px', // Köşelere yuvarlaklık ekle
    }
  }}
>
  
          <div style={styles.gorevdegisiklikCenteredView}>
          <div style={styles.scroolviewStyles3}>
            <div style={styles.gorevdegisiklikModalView}>
              <p style={styles.gorevdegisiklikModalTitle}>Kullanıcı Detayları</p>
             
  
              {selectedUser && (
                <>
                <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>Mevcut Komisyon:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.mevcutKomisyonAdi}</p>
          </div>
          <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>Mevcut Görev:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.mevcutGorev}</p>
  
  </div>
          <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>Yeni Komisyon İsteği:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.yeniKomisyon}</p>
  </div>
  <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>Yeni Görev İsteği:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.yeniGorev}</p>
  </div>
          <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>Ad Soyad:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.AdSoyad}</p>
  </div>
  <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>Bölüm:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.Bolum}</p>
          </div>
          <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>Doğum Tarihi:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.DogumTarihi}</p>
  </div>
  <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>Ek Görevi:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.EkGorev}</p>
  </div>
          <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>Eposta:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.Eposta}</p>
  </div>
  <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>Fakülte:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.Fakulte}</p>
  </div>
          <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>Lise:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.Lise}</p>
  </div>
  <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>Medeni Durum:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.MedeniDurum}</p>
  </div>
          <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>Merkez Taşra:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.MerkezTasra}</p>
  </div>
  <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>Mezuniyet Yılı:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.MezuniyetYili}</p>
        </div>
          <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>İlçe:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.ilce}</p>
  </div>
  <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>Yabancı Dil Bilgisi:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.YabanciDilBilgisi}</p>
          </div>
          <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>Mevcut Görev Durumu:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.mevcutGorevDurumu}</p>
  </div>
  <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>Neden:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.neden}</p>
  </div>
          <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>İl:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.sehir}</p>
  </div>
  <div style={styles.ortala}>
          <p style={styles.gorevdegisiklikDetailTitle}>Üniversite:</p>
          <p style={styles.gorevdegisiklikDetailText}>{selectedUser.Üniversite}</p>
                  </div>
                  
                </>
              )}
              <div style={styles.modalButtonContainer}>
              
                  <button
                    style={styles.modalButton}
                    onClick={closeAndResetModal}
                  >
                    <p style={styles.modalButtonText}>Kapat</p>
                  </button>
                 
                </div>
            </div>
            </div>
          </div>
          
        </Modal>
  
      </div>
    );
  };


  const styles = {
    gorevdegisiklikonay: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
    },
    confirmationHeaderContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '20px',
    },
    taskManagementTitle: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#333',
    },
    divider: {
      height: '2px',
      backgroundColor: '#ececec',
      width: '100%',
      marginTop: '10px',
    },
    item: {
      backgroundColor: '#f9f9f9',
      padding: '10px',
      marginVertical: '8px',
      width: '100%',
      cursor: 'pointer',
      border: '1px solid #ddd',
    },
    title: {
      fontSize: '20px',
      color: '#333',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      fontWeight: 'bold'
    },
    gorevdegisiklikonayrender: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '5px',
      },
    title4: {
      fontWeight: 'bold',
      marginRight: '10px'
    },
    modalStyle: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      width: '100%',
      position: 'fixed',
      top: '0',
      left: '0',
    },
    gorevdegisiklikCenteredView: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    scroolviewStyles3: {
      maxHeight: '90%',
      width: '90%',
      overflowY: 'auto',
    },
    gorevdegisiklikModalView: {
      margin: '20px',
      backgroundColor: 'white',
      borderRadius: '20px',
      padding: '35px',
      alignItems: 'center',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5
    },
    gorevdegisiklikModalTitle: {
      fontSize: '22px',
      fontWeight: 'bold',
      marginBottom: '15px',
    },
    ortala: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      padding: '10px 0',
    },
    gorevdegisiklikDetailTitle: {
      fontWeight: 'bold',
      fontSize: '16px',
      width: '40%',
    },
    gorevdegisiklikDetailText: {
      fontSize: '16px',
      width: '60%',
    },
    modalButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: '20px',
    },
    modalButton: {
      padding: '10px 20px',
      borderRadius: '5px',
      margin: '0 10px',
      backgroundColor: 'darkgray',
      width: '100%'
    },
    modalButtonClose: {
      backgroundColor: '#dc3545',
      color: 'white',
    },
    modalButtonText: {
      color: 'white',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      maxHeight: '800px',
      marginLeft: '40px',
      marginRight: '40px',
      borderRadius: '30px',
      marginBottom: '100px',
      width: '100%',
      boxShadow: '0px 4px 8px rgba(0,0,0,0.4)' // Gölge efekti ekle
      },
  };
  


export default IKGorevDegisiklikOnaylananlarScreen;
