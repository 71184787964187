
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, onValue, update } from 'firebase/database';  // Firebase veritabanı işlemleri için
import { BrowserRouter as Router, Route } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

import ConfirmationPopup from './ConfirmationPopup';


const IKConfirmationScreen = ({ navigation }) => {
    const userId = localStorage.getItem("userId");
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [confirmationVisible2, setConfirmationVisible2] = useState(false);
  // Anahtar kelimeyi Türkçe'ye çevirme fonksiyonu
  const translateKey = (key) => {
    const translations = {
      ageDate: "Doğum Tarihi",
      selectedCityName: "Şehir",
      department: "Bölüm",
      additionalInfo: "Ek Bilgiler",
      selectedDistrictName: "İlçe",
      email: "E-posta",
      employmentStatus: "Çalışma Durumu",
      extraGorev: "Ek Görev",
      faculty: "Fakülte",
      fullName: "Ad Soyad",
      graduationYear: "Mezuniyet Yılı",
      highSchool: "Lise",
      langueDetails: "Yabancı Dil Bilgisi",
      maritalStatus: "Medeni Durum",
      merkezTasra: "Merkez/Taşra",
      onay: "Onay Durumu",
      status: "durum",
      otherDescription: "Diğer Açıklama",
      otherJob: "Diğer İş",
      phoneNumber: "Telefon Numarası",
      selectedGorev: "Seçilen Görev",
      selectedKademe: "Görev İsteği",
      selectedUniversity: "Üniversite",
      yukseklisans: "Yüksek Lisans/Doktora"
      // Diğer anahtar kelimeler eklenebilir
    };
    return translations[key] || key;
  };
  
  useEffect(() => {
    const checkUserId = async () => {
      const userId = await localStorage.getItem('userId');
      if (!userId) {
        navigate('/login', { replace: true });
      }
    };
  
    checkUserId();
  }, [navigate]);
  
  
    useEffect(() => {
      const db = getDatabase();
      const usersRef = ref(db, 'kullanicilar');
  
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
        if (data) { // Veri var mı kontrol et
          const unapprovedUsers = Object.keys(data).filter(key => 
            data[key].onay === true && data[key].status === "onaylandı" && data[key].IKstatus === "belirlenmedi"
          )
            .map(key => {
              return {
                id: key,
                ...data[key]
              };
            });
    
          setUsers(unapprovedUsers);
        } else {
          setUsers([]); // Veri yoksa, boş liste ata
        }
      });
    }, []);
    
  
    const showUserDetails = (user) => {
      setSelectedUser(user);
      setModalVisible(true);
    };
  
    const renderItem = ({ item }) => (
      <div style={styles.confirmationListItem}>
        <p style={styles.confirmationListItemText}>{item.fullName}</p>
        <button style={styles.confirmationButton} onClick={() => showUserDetails(item)}>
          <p style={styles.confirmationButtonText}>İncele</p>
        </button>
      </div>
    );
  
  
    const confirmApproval = () => {
        setConfirmationVisible2(true);
        setModalVisible(false);
    };
    
    const confirmRejection = () => {
      setConfirmationVisible(true);
      setModalVisible(false);
  };
    const approveUser = (userId) => {
      const db = getDatabase();
      const userRef = ref(db, `kullanicilar/${userId}`); // Burada userId parametresi kullanılıyor
      
      update(userRef, { IKonay: true ,IKstatus: "onaylandı"})
        .then(() => {
          window.alert("Başarılı: Kullanıcının kaydı onaylandı.");
          setModalVisible(false);
          // Burada ek olarak kullanıcı listesini yenilemek için kod ekleyebilirsiniz
        })
        .catch(error => {
          window.alert("Hata: Bir hata oluştu: " + error.message);
        });
    };
    
  
    
  
    const rejectUser = (userId) => {
      const db = getDatabase();
      const userRef = ref(db, `kullanicilar/${userId}`);
  
      update(userRef, {IKonay: false , IKstatus: "onaylanmadı" }) // Burada status değişkenini false olarak ayarlıyoruz
        .then(() => {
          window.alert("Başarılı: Kullanıcının kaydı reddedildi.");
          setModalVisible(false);
          // Burada ek olarak kullanıcı listesini yenilemek için kod ekleyebilirsiniz
        })
        .catch(error => {
            window.alert("Hata: Bir hata oluştu: " + error.message);
        });
    };
  
    // Kullanıcı bilgilerini gösteren Modal pop-up'ı
  const UserDetailsModal = ({ visible, user, onClose }) => {
    return (
        <Modal
        animationType="slide"
        transparent={true}
        isOpen={modalVisible}
        onRequestClose={onClose}
        style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)', // Arka plan rengini ayarla
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '400px', // Modal genişliğini burada ayarlayın
      maxHeight: '80%', // Modalın maksimum yüksekliğini sınırla
      overflow: 'auto', // İçeriği taşarsa kaydırma çubuğu ekle
      padding: '20px', // İç padding
      border: '2px solid black', // İnce çerçeve ekle
      borderRadius: '50px', // Köşelere yuvarlaklık ekle
    }
  }}
      >
        <div style={styles.centeredView}>
          <div style={styles.modalView}>
            <div>
              {Object.entries(user).map(([key, value]) => {
                if (key !== 'status' && key !== 'id' && key !== 'onay' && key !== 'IKstatus' && key !== 'IKonay' && key !== 'IKdurum' && key !== 'IKyonetim' && key !== 'ik') {
                  return (
                    <div key={key} style={styles.modalItem}>
                      <p style={styles.modalItemTitle}>{translateKey(key)}:</p>
                      <p style={styles.modalItemText}>{value.toString()}</p>
                    </div>
                  );
                }
              })}
            </div>
            <div style={styles.modalButtonsContainer}>
                <button
                  style={styles.modalButtonDecline}
                  onClick={confirmRejection}
                >
                  <p style={styles.modalButtonText}>Reddet</p>
                </button>
  
                <button
                       style={styles.modalButtonexit}
                  onClick={() => setModalVisible(!modalVisible)}
                >
                  <p style={styles.modalButtonText}>Kapat</p>
                </button>
  
                <button
                       style={styles.modalButtonAccept}
                  onClick={confirmApproval}
                >
                  <p style={styles.modalButtonText}>Onayla</p>
                </button>
              </div>
          </div>
        </div>
      </Modal>
    );
  };
  
    
    return (
      <div style={{ flex: 1 }}>
      <div style={styles.confirmationHeaderContainer}>
       <p style={styles.taskManagementTitle}>Onay Bekleyenler</p>
        <div style={styles.divider}/>
  </div>
  
  {users.map(user => (
        <div key={user.id} style={{ display: 'flex',flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingVertical: 10, paddingHorizontal: 20, borderBottomWidth: 1, borderBottomColor: '#ccc' }}>
          <p style={{ fontSize: 20, fontWeight: 'bold', marginLeft: '35px' }}>{user.fullName.toUpperCase()}</p>
          <button style={{ backgroundColor: '#007bff', alignItems: 'center', justifyContent: 'center', display: 'flex' , padding: 10, borderRadius: 5 , marginRight: '35px' , height: '40px'}} onClick={() => showUserDetails(user)}>
            <p style={{ fontSize: 16, color: '#fff', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>İncele</p>
          </button>
        </div>
      ))}
  
      <div style={styles.modalButtonsContainer2}>
                <button 
                    style={{ ...styles.modalButton, ...styles.modalButtonReject }}
                    onClick={() => navigate('/ikredusers')}
                >
                    <p style={styles.modalButtonText}>Reddedilenler</p>
                </button>
  
                <button
                    style={{ ...styles.modalButton, ...styles.modalButtonApprove }}
                    onClick={() => navigate('/ikconfirmationusers')}
                >
                    <p style={styles.modalButtonText}>Onaylananlar</p>
                </button>
            </div>
  
       <UserDetailsModal
          visible={modalVisible}
          user={selectedUser}
          onClose={() => setModalVisible(false)}
        />

{confirmationVisible && (
            <ConfirmationPopup
                message={`${selectedUser.fullName.toUpperCase()} kullanıcısını reddetmek istiyor musunuz?`}
                onConfirm={() => {
                    rejectUser(selectedUser.id);
                    setModalVisible(false); // Modalı kapat
                    setConfirmationVisible(false); // Onay penceresini kapat
                }}
                onCancel={() => setConfirmationVisible(false)}
            />
        )}

        {confirmationVisible2 && (
            <ConfirmationPopup
                message={`${selectedUser.fullName.toUpperCase()} kullanıcısını onaylamak istiyor musunuz?`}
                onConfirm={() => {
                    approveUser(selectedUser.id);
                    setModalVisible(false); // Modalı kapat
                    setConfirmationVisible2(false); // Onay penceresini kapat
                }}
                onCancel={() => setConfirmationVisible2(false)}
            />
        )}
    </div>
    );
  };




  const styles = {
    confirmationHeaderContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 10,
    },
    taskManagementTitle: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    divider: {
        height: 1,
        backgroundColor: '#ccc',
        marginTop: 10,
        marginBottom: 10,
    },
    confirmationListItem: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
    },
    confirmationListItemText: {
        fontSize: 16,
    },
    confirmationButton: {
        backgroundColor: '#007bff',
        padding: 10,
        borderRadius: 5,
        boxShadow: '0 2px 5px rgba(0,0,0,0.9)',
        border: 'none', // Çizgiyi kaldırmak için eklenen özellik
        outline: 'none', // Çizgiyi kaldırmak için eklenen özellik
    },
    confirmationButtonText: {
        fontSize: 16,
        color: '#fff',
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    modalItem: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginBottom: 10,
    },
    modalItemTitle: {
        fontSize: '20px',
        fontWeight: 'bold',
        marginRight: 10,
    },
    modalItemText: {
        flex: 1,
        fontSize: '17px',
        marginLeft: 10,
    },
    modalButtonsContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 20,
        marginBottom: 10,
    },
    modalButton: {
        padding: 10,
        borderRadius: 5,
        width: '30%',
        backgroundColor: '#28a745',
        alignItems: 'center',
    },
    modalButtonReject: {
        backgroundColor: '#dc3545',
        padding: 10,
        borderRadius: 5,
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'flex-start', // Sola yaslama
    },
    modalButtonClose: {
        backgroundColor: '#6c757d',
    },
    modalButtonApprove: {
        backgroundColor: '#28a745',
        padding: 10,
        borderRadius: 5,
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'flex-end', // Sağa yaslama
    },
    modalButtonText: {
        fontSize: 16,
        color: '#fff',
        
    },
    modalButtonsContainer2: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        paddingBottom: '100px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginHorizontal: 20,
    },
    modalButtonDecline:{
        padding: 10,
        borderRadius: 5,
        borderRadius: 20,
        width: '33%',
        backgroundColor: '#dc3545',
        alignItems: 'center',
    },
    modalButtonAccept: {
        padding: 10,
        borderRadius: 5,
        width: '33%',
        borderRadius: 20,
        backgroundColor: '#28a745',
        alignItems: 'center',
    },
    modalButtonexit: {
        padding: 10,
        borderRadius: 5,
        width: '33%',
        borderRadius: 20,
        backgroundColor: 'gray',
        alignItems: 'center',
    },
};
  








export default IKConfirmationScreen;
 