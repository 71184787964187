
import React, { useState, useEffect } from 'react';
import { useNavigate ,useParams } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, onValue, get } from 'firebase/database';
import moment from 'moment';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';


import { 
    MdVisibility, // Göz ikonu
    MdEdit // Düzeltme kalem ikonu
  } from 'react-icons/md';
  

  let selectedDate = null;


const PastMissionScreen = ({ route }) => {
    const navigate = useNavigate();
  const userId = localStorage.getItem("userId"); // Kullanıcı ID'si
    const [selectedMission, setSelectedMission] = useState(null); // Seçili görevin detaylarını tutar
    const [modalVisible, setModalVisible] = useState(false); // Modalın görünürlüğünü kontrol eder
  
    const [missions, setMissions] = useState([]); // Görevleri tutacak state
   

  
    useEffect(() => {
      const checkUserId = async () => {
        const userId = await localStorage.getItem('userId');
        if (!userId) {
          navigate('/login', { replace: true });
        }
      };
    
      checkUserId();
    }, [navigate]);
    
    
  
    useEffect(() => {
      const getUserId = async () => {
        try {
          const userId = await localStorage.getItem('userId');
          if (userId !== null) {
            // Kullanıcı ID'sini global değişkene ata
            global.userDetails.userId = userId;
            // Konsolda göster
            console.log("Kullanıcı ID: ", userId);
          }
        } catch (error) {
          console.error("Kullanıcı ID alınırken hata oluştu: ", error);
        }
      };
    
      getUserId();
    }, []);
    
    const getPastWeekDates = (currentDate) => {
      let dates = [];
      for (let i = 0; i < 7; i++) {
          const date = new Date(currentDate);
          date.setDate(currentDate.getDate() - i);
          dates.push(date);
      }
      return dates;
  };
    const db = getDatabase();
   useEffect(() => {
    const db = getDatabase();
    const userRef = ref(db, 'gorevler/' + userId);
    onValue(userRef, (snapshot) => {
        if (snapshot.exists()) {
            const data = snapshot.val();
            const allDates = Object.keys(data).sort((a, b) => a.localeCompare(b)); // En eski tarihten itibaren sırala
            const missionsWithCompleteDates = calculateCompleteDateRange(allDates, data);
            setMissions(missionsWithCompleteDates.reverse()); // Verileri ters çevir ve state'e ata
        } else {
            const currentDate = new Date();
            const pastWeekDates = getPastWeekDates(currentDate);
            const emptyMissions = pastWeekDates.map(date => ({
                date: formatDate(date),
                data: {}
            }));
            setMissions(emptyMissions);
        }
    });
}, []);

    
    const calculateCompleteDateRange = (allDates, data) => {
      let dateArray = [];
      let startDate = new Date(allDates[0].split('-').reverse().join('-'));
      let currentDate = new Date();
    
      while (startDate <= currentDate) {
        let formattedDate = formatDate(startDate);
        dateArray.push({
          date: formattedDate,
          data: data[formattedDate] || {}
        });
        startDate.setDate(startDate.getDate() + 1);
      }
      return dateArray;
    };
    
    const formatDate = (date) => {
      return [
        String(date.getDate()).padStart(2, '0'),
        String(date.getMonth() + 1).padStart(2, '0'),
        date.getFullYear(),
      ].join('-');
    };
    
    const viewMissionDetails = async (missionData) => {
      try {
          const date = missionData.date;
          const missionRef = ref(db, `gorevler/${userId}/${date}`);
          const snapshot = await get(missionRef);

          if (snapshot.exists()) {
              const missionDetails = snapshot.val();
              setSelectedMission({ ...missionData, ...missionDetails });
              setModalVisible(true);
          } else {
              console.log("Bu tarihe ait bilgileriniz bulunamadı. Lütfen ekleyiniz.");
          }
      } catch (error) {
          console.error("Bilgiler alınırken hata oluştu: ", error);
      }
  };

    const renderMissionItem = ({ item, index }) => {
      // Tarihi 'DD-MM-YYYY' formatından 'YYYY-MM-DD' formatına dönüştürün (eğer gerekliyse)
      const dateParts = item.date.split('-');
      const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
  
      // Dönüştürülmüş tarihi moment'e verin
      const missionDate = moment(formattedDate, "YYYY-MM-DD").local(); // Yerel saat diliminde
  
      const dayOfWeek = missionDate.format('dddd'); // Türkçe gün ismi
  
      const isFirst7Item = index < 7; // İlk 7 öğe için indeks kontrolü
  
      return (
          <div style={styles.missionItem}>
              <button onPress={() => viewMissionDetails(item)}>
                  <MdVisibility size={25} color={'black'} />
              </button>
              <p style={styles.missionText}>{item.date} ({dayOfWeek})</p>
              {isFirst7Item ? (
                  <button onclick={() => editMission(item)}>
                      <MdEdit  size={25} color={'black'} />
                  </button>
              ) : (
                  <div style={{ width: 25, height: 25 }}></div> // Boş alan bırakmak için
              )}
          </div>
      );
  };
  
  const editMission = (item) => {
    selectedDate = item.date;
    if(selectedDate == null) {
      console.log("Edit Mission Data:", item.data.currentDate);
      console.log("selamm:", item.data.currentDate);
    }
  
    // Kullanıcıyı '/daymissionedit' sayfasına yönlendir, 'currentDate' ve 'dayName' ile
    navigate('/daymissionedit', { state: { currentDate: item.date, missionData: item.data } });
  };
  

  
  
    return (
      <div style={styles.pastmissionContainer}>
      


      <div style={styles.title}>

      <p style={styles.label2}>Geçmiş</p>
      </div>
      
  
      <div style={styles.divider}/>
      
      <div>
                {missions.map((item, index) => (
                    <div style={styles.missionItem} key={item.date}>
                        <button onClick={() => viewMissionDetails(item)}>
                            <MdVisibility size={25} color={'black'} />
                        </button>
                        <p style={styles.missionText}>{item.date} ({moment(item.date, "DD-MM-YYYY").format('dddd')})</p>
                        {index < 7 && (
                            <button onClick={() => editMission(item)}>
                                <MdEdit size={25} color={'black'} />
                            </button>
                        )}
                        {index >= 7 && (
                <div style={styles.emptySpace}></div>
              )}
                    </div>
  
                    ))}
            </div>
            <Modal
  isOpen={modalVisible}
  onRequestClose={() => setModalVisible(false)}
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)', // Arka plan rengini ayarla
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '400px', // Modal genişliğini burada ayarlayın
      maxHeight: '80%', // Modalın maksimum yüksekliğini sınırla
      overflow: 'auto', // İçeriği taşarsa kaydırma çubuğu ekle
      padding: '20px', // İç padding
      border: '2px solid black', // İnce çerçeve ekle
      borderRadius: '50px', // Köşelere yuvarlaklık ekle
    }
  }}
>
    <div style={styles.pastmissionCenteredView}>
      <div style={styles.pastmissionModalView}>
  
    <p style={styles.pastmissionModalTitle1}>{selectedMission?.dayName}</p>
  
    <div style={styles.pastmissionModalitemspastmission}>
    <p style={styles.pastmissionModalTitle}>Akademik Hedef: </p>
    <p style={styles.pastmissionModalText}>{selectedMission?.academicGoal}</p>
  </div>
      <div style={styles.pastmissionModalitemspastmission}>
    <p style={styles.pastmissionModalTitle}>Hedef Tarihi: </p>
    <p style={styles.pastmissionModalText}>{selectedMission?.academicGoalDate}</p>
  </div>
  <div style={styles.pastmissionModalitemspastmission}>
    <p style={styles.pastmissionModalTitle}>Çalışma Süresi: </p>
    <p style={styles.pastmissionModalText}>{selectedMission?.academicWorkDuration}</p>
  </div>
  <div style={styles.pastmissionModalitemspastmission}>
    <p style={styles.pastmissionModalTitle}>Faaliyet ve Katılımcı Sayısı: </p>
    <p style={styles.pastmissionModalText}>{selectedMission?.activityParticipation}</p>
  </div>
  <div style={styles.pastmissionModalitemspastmission}>
    <p style={styles.pastmissionModalTitle}>Sanatsal Alan: </p>
    <p style={styles.pastmissionModalText}>{selectedMission?.artArea}</p>
  </div>
  <div style={styles.pastmissionModalitemspastmission}>
    <p style={styles.pastmissionModalTitle}>Sanatsal Çalışma Süresi: </p>
    <p style={styles.pastmissionModalText}>{selectedMission?.artDuration}</p>
  </div>
  <div style={styles.pastmissionModalitemspastmission}>
    <p style={styles.pastmissionModalTitle}>Birim Üyesi Sayısı: </p>
    <p style={styles.pastmissionModalText}>{selectedMission?.committeeMemberCount}</p>
  </div>
  <div style={styles.pastmissionModalitemspastmission}>
    <p style={styles.pastmissionModalTitle}>Aile Zamanı: </p>
    <p style={styles.pastmissionModalText}>{selectedMission?.familyTime}</p>
  </div>
  <div style={styles.pastmissionModalitemspastmission}>
    <p style={styles.pastmissionModalTitle}>Mali Katkı: </p>
    <p style={styles.pastmissionModalText}>{selectedMission?.financialContribution}</p>
  </div>
  <div style={styles.pastmissionModalitemspastmission}>
    <p style={styles.pastmissionModalTitle}>Toplantı Katılımı: </p>
    <p style={styles.pastmissionModalText}>{selectedMission?.meetingAttendance}</p>
  </div>
  <div style={styles.pastmissionModalitemspastmission}>
    <p style={styles.pastmissionModalTitle}>Gelecek Etkinlik Planı: </p>
    <p style={styles.pastmissionModalText}>{selectedMission?.nextActivityPlan}</p>
  </div>
  <div style={styles.pastmissionModalitemspastmission}>
    <p style={styles.pastmissionModalTitle}>Manevi Puan: </p>
    <p style={styles.pastmissionModalText}>{selectedMission?.spiritualPoints}</p>
  </div>
  <div style={styles.pastmissionModalitemspastmission}>
    <p style={styles.pastmissionModalTitle}>Spor Zamanı: </p>
    <p style={styles.pastmissionModalText}>{selectedMission?.sportTime}</p>
     </div>
        
        <button
          style={styles.pastmissionclosebutton}
          onClick={() => {
            setModalVisible(!modalVisible);
          }}
        >
          <p style={styles.pastmissionbuttontext}>Kapat</p>
        </button>
      </div>
    </div>
  </Modal>
  
      </div>
    );
  };
  const styles = {
    pastmissionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        justifyContent: 'flex-start',
        minHeight: '100vh',
        backgroundColor: '#f0f0f0',
    },
    emptySpace: {
      width: 25, 
      height: 25,
  },
    label2: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#333',
      textAlign: 'center', // Metni ortala
      width: '100%', // Genişliği tam ekran yap
    },
    divider: {
        width: '100%',
        height: '2px',
        backgroundColor: '#ccc',
        marginBottom: '20px',
    },
    missionItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        width: '100%',
        borderBottom: '1px solid #ccc',
        marginBottom: '10px',
    },
    missionText: {
        fontSize: '16px',
        color: '#333',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    pastmissionCenteredView: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '1%',
    },
    pastmissionModalView: {
        margin: '20px',
        backgroundColor: 'white',
        borderRadius: '20px',
        padding: '35px',
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        justifyContent: 'center',
       
    },
    pastmissionModalTitle1: {
        marginBottom: '15px',
        textAlign: 'center',
        fontSize: '22px',
        fontWeight: 'bold',
    },
    pastmissionModalTitle: {
        fontSize: '18px',
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#333',
        marginRight: '10px'
        
    },
    pastmissionModalText: {
        fontSize: '18px',
        alignItems: 'center',
        justifyContent: 'center',
        
    },
    pastmissionModalitemspastmission: {
        marginBottom: '20px',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        
    },
    pastmissionclosebutton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#f44336', // Kırmızı renk
      padding: '10px 20px',
      borderRadius: '10px',
      width: '100%',
      elevation: 2,
      marginTop: '20px', // Modal içeriği ile arasında boşluk
    },
    pastmissionbuttontext: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    title: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'flex-start'

    }
};


export default PastMissionScreen;